<template>
  <div class="content">
    <h3>Outstanding Tasks</h3>
    <div v-if="outstandingCards" class="list">
      <ActionCard v-for="card in outstandingCards" :card="card" :tasks="tasks" :key="card.id" />
    </div>
    <h3>Completed Tasks</h3>
    <div v-if="completedCards" class="list">
      <ActionCard v-for="card in completedCards" :card="card" :tasks="tasks" :key="card.id" />
    </div>
  </div>
</template>

<script>
import ActionCard from '@/components/ActionCard';

export default {
  name: 'RetroOutcomes',
  props: ['taskIds', 'tasks'],
  components: {
    ActionCard,
  },
  created() {
    this.fetchCards(this.taskIds);
  },
  computed: {
    cards() {
      const actionCards = this.$store.getters['cards/actionCards'];
      // map through cards and add tasks to object
      const mergedCards = actionCards.map(card => {
        const tasks = this.tasks.filter(task => task.cardId === card.id);
        card.tasks = tasks;
        card.tasksComplete = this.areAllTasksComplete(tasks);
        return card;
      });
      // Sort by created date
      return mergedCards.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    },
    outstandingCards() {
      return this.cards.filter(card => !card.tasksComplete);
    },
    completedCards() {
      return this.cards.filter(card => card.tasksComplete);
    },
  },
  methods: {
    areAllTasksComplete(tasks) {
      const completedTasksLen = tasks.filter(task => task.done).length;
      return completedTasksLen === tasks.length;
    },
    fetchCards(taskIds) {
      this.$store.dispatch('cards/fetchActionCards', taskIds);
    },
  },
};
</script>

<style scoped lang="scss">
.list {
  margin-top: $space_sm;
  margin-bottom: $space_xxl;
}
</style>