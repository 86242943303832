<template>
  <div class="templates">
    <label>Choose a template</label>
    <div class="template-grid">
      <div
        v-for="(item, index) in templates"
        :key="item.id"
        class="template"
        :class="{ selected: index == selected }"
        @click="selectTemplate(index)"
      >
        <div class="template-name">{{ item.name }}</div>
        <div class="template-body">
          <div>{{ item.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import templates from '@/constants/templates';

export default {
  name: 'TemplateChooser',
  data: () => ({
    templates,
    selected: 0,
  }),
  created() {
    this.emitChange(templates[this.selected].columns);
  },
  methods: {
    selectTemplate(index) {
      this.selected = index;
      const selectedTemplate = templates[index];
      this.emitChange(selectedTemplate.columns);
    },
    emitChange(cols) {
      this.$emit('columnChange', cols);
    },
  },
};
</script>

<style scoped lang="scss">
.templates {
  margin-top: $space_md;
  margin-bottom: $space_md;
}
.template-grid {
  margin-top: $space_xs;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $space_sm;
  font-size: 14px;
}
@media (min-width: 768px) {
  .template-grid {
    grid-template-columns: 1fr 1fr;
  }
}
.template {
  border: solid 2px $color_input_border;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  &.selected,
  &:hover {
    border-color: $color_primary;
    box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.08);

    .template-name {
      color: white;
      background: $color_primary;
    }
  }
}
.template-name {
  color: $color_text;
  background: $color_input_border;
  font-weight: $font_weight_bold;
  line-height: 24px;
  padding: $space_xxs;
}
.template-body {
  min-height: 90px;
  font-size: 13px;
  display: grid;
  place-items: center;
  white-space: pre;
}
</style>
