<template>
  <button class="button" @click="onClick">
    <div class="logo" :class="[logo || '']"></div>
    <div class="text">
      <slot></slot>
    </div>
    <div class="spacer"></div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['logo'],
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  border: none;
  background: white;
  line-height: 58px;
  font-size: 16px;
  font-weight: $font_weight_bold;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 0 $space_md;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.14);
    border: solid 1px #dcdcdc;
  }

  &:focus {
    outline: none;
    border-color: $color_blue;
  }

  .logo {
    display: block;
    width: 28px;
    height: 28px;

    &.google {
      background: url(https://i.imgur.com/N4jEFsP.png) left center no-repeat;
      background-size: 100%;
    }

    &.github {
      background: url(https://i.imgur.com/YsHNAXw.png) left center no-repeat;
      background-size: 100%;
    }

    &.microsoft {
      background: url(https://i.imgur.com/1k02sGq.png) left center no-repeat;
      background-size: 100%;
    }
  }

  .spacer {
    width: 28px;
  }

  .text {
    padding: 0 $space_sm;
    color: $color_text;
  }
}

.button + .button {
  margin-top: $space_sm;
}

@media (min-width: 768px) {
  .button {
    padding: 0 $space_lg;
  }
}
</style>
