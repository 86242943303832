import formatISO from 'date-fns/formatISO';
import { db } from './firebase-config';
import invites from '@/auth/invites';

const users = {
  async fetchOrCreateUser(user) {
    // Check invites
    const foundOrganisations = await invites.checkForInvite(user);

    // Check if user exists
    const existingUser = await this.fetchUser(user.uid);
    if (existingUser.exists) {
      // User exists, update their organisations and return
      const existingUserData = { ...existingUser.data(), uid: user.uid };
      const updatedUser = this.updateUserOrganisations(existingUserData, foundOrganisations);
      return updatedUser;
    } else {
      // User does not exist, create one
      console.log('No user found, adding user...');
      const createdUser = await this.createUser(user, foundOrganisations);
      if (createdUser) {
        const newUser = await this.fetchUser(user.uid);
        return newUser.data();
      }
    }
  },
  async fetchUser(uid) {
    return await db
      .collection('users')
      .doc(uid)
      .get();
  },
  async createUser(user, organisations) {
    const newUser = db.collection('users').doc(user.uid);

    return newUser
      .set({
        email: user.email,
        displayName: user.displayName,
        createdDate: formatISO(new Date()),
        organisations,
      })
      .then(() => {
        return true;
      });
  },
  updateUser(id, user) {
    if (user) {
      const query = db.collection('users').doc(id);
      query.update(user);
    }
  },
  updateUserOrganisations(user, organisations) {
    const updatedOrgs = [...user.organisations, ...organisations];
    const uniqueOrgs = [...new Set(updatedOrgs)];
    const updatedUser = {
      ...user,
      organisations: uniqueOrgs,
    };
    // Update user
    this.updateUser(user.uid, updatedUser);
    return updatedUser;
  },
};

export default users;
