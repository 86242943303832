<template>
  <div class="task-item" @dblclick="toggleEdit">
    <div class="task-content" v-if="!isEditing">
      <IconCheckBox color="#a7aab0" />
      <span class="text">{{ task.description }}</span>
    </div>
    <div class="editor" v-if="isEditing">
      <resizable-textarea>
        <textarea
          class="input"
          placeholder="Write something..."
          data-gramm_editor="false"
          v-model="task.description"
          @keydown.enter="onSubmitEdit"
          @keyup.esc="onCancelEdit"
          rows="1"
          ref="titleTextarea"
        >
        </textarea>
      </resizable-textarea>
      <div class="btn" @click="onSubmitEdit">Save</div>
    </div>
  </div>
</template>

<script>
import ResizableTextarea from '@/components/ResizableTextarea';
import IconCheckBox from '@/components/icons/CheckBox';

export default {
  name: 'ActionItem',
  props: ['task'],
  components: {
    IconCheckBox,
    ResizableTextarea,
  },
  data: () => ({
    isEditing: false,
  }),
  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
      if (this.isEditing) {
        this.$nextTick(() => {
          this.$refs.titleTextarea.focus();
        });
      }
    },
    onCancelEdit() {
      this.toggleEdit();
    },
    onSubmitEdit(event) {
      event.preventDefault();
      this.saveChanges();
      this.toggleEdit();
    },
    deleteTask() {
      this.$store.dispatch('tasks/remove', this.task);
    },
    saveChanges() {
      const task = this.task;
      if (task.description) {
        // If the task has content, save the changes
        this.$store.dispatch('tasks/edit', {
          ...task,
        });
      } else {
        // If there is no task description, delete it.
        this.deleteTask();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.task-item {
  font-size: 13px;
  padding: 0.35rem 1rem;
  border-top: solid 1px #e1e4e6;

  .icon {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 2px;
    margin-right: 0.35rem;
  }

  .text {
    flex: 1;
  }
}
.task-content {
  display: flex;
}
.editor {
  margin: -2px -8px;
  flex: 1;
  display: flex;
  align-items: center;

  textarea {
    font-family: $font_family;
    width: 100%;
    font-size: 13px;
    color: $color_text;
    padding: 4px 8px;
    border-radius: 3px;
    border: solid 1px #ddd;
    display: block;
    resize: none;

    &:focus,
    &:active {
      border-color: #bbb;
      outline: none;
    }
  }
}
.btn {
  margin-left: 4px;
  padding: 0 12px;
  line-height: 26px;
  background: $color_primary;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: darken($color_primary, 5%);
  }
}
</style>
