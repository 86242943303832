export default {
  plans: [
    {
      id: 'free',
      key: 'free',
      name: 'Free',
      details: ['<strong>1 Public Team</strong>, with unlimited Retros.'],
      permissions: {
        privateBoards: false,
        inviteUsers: false,
        teamLimit: 1,
      },
    },
    {
      testId: 'prod_Hpt0OoUZHx80AZ',
      prodId: 'prod_HpiPXFggGPx6xi',
      key: 'team',
      name: 'Team',
      details: ['Up to <strong>5 Teams</strong>, with unlimited Retros', 'Public &amp; private boards.'],
      permissions: {
        privateBoards: true,
        inviteUsers: true,
        teamLimit: 5,
      },
    },
    {
      testId: 'prod_Hpt1P0xhLmt1yJ',
      prodId: 'prod_HpiQgGM20x8XJK',
      key: 'pro',
      name: 'Pro',
      details: [
        'Up to <strong>25 Teams</strong>, with unlimited Retros',
        'Public &amp; private boards.',
        'Unlimited members',
      ],
      permissions: {
        privateBoards: true,
        inviteUsers: true,
        teamLimit: 25,
      },
    },
  ],
  findPlan(planId) {
    return this.plans.find(p => p.id == planId || p.testId === planId || p.prodId === planId);
  },
};
