<template>
  <LayoutContent>
    <h1>Privacy Policy</h1>
    <p>
      Thank you for choosing to use our service at Retros for Teams (&quot;<strong>Company</strong>&quot;,
      &quot;<strong>we</strong>&quot;, &quot;<strong>us</strong>&quot;, &quot;<strong>our</strong>&quot;). We are
      committed to protecting your personal information and your right to privacy. If you have any questions or concerns
      about this privacy notice, or our practices with regards to your personal information, please contact us at
      contact@retros.team.
    </p>
    <p>
      When you visit our website <a href="https://retros.team/">https://retros.team</a> (the
      &quot;<strong>Website</strong>&quot;), and more generally, use any of our services (the
      &quot;<strong>Services</strong>&quot;, which include the Website), we appreciate that you are trusting us with
      your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you
      in the clearest way possible what information we collect, how we use it and what rights you have in relation to
      it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this
      privacy notice that you do not agree with, please discontinue use of our Services immediately.
    </p>
    <p>
      This privacy notice applies to all information collected through our Services (which, as described above, includes
      our Website), as well as, any related services, sales, marketing or events.
    </p>
    <p>
      <strong
        >Please read this privacy notice carefully as it will help you understand what we do with the information that
        we collect.</strong
      >
    </p>
    <h2><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></h2>
    <p><strong>Personal information you disclose to us</strong></p>
    <p>
      <em><strong>In Short: </strong></em><em><strong> </strong></em
      ><em>We collect personal information that you provide to us.</em>
    </p>
    <p>
      We collect personal information that you voluntarily provide to us when you register on the Website, express an
      interest in obtaining information about us or our products and Services, when you participate in activities on the
      Website (such as by posting messages in our online forums or entering competitions, contests or giveaways) or
      otherwise when you contact us.
    </p>
    <p>
      The personal information that we collect depends on the context of your interactions with us and the Website, the
      choices you make and the products and features you use. The personal information we collect may include the
      following:
    </p>
    <p>
      <strong>Personal Information Provided by You.</strong> We collect names; email addresses; and other similar
      information.
    </p>
    <p>
      <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such
      as your payment instrument number (such as a credit card number), and the security code associated with your
      payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here:
      <a href="https://stripe.com/privacy-center/legal">https://stripe.com/privacy-center/legal</a>.
    </p>
    <p>
      <strong>Social Media Login Data. </strong>We may provide you with the option to register with us using your
      existing social media account details, like your Facebook, Twitter or other social media account. If you choose to
      register in this way, we will collect the Information described in the section called &quot;HOW DO WE HANDLE YOUR
      SOCIAL LOGINS&quot; below.
    </p>
    <p>
      All personal information that you provide to us must be true, complete and accurate, and you must notify us of any
      changes to such personal information.
    </p>
    <p><strong>Information automatically collected</strong></p>
    <p>
      <em><strong>In Short: </strong></em><em><strong> </strong></em
      ><em
        >Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is
        collected automatically when you visit our </em
      ><em>Website</em><em>.</em>
    </p>
    <p>
      We automatically collect certain information when you visit, use or navigate the Website. This information does
      not reveal your specific identity (like your name or contact information) but may include device and usage
      information, such as your IP address, browser and device characteristics, operating system, language preferences,
      referring URLs, device name, country, location, information about how and when you use our Website and other
      technical information. This information is primarily needed to maintain the security and operation of our Website,
      and for our internal analytics and reporting purposes.
    </p>
    <p>The information we collect includes:</p>
    <ul>
      <li>
        <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage and performance
        information our servers automatically collect when you access or use our Website and which we record in log
        files. Depending on how you interact with us, this log data may include your IP address, device information,
        browser type and settings and information about your activity in the Website (such as the date/time stamps
        associated with your usage, pages and files viewed, searches and other actions you take such as which features
        you use), device event information (such as system activity, error reports (sometimes called &#x27;crash
        dumps&#x27;) and hardware settings).
      </li>
    </ul>
    <ul>
      <li>
        <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet or other
        device you use to access the Website. Depending on the device used, this device data may include information
        such as your IP address (or proxy server), device and application identification numbers, location, browser
        type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration
        information.
      </li>
    </ul>

    <h2><strong>2. HOW DO WE USE YOUR INFORMATION?</strong></h2>
    <p>
      <em><strong>In Short: </strong></em
      ><em
        >We process your information for purposes based on legitimate business interests, the fulfillment of our
        contract with you, compliance with our legal obligations, and/or your consent.</em
      >
    </p>
    <p>
      We use personal information collected via our Website for a variety of business purposes described below. We
      process your personal information for these purposes in reliance on our legitimate business interests, in order to
      enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We
      indicate the specific processing grounds we rely on next to each purpose listed below.
    </p>
    <p>We use the information we collect or receive:</p>
    <ul>
      <li>
        <strong>To facilitate account creation and logon process.</strong> If you choose to link your account with us to
        a third-party account (such as your Google or Facebook account), we use the information you allowed us to
        collect from those third parties to facilitate account creation and logon process for the performance of the
        contract.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Request feedback. </strong>We may use your information to request feedback and to contact you about your
        use of our Website.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To enable user-to-user communications.</strong> We may use your information in order to enable
        user-to-user communications with each user&#x27;s consent.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To manage user accounts. </strong>We may use your information for the purposes of managing our account
        and keeping it in working order.
      </li>
    </ul>
    <h2>
      <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
    </h2>
    <p>
      <em><strong>In Short:</strong></em
      ><em>
        We only share information with your consent, to comply with laws, to provide you with services, to protect your
        rights, or to fulfill business obligations.</em
      >
    </p>
    <p>
      We may process or share your data that we hold based on the following legal basis:
    </p>
    <ul>
      <li>
        <strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal
        information for a specific purpose.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our
        legitimate business interests.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your
        personal information to fulfill the terms of our contract.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in
        order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
        process, such as in response to a court order or a subpoena (including in response to public authorities to meet
        national security or law enforcement requirements).
      </li>
    </ul>
    <ul>
      <li>
        <strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to
        investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations
        involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in
        which we are involved.
      </li>
    </ul>
    <p>
      More specifically, we may need to process your data or share your personal information in the following
      situations:
    </p>
    <ul>
      <li>
        <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during
        negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
        business to another company.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Other Users. </strong>When you share personal information (for example, by posting comments,
        contributions or other content to the Website) or otherwise interact with public areas of the Website, such
        personal information may be viewed by all users and may be publicly made available outside the Website in
        perpetuity. If you interact with other users of our Website and register for our Website through a social login
        (such as Google), your contacts on the social network will see your name, profile photo, and descriptions of
        your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you
        within our Website, and view your profile.
      </li>
    </ul>

    <h2><strong>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></h2>
    <p>
      <em><strong>In Short: </strong></em
      ><em
        >If you choose to register or log in to our services using a social media account, we may have access to certain
        information about you.</em
      >
    </p>
    <p>
      Our Website offers you the ability to register and login using your third-party social media account details (like
      your Facebook or Google logins). Where you choose to do this, we will receive certain profile information about
      you from your social media provider. The profile Information we receive may vary depending on the social media
      provider concerned, but will often include your name, email address, friends list, profile picture as well as
      other information you choose to make public on such social media platform.
    </p>
    <p>
      We will use the information we receive only for the purposes that are described in this privacy notice or that are
      otherwise made clear to you on the relevant Website. Please note that we do not control, and are not responsible
      for, other uses of your personal information by your third-party social media provider. We recommend that you
      review their privacy notice to understand how they collect, use and share your personal information, and how you
      can set your privacy preferences on their sites and apps.
    </p>
    <h2>
      <strong>5. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
    </h2>
    <p>
      <em><strong>In Short: </strong></em
      ><em>
        We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless
        otherwise required by law.</em
      >
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary for the purposes set out in this
      privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other
      legal requirements). No purpose in this notice will require us keeping your personal information for longer than
      the period of time in which users have an account with us.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal information, we will either delete or
      anonymize such information, or, if this is not possible (for example, because your personal information has been
      stored in backup archives), then we will securely store your personal information and isolate it from any further
      processing until deletion is possible.
    </p>
    <h2>
      <strong>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
    </h2>
    <p>
      <em><strong>In Short: </strong></em
      ><em>
        We aim to protect your personal information through a system of organizational and technical security
        measures.</em
      >
    </p>
    <p>
      We have implemented appropriate technical and organizational security measures designed to protect the security of
      any personal information we process. However, despite our safeguards and efforts to secure your information, no
      electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure,
      so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be
      able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will
      do our best to protect your personal information, transmission of personal information to and from our Website is
      at your own risk. You should only access the Website within a secure environment.
    </p>
    <h2>
      <strong>7. DO WE COLLECT INFORMATION FROM MINORS?</strong>
    </h2>
    <p>
      <em><strong>In Short:</strong></em
      ><em> We do not knowingly collect data from or market to children under 18 years of age.</em>
    </p>
    <p>
      We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you
      represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Website. If we learn that personal information from users less than 18 years of age
      has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from
      our records. If you become aware of any data we may have collected from children under age 18, please contact us
      at <strong>contact@retros.team</strong>.
    </p>
    <h2><strong>8. WHAT ARE YOUR PRIVACY RIGHTS?</strong></h2>
    <p>
      <em><strong>In Short:</strong></em
      ><em> </em
      ><em
        >In some regions, such as the European Economic Area, you have rights that allow you greater access to and
        control over your personal information. </em
      ><em>You may review, change, or terminate your account at any time.</em>
    </p>
    <p>
      In some regions (like the European Economic Area), you have certain rights under applicable data protection laws.
      These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request
      rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable,
      to data portability. In certain circumstances, you may also have the right to object to the processing of your
      personal information. To make such a request, please use the contact details provided below. We will consider and
      act upon any request in accordance with applicable data protection laws.
    </p>
    <p>
      If we are relying on your consent to process your personal information, you have the right to withdraw your
      consent at any time. Please note however that this will not affect the lawfulness of the processing before its
      withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful
      processing grounds other than consent.
    </p>
    <p>
      If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal
      information, you also have the right to complain to your local data protection supervisory authority. You can find
      their contact details here:
      <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
        >http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a
      >.
    </p>
    <p>
      If you are a resident in Switzerland, the contact details for the data protection authorities are available here:
      <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
    </p>
    <p>
      If you have questions or comments about your privacy rights, you may email us at contact@retros.team.
    </p>
    <p><strong>Account Information</strong></p>
    <p>
      If you would at any time like to review or change the information in your account or terminate your account, you
      can:
    </p>
    <ul>
      <li>Log in to your account settings and update your user account.</li>
    </ul>
    <p>
      Upon your request to terminate your account, we will deactivate or delete your account and information from our
      active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
      assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
    </p>
    <p>
      <strong>Opting out of email marketing:</strong> You can unsubscribe from our marketing email list at any time by
      clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below.
      You will then be removed from the marketing email list — however, we may still communicate with you, for example
      to send you service-related emails that are necessary for the administration and use of your account, to respond
      to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
    </p>
    <ul>
      <li>Access your account settings and update your preferences.</li>
    </ul>
    <h2><strong>9. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></h2>
    <p>
      Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
      (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data about
      your online browsing activities monitored and collected. At this stage no uniform technology standard for
      recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser
      signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard
      for online tracking is adopted that we must follow in the future, we will inform you about that practice in a
      revised version of this privacy notice.
    </p>
    <h2>
      <strong>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
    </h2>
    <p>
      <em><strong>In Short: </strong></em
      ><em>
        Yes, if you are a resident of California, you are granted specific rights regarding access to your personal
        information.</em
      >
    </p>
    <p>
      California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law, permits our users who
      are California residents to request and obtain from us, once a year and free of charge, information about
      categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the
      names and addresses of all third parties with which we shared personal information in the immediately preceding
      calendar year. If you are a California resident and would like to make such a request, please submit your request
      in writing to us using the contact information provided below.
    </p>
    <p>
      If you are under 18 years of age, reside in California, and have a registered account with the Website, you have
      the right to request removal of unwanted data that you publicly post on the Website. To request removal of such
      data, please contact us using the contact information provided below, and include the email address associated
      with your account and a statement that you reside in California. We will make sure the data is not publicly
      displayed on the Website, but please be aware that the data may not be completely or comprehensively removed from
      all our systems (e.g. backups, etc.).
    </p>
    <p><strong>CCPA Privacy Notice</strong></p>
    <p>
      The California Code of Regulations defines a &quot;resident&quot; as:
    </p>
    <p>
      (1) every individual who is in the State of California for other than a temporary or transitory purpose and
    </p>
    <p>
      (2) every individual who is domiciled in the State of California who is outside the State of California for a
      temporary or transitory purpose
    </p>
    <p>
      All other individuals are defined as &quot;non-residents.&quot;
    </p>
    <p>
      If this definition of &quot;resident&quot; applies to you, we must adhere to certain rights and obligations
      regarding your personal information.
    </p>
    <h2><strong>11. DO WE MAKE UPDATES TO THIS NOTICE?</strong></h2>
    <p>
      <em><strong>In Short: </strong></em
      ><em> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
    </p>
    <p>
      We may update this privacy notice from time to time. The updated version will be indicated by an updated
      &quot;Revised&quot; date and the updated version will be effective as soon as it is accessible. If we make
      material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes
      or by directly sending you a notification. We encourage you to review this privacy notice frequently to be
      informed of how we are protecting your information.
    </p>
    <h2>
      <strong>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
    </h2>
    <p>
      If you have questions or comments about this notice, you may email us at <strong>contact@retros.team</strong> or
      by post to:
    </p>
    <p>Retro for Teams</p>
    <p>1 Adelaide St</p>
    <p>Richmond, Victoria 3121</p>
    <p>Australia</p>
  </LayoutContent>
</template>

<script>
import LayoutContent from '@/components/LayoutContent';

export default {
  name: 'Privacy',
  components: {
    LayoutContent,
  },
};
</script>
