import formatISO from 'date-fns/formatISO';
import db from '@/db/organisations';
import { generateId, getEmailDomain } from '@/utils';

const state = {
  organisation: null,
  organisations: [],
  users: [],
};

const getters = {
  currentId: state => state.organisation,
  organisations: state => state.organisations,
  organisationById: state => id => {
    return state.organisations ? state.organisations.find(org => org.id === id) : {};
  },
  currentOrganisation: state => {
    const id = state.organisation;
    return state.organisations ? state.organisations.find(org => org.id === id) : {};
  },
  users: state => state.users,
};

const mutations = {
  setOrg: (state, organisation) => {
    state.organisation = organisation;
  },
  setOrgs: (state, organisations) => {
    state.organisations = organisations || [];
  },
  add(state, organisation) {
    state.organisations.push(organisation);
  },
  edit(state, organisation) {
    const index = state.organisations.findIndex(x => x.id === organisation.id);
    state.organisations.splice(index, 1, organisation);
  },
  setUsers: (state, users) => {
    state.users = users || [];
  },
  updateUser: (state, user) => {
    const index = state.users.findIndex(x => x.email === user.email);
    state.users.splice(index, 1, user);
  },
};

const actions = {
  setCurrentOrg: ({ commit }, org) => {
    commit('setOrg', org);
    localStorage.setItem('retro:org', org);
  },
  async create({ commit }, organisation) {
    const newOrg = {
      ...organisation,
      id: generateId(),
      plan: {
        planId: 'free',
        tier: 1,
      },
      createdDate: formatISO(new Date()),
    };
    commit('add', newOrg);
    // Sync to DB
    const orgId = await db.addOrganisation(newOrg);
    return orgId;
  },
  edit({ commit }, org) {
    commit('edit', org);
    // Sync to DB
    db.updateOrg(org.id, org);
  },
  editUser({ commit }, user) {
    commit('updateUser', user);
    // Sync to DB
    db.updateOrgUser(user.uid, user);
  },
  // eslint-disable-next-line no-empty-pattern
  async findByDomain({}, email) {
    const domain = getEmailDomain(email);
    const matchedOrg = await db.queryByDomain(domain);
    return matchedOrg;
  },
  async fetchUsersForOrg({ commit }, orgId) {
    const users = await db.fetchUsersForOrg(orgId);
    commit('setUsers', users);
  },
  async fetchOrganisationById({ commit }, orgId) {
    const org = await db.fetchOrgById(orgId);
    commit('add', org);
  },
  async fetchOrganisationsForUser({ commit }, orgArr) {
    const orgs = await db.fetchOrgsByIdArr(orgArr);
    commit('setOrgs', orgs);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
