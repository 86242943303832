import { render, staticRenderFns } from "./MessageBar.vue?vue&type=template&id=ea408692&scoped=true&"
import script from "./MessageBar.vue?vue&type=script&lang=js&"
export * from "./MessageBar.vue?vue&type=script&lang=js&"
import style0 from "./MessageBar.vue?vue&type=style&index=0&id=ea408692&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea408692",
  null
  
)

export default component.exports