import { db } from './firebase-config';

const teams = {
  fetchTeamsByOrganisation(organisation, resolve) {
    // get views from localstorage
    const viewCount = JSON.parse(localStorage.getItem('retro:viewCount'));
    // Fetch store and set up watcher
    const query = db.collection('teams').where('organisation', '==', organisation);
    query.onSnapshot(
      snap => {
        const teams = [];
        snap.forEach(doc => {
          const data = doc.data();
          if (!data.archived) {
            teams.push(doc.data());
          }
        });
        // Sort teams by view count
        const teamViews = teams.map(t => {
          if (viewCount) {
            const hasViews = viewCount.find(v => v.team == t.id);
            t.views = hasViews ? hasViews.views : 0;
          }
          return t;
        });
        const sortedTeams = teamViews.sort((a, b) => b.views - a.views);
        // resolve promise
        resolve(sortedTeams);
      },
      err => {
        /* eslint-disable */
        console.log(`Encountered fetch error: ${err}`);
        /* eslint-enable */
      }
    );
  },
  async fetchTeamById(id) {
    const team = await db
      .collection('teams')
      .doc(id)
      .get();

    if (team.exists) {
      return team.data();
    } else {
      return false;
    }
  },
  addTeam(team) {
    if (team) {
      db.collection('teams')
        .doc(team.id)
        .set(team);
    }
  },
  updateTeam(id, data) {
    if (data) {
      const query = db.collection('teams').doc(id);
      query.update(data);
    }
  },
};

export default teams;
