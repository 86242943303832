<template>
  <Layout layout="settings" hideHeader="true">
    <div class="content">
      <div class="page-close">
        <router-link to="/boards"><IconClose color="#323d47"/></router-link>
      </div>
      <Logo variant="dark-logo" />
      <h1>Upgrade your plan</h1>
      <p>
        Upgrade to a paid plan to benefit from more features such as private Retros, more team boards and unlimited
        collaborators.
      </p>
      <div class="form" v-if="currentOrg && currentOrg.id">
        <h2 v-if="currentOrg.name">{{ currentOrg.name }}</h2>
        <div v-if="!currentOrg.name" class="form-field">
          <label>Give your Organisation a name</label>
          <input
            v-model="newOrgName"
            type="text"
            class="form-input"
            placeholder="i.e the name of your company or department"
          />
        </div>
        <div class="form-row">
          <h3>Choose a plan</h3>
          <choose-plan :selectedPlan="plan.planId || currentOrgPlanId" @selectPlan="onSelectPlan" />
        </div>
        <div class="form-row" v-if="plan.priceId && plan.priceOptions">
          <label>Choose a billing option</label>
          <choose-payment-plan
            :selectedPlan="plan.planId"
            :selectedPrice="plan.priceId"
            :priceOptions="plan.priceOptions"
            @selectPriceOption="onSelectPriceOption"
          />
        </div>
        <div class="form-row" v-if="currentOrg && currentOrg.id">
          <h3>Review &amp; Confirm</h3>
          <p>
            If you decide in the future that this plan does not meet your needs, you can change it at anytime.
          </p>
          <Button @click="openCheckout()" :isLoading="isLoading" :isDisabled="isDisabled">Upgrade your plan</Button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import subscriptions from '@/db/subscriptions';
import ChoosePlan from '@/components/ChoosePlan';
import ChoosePaymentPlan from '@/components/ChoosePaymentPlan';
import Button from '@/components/Button';
import IconClose from '@/components/icons/Close';
import Layout from '@/components/Layout';
import Logo from '@/components/Logo';

export default {
  name: 'OrganisationUpgrade',
  data: () => ({
    isLoading: false,
    priceOptions: null,
    plan: {},
    newOrgName: '',
  }),
  components: {
    Button,
    ChoosePlan,
    ChoosePaymentPlan,
    IconClose,
    Layout,
    Logo,
  },
  computed: {
    currentOrg() {
      return this.$store.getters['organisations/currentOrganisation'];
    },
    currentOrgPlanId() {
      return this.currentOrg ? this.currentOrg.plan.planId : 'free';
    },
    isDisabled() {
      return !this.plan.planId || this.currentOrgPlanId === this.plan.planId;
    },
  },
  methods: {
    onSelectPlan({ planId, priceId, priceOptions }) {
      this.plan = { planId, priceId, priceOptions };
    },
    onSelectPriceOption(option) {
      this.plan.priceId = option;
    },
    openCheckout() {
      if (!this.currentOrg.name && !this.newOrgName) {
        alert('An organisation name is required');
        return;
      }

      if (this.isDisabled) {
        alert('You are already on a Free account');
        return;
      }

      this.isLoading = true;
      if (this.newOrgName && this.newOrgName.length) {
        // Update Org name if required
        this.$store.dispatch('organisations/edit', {
          ...this.currentOrg,
          name: this.newOrgName,
        });
      }

      // Start checkout session
      subscriptions.createSessionAndRedirect(this.plan.priceId);
    },
  },
};
</script>

<style scoped lang="scss">
.page-close {
  position: fixed;
  top: $space_xs;
  right: $space_xs;
  border-radius: 20px;
  cursor: pointer;

  a {
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
  }

  &:hover {
    background: $color_body;
  }
}
.form {
  margin: $space_md 0;
}
.form-field {
  max-width: 500px;
}
.input-description {
  font-size: 13px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
}
.form-row {
  margin: $space_lg 0;
}
</style>
