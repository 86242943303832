<template>
  <div class="column-manager">
    <label>Board columns</label>
    <draggable :list="cols" group="columns" class="rows" handle=".handle" ghost-class="ghost" @change="onOrderChange">
      <div v-for="(col, index) in cols" :key="col.id" class="row">
        <div class="handle">
          <icon-drag color="#cacaca" />
        </div>
        <input
          class="input"
          placeholder="Enter a column name..."
          v-model="col.title"
          @change="onValueChange"
          type="text"
        />
        <span class="remove-row" @click="removeColumn(index)">
          <icon-close />
        </span>
      </div>
    </draggable>
    <div class="create-column" @click="addColumn"><IconPlus color="#323d47" />Add a column</div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { generateId } from '@/utils';
import IconClose from '@/components/icons/Close';
import IconPlus from '@/components/icons/Plus';
import IconDrag from '@/components/icons/Drag';

export default {
  name: 'ColumnManager',
  props: ['columns'],
  components: {
    draggable,
    IconClose,
    IconPlus,
    IconDrag,
  },
  computed: {
    cols() {
      return JSON.parse(JSON.stringify(this.columns));
    },
  },
  methods: {
    removeColumn(index) {
      if (
        confirm(
          'If you delete this column you may lose any cards associated with it. Are you sure you wish to continue?'
        )
      ) {
        this.cols.splice(index, 1);
        this.emitChange(this.cols);
      }
    },
    addColumn() {
      this.cols.push({
        title: '',
        id: generateId(),
        order: this.columns.length + 1,
      });
      this.emitChange(this.cols);
    },
    emitChange(cols) {
      this.$emit('columnChange', cols);
    },
    onValueChange() {
      this.emitChange(this.cols);
    },
    onOrderChange() {
      this.emitChange(this.sortColumns(this.cols));
    },
    sortColumns(columns) {
      const orderedColumns = [];
      columns.forEach((col, index) => {
        orderedColumns.push({
          ...col,
          order: index,
        });
      });
      return orderedColumns;
    },
  },
};
</script>

<style scoped lang="scss">
.column-manager {
  margin-top: $space_sm;
  margin-bottom: $space_sm;
}
.create-column {
  background: #f6f6f6;
  border-radius: 3px;
  padding: 6px 8px;
  line-height: 24px;
  font-size: 14px;
  text-decoration: none;
  color: $color_primary;
  cursor: pointer;
  display: flex;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &:hover {
    background: #eee;
  }
}
.row {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 3px;
  padding: 3px;
  position: relative;

  &:hover .locked {
    display: block;
  }
}
/* DragDrop Ghost */
.row.ghost {
  opacity: 0.4;
}
.input {
  font-size: 16px;
  padding: 4px 12px;
  border-radius: 3px;
  border: solid 1px #cacaca;
  width: 100%;
  flex: 1;
}
.handle {
  width: 24px;
  height: 24px;
  margin-left: 2px;
  margin-right: 4px;
  cursor: move;
}
.remove-row {
  display: flex;
  align-items: center;
  width: 24px;
  margin-right: 2px;
  margin-left: 4px;
  justify-content: center;
  cursor: pointer;
}
.instructions {
  font-size: 12px;
  margin-bottom: $space_xs;
  background: #fffbdb;
  display: inline-block;
  padding: 2px 4px;
  border-radius: 2px;
}
</style>

<style lang="scss">
.remove-row {
  .icon {
    width: 18px;
    height: 18px;
  }
  svg {
    fill: #cacaca;
  }
  &:hover {
    svg {
      fill: $color_red;
    }
  }
}
</style>
