<template>
  <div class="payment-options">
    <div
      v-for="price in priceOptions"
      :key="price.id"
      class="payment-option"
      :class="{ selected: selectedPrice == price.id }"
      @click="selectPriceOption(price.id)"
    >
      <Check />
      <div class="payment-details">
        <span>{{ formatPriceInterval(price.interval) }}</span>
        <div class="price">{{ formatPrice(price) }} <em>USD</em></div>
      </div>
    </div>
  </div>
</template>

<script>
import Check from '@/components/atoms/Check';

export default {
  name: 'ChoosePlan',
  props: ['selectedPlan', 'selectedPrice', 'priceOptions'],
  components: {
    Check,
  },
  methods: {
    selectPriceOption(option) {
      this.$emit('selectPriceOption', option);
    },
    formatPrice(priceData) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: priceData.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format((priceData.unit_amount / 100).toFixed(2));
    },
    formatPriceInterval(interval) {
      switch (interval) {
        case 'month':
          return 'Monthly';
        case 'year':
          return 'Yearly';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-options {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
}
.payment-option {
  border: solid 2px $color_input_border;
  border-radius: 6px;
  padding: $space_xs;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    border: solid 2px $color_blue;
    cursor: pointer;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .check {
    background: transparent;
    border-color: $color_input_border;
  }

  &.selected {
    border: solid 2px $color_blue;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);

    .check {
      background: $color_blue;
      border-color: $color_blue;
    }
  }
}
.payment-details {
  display: flex;
  padding-left: $space_xxs;
  flex: 1;

  span {
    text-transform: capitalize;
  }
}
.price {
  margin-left: auto;
  font-weight: $font_weight_bold;

  em {
    font-weight: $font_weight_regular;
    font-style: normal;
    opacity: 0.7;
  }
}
</style>
