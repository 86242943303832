import { db, firebaseApp } from './firebase-config';
import { loadStripe } from '@stripe/stripe-js/pure';

import store from '@/store';
import planDetails from '@/constants/plans';

const stripeKey = process.env.VUE_APP_STRIPE_KEY_PUBLIC;

const subscription = {
  async fetchOrganisationSubscription(organisationId, resolve) {
    db.collection('customers')
      .doc(organisationId)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async snapshot => {
        // In this implementation we only expect one active or trialing subscription to exist.
        const doc = snapshot.docs[0];
        if (doc) {
          const data = doc.data();
          const priceRef = await data.price.get();
          const planId = priceRef.ref.parent.parent.id;
          resolve({ ...data, priceId: data.price.id, planId: planId });
        } else {
          resolve({
            planId: 'free',
            priceId: null,
          });
        }
      });
  },
  async fetchPlans() {
    return db
      .collection('plans')
      .where('active', '==', true)
      .get()
      .then(querySnapshot => {
        const plans = [];
        // add free plan
        plans.push(planDetails.findPlan('free'));

        // Loop through Stripe plans and add
        querySnapshot.forEach(async function(doc) {
          const matchedPlan = planDetails.findPlan(doc.id);
          const plan = { ...doc.data(), ...matchedPlan, id: doc.id };
          const priceSnap = await doc.ref
            .collection('prices')
            .orderBy('unit_amount')
            .get();

          priceSnap.docs.forEach(doc => {
            plan.prices = plan.prices || [];
            plan.prices.push({ ...doc.data(), id: doc.id });
          });
          plans.push(plan);
        });
        // Return plans array
        return plans;
      });
  },
  async createSession(priceId, orgId) {
    // set or get current organisation
    if (!orgId) {
      const organisation = store.getters['organisations/currentOrganisation'];
      orgId = organisation.id;
    }
    // Create new Checkout Session in DB
    return await db
      .collection('customers')
      .doc(orgId)
      .collection('checkout_sessions')
      .add({
        price: priceId,
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
  },
  async createSessionAndRedirect(priceId, orgId = null) {
    // We have a session, let's redirect to Checkout
    const stripeJs = await loadStripe(`${stripeKey}`);
    // Create new Checkout Session in DB
    const session = await this.createSession(priceId, orgId);
    // Wait for the CheckoutSession to get attached by the extension
    session.onSnapshot(snap => {
      const { sessionId } = snap.data();
      if (sessionId) {
        stripeJs.redirectToCheckout({ sessionId });
      }
    });
  },
  async createPortalLink() {
    // get current organisation
    const organisation = store.getters['organisations/currentOrganisation'];
    // Get organisations Stripe Customer ID
    const customerId = (
      await db
        .collection('customers')
        .doc(organisation.id)
        .get()
    ).data().stripeId;

    // Call billing portal function
    const functionRef = firebaseApp()
      .functions('us-central1')
      .httpsCallable('createStripePortalLink');

    const { data } = await functionRef({
      returnUrl: window.location.origin,
      customerId,
    });

    return data;
  },
};

export default subscription;
