<template>
  <div id="app">
    <router-view />
    <div v-if="isLoggingIn" class="loader-overlay">
      <pattern-background>
        <div class="loader" />
      </pattern-background>
    </div>
  </div>
</template>

<script>
import PatternBackground from '@/components/PatternBackground';

export default {
  name: 'App',
  components: {
    PatternBackground,
  },
  computed: {
    isLoggingIn() {
      return this.$store.state.loggingIn;
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background: #f3f5f7;
}
#app {
  font-family: $font_family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color_text;
  font-size: 16px;
  height: 100vh;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color_body;
}
</style>
