<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 4C8.35789 4 5.00003 7.35786 5.00003 11.5C5.00003 12.7768 5.32018 13.9772 5.88202 15.0287C6.01587 15.2792 6.03688 15.5748 5.93982 15.8417L4.67213 19.3279L8.15829 18.0602C8.42535 17.9631 8.72113 17.9842 8.97171 18.1182C10.0235 18.6809 11.2215 19 12.5 19C16.6422 19 20 15.6421 20 11.5C20 7.35786 16.6422 4 12.5 4ZM3.00003 11.5C3.00003 6.25329 7.25332 2 12.5 2C17.7467 2 22 6.25329 22 11.5C22 16.7467 17.7467 21 12.5 21C11.0459 21 9.66902 20.6726 8.43594 20.0874L3.34177 21.9398C2.97664 22.0726 2.56765 21.9818 2.29292 21.7071C2.01819 21.4324 1.92746 21.0234 2.06023 20.6583L3.91275 15.5638C3.32844 14.3315 3.00003 12.9531 3.00003 11.5Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'Chat',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
