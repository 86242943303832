import store from '@/store';
import features from '@/constants/features';
import plans from '@/constants/plans';

/**
 * teamIsInCurrentOrg
 * is the team the user is accessing part of their Currently selected organisation?
 * Note: Doesnt appear to be used anywhere?
 */
const teamIsInCurrentOrg = team => {
  const currentOrgId = store.getters['organisations/currentId'];
  return currentOrgId == team.organisation;
};

/**
 * teamAllowsAnonUsers
 * Does this team/board allow anonymous users?
 */
const teamAllowsAnonUsers = team => {
  return !!(team && team.settings && team.settings.anonUsersAllowed);
};

/**
 * isMemberOfTeam
 * is the current user a member of this teamID?
 */
const isMemberOfTeam = teamId => {
  const team = store.getters['teams/teamById'](teamId);
  if (team) {
    return isTeamMember(team);
  }
  return false;
};

/**
 * isTeamMember
 * is the current user a member of the passed in Team?
 */
const isTeamMember = team => {
  const user = store.getters['user/user'];
  if (user && team && team.organisation) {
    const teamOrg = team.organisation;
    return user.organisations.indexOf(teamOrg) > -1;
  }

  return false;
};

/**
 * boardAccessAllowed
 * is this current user allowed to access this team?
 */
const boardAccessAllowed = team => {
  const currentTeam = team || store.getters['teams/currentTeam'];
  if (currentTeam) {
    return isTeamMember(currentTeam) || teamAllowsAnonUsers(currentTeam);
  }

  return false;
};

/**
 * isOrgOwner
 * is the user who was passed in, or if not, the current user, the owner of this Organistion?
 */
const isOrgOwner = (orgId = false, user = false) => {
  orgId = orgId || store.getters['organisations/currentId'];
  user = user || store.getters['user/user'];
  if (user && orgId) {
    return !!(user.roles && user.roles.find(r => r.organisationId === orgId && r.role > 9));
  }
  return false;
};

/**
 * canCreateMoreTeams
 * Checks if more teams can be created, checks Current org Tier existing team length
 */
const canCreateMoreTeams = () => {
  const teamCount = store.getters['teams/teamCount'];
  const organisation = store.getters['organisations/currentOrganisation'];
  if (organisation) {
    const teamLimit = plans.findPlan(organisation.plan.planId).permissions.teamLimit;
    return teamCount < teamLimit;
  }
  // if user does not have an organisation, return true for now
  return true;
};

/**
 * canCreatePrivateTeams
 * Checks if more teams can be created, checks Current org Tier existing team length
 */
const canCreatePrivateTeams = () => {
  const organisation = store.getters['organisations/currentOrganisation'];
  if (organisation) {
    return plans.findPlan(organisation.plan.planId).permissions.privateBoards;
  }
  // if user does not have an organisation, return true for now
  return false;
};

/**
 * isFreeVersion
 * Checks if the user is currently on the free version
 */
const isFreeVersion = () => {
  const organisation = store.getters['organisations/currentOrganisation'];
  if (!organisation) {
    return true;
  }
  // check for plan tier
  return organisation && organisation.plan.tier < 2;
};

/**
 * canCreateAnOrganisation
 * Can the current user create an organisation
 */
const canCreateAnOrganisation = orgTier => {
  const isNotFree = orgTier > 1;
  return features.createNewOrganisations && isNotFree;
};

/**
 * canUpgradeOrganisation
 * Can the current org be upgraded
 */
const canUpgradeOrganisation = orgTier => {
  const isFree = orgTier < 2;
  return features.upgradeOrganisations && isFree;
};

export {
  boardAccessAllowed,
  canCreateMoreTeams,
  canCreatePrivateTeams,
  canCreateAnOrganisation,
  canUpgradeOrganisation,
  isFreeVersion,
  isTeamMember,
  isMemberOfTeam,
  isOrgOwner,
  teamAllowsAnonUsers,
  teamIsInCurrentOrg,
};
