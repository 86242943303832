<template>
  <div class="login-gate">
    <pattern-background>
      <div class="container">
        <div class="box" v-if="!showLogin">
          <guest-access-form v-if="allowsAnonUsers" :team="currentTeam" @showLogin="toggleLogin" />
        </div>
        <div class="box" v-if="showLogin">
          <login showLogo="true" message="If you are already a member of this team, please sign in." />
        </div>
      </div>
    </pattern-background>
  </div>
</template>

<script>
import Login from '@/components/Login';
import GuestAccessForm from '@/components/GuestAccessForm';
import PatternBackground from '@/components/PatternBackground';
import { teamAllowsAnonUsers } from '@/utils/permissions';

export default {
  name: 'LoginGate',
  props: ['teamId'],
  components: {
    Login,
    GuestAccessForm,
    PatternBackground,
  },
  data: () => ({
    showLogin: false,
  }),
  computed: {
    currentTeam() {
      return this.$store.getters['teams/currentTeam'];
    },
    allowsAnonUsers() {
      const team = this.currentTeam;
      return teamAllowsAnonUsers(team);
    },
  },
  methods: {
    toggleLogin() {
      this.showLogin = !this.showLogin;
    },
  },
};
</script>

<style scoped lang="scss">
.login-gate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.container {
}
.box {
}
</style>
