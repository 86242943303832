<template>
  <settings-container title="Invite users" :showButton="false">
    <p>
      Only members of this organisation can particpate in Private Retros you create.
    </p>
    <p>
      You can either send out invite links to those you want to join <u>OR</u> if you would like to streamline this
      process you can enter your companies email domain so that anyone who signs up with a verified email address from
      that domain will automatically be added to this organisation.
    </p>
    <hr />
    <div class="form-field">
      <label>Invite link</label>
      <p class="label">Send this link anyone you'd like to join your organisation.</p>
      <generate-invite :organisationId="organisation.id" />
    </div>
    <hr />
    <div class="form-field">
      <label>Email domain</label>
      <p class="label">Allow your team to sign up automatically by entering your Email Domain below.</p>
      <div class="form-field prefix inline">
        <div class="input-prefix">anyone@</div>
        <input class="form-input" type="text" v-model="organisation.domain" placeholder="yourcompany.com" />
        <Button @click="updateEmailDomain" :isLoading="isLoadingEmail">Save</Button>
      </div>
      <div class="form-error" v-if="!domainIsValid">Sorry, the domain "{{ organisation.domain }}" is not allowed.</div>
    </div>
  </settings-container>
</template>

<script>
import { isAllowedDomain } from '@/utils/email';
import Button from '@/components/Button';
import GenerateInvite from '@/components/GenerateInvite';
import SettingsContainer from '@/components/SettingsContainer';

export default {
  name: 'SettingOrgName',
  props: ['organisation'],
  components: {
    Button,
    GenerateInvite,
    SettingsContainer,
  },
  data: () => ({
    isLoadingEmail: false,
    domainIsValid: true,
  }),
  watch: {
    'organisation.domain'(value) {
      if (isAllowedDomain(value)) {
        this.domainIsValid = false;
      } else {
        this.domainIsValid = true;
      }
    },
  },
  methods: {
    updateEmailDomain() {
      if (this.domainIsValid) {
        let domain = this.organisation.domain;
        this.isLoadingEmail = true;

        this.$store.dispatch('organisations/edit', {
          ...this.organisation,
          domain: domain,
        });
        // TODO This is hacky...fix it
        setTimeout(() => {
          this.isLoadingEmail = false;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-input {
  padding-left: 24px;
  position: relative;

  input[type='radio'] {
    position: absolute;
    top: -2px;
    left: 0;
    width: 14px;
    height: 14px;
  }
}
.radio-input + .radio-input {
  margin-top: 8px;
}
.radio-input-content {
  padding-top: 8px;
  margin-bottom: $space_xs;
}
.form-error {
  font-size: 14px;
  color: red;
  margin-top: $space_xxs;
}
</style>
