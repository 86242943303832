<template>
  <pattern-background>
    <div v-if="codeIsLoading">
      <div class="loader" />
    </div>
    <div v-if="!codeIsValid && !codeIsLoading">
      <div class="message">
        <Logo variant="dark-logo" />
        <p>
          Sorry, <strong>this invite has expired</strong>, please contact the person who sent it to you to let them
          know.
        </p>
        <Button size="large" @click="goBackHome()">Back home</Button>
      </div>
    </div>
    <login v-if="codeIsValid" showLogo="true" message="👋 You have been invited to join a Retro!" />
  </pattern-background>
</template>

<script>
import auth from '@/auth';
import invites from '@/auth/invites';
import Button from '@/components/Button';
import Login from '@/components/Login';
import Logo from '@/components/Logo';
import PatternBackground from '@/components/PatternBackground';

export default {
  name: 'Invite',
  components: {
    Button,
    Login,
    Logo,
    PatternBackground,
  },
  data: () => ({
    codeIsLoading: true,
    codeIsValid: false,
  }),
  created() {
    auth.setRedirectUrl('/boards');
    this.inviteCode = this.$route.params.inviteCode;
    this.validateInviteCode(this.inviteCode);
  },
  methods: {
    goBackHome() {
      this.$nextTick(() => {
        this.$router.push({ name: 'Home' });
      });
    },
    async validateInviteCode(code) {
      const isValid = await invites.validateInvite(code);
      this.codeIsLoading = false;

      if (isValid) {
        // store the code if its valid
        invites.setInviteCode(code);
        this.codeIsValid = true;
      } else {
        this.codeIsValid = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  padding: $space_md 0;
  max-width: 410px;
  width: 100%;
  font-weight: $font_weight_regular;

  p {
    font-size: 18px;
    line-height: 1.4;
    margin: $space_md 0;
  }
}
</style>
