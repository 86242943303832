<template>
  <button class="button" :class="[size, type, isDisabled && 'disabled']" @click="onClick">
    <span v-if="isLoading" class="spinner"></span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['isLoading', 'isDisabled', 'size', 'type'],
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  border: none;
  padding: 0 $space_xs;
  line-height: 38px;
  background: $color_button_background;
  color: $color_button_color;
  font-size: 14px;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: $font_weight_bold;

  &:hover {
    background: darken($color_button_background, 5%);
  }

  &:focus,
  &:active {
    outline: none;
  }

  &.disabled {
    background: $color_text_grey;
    cursor: not-allowed;
  }

  &.small {
    line-height: 28px;
    padding: 0 10px;
  }

  &.large {
    line-height: 58px;
    font-size: 16px;
    font-weight: $font_weight_bold;
    width: 100%;
    justify-content: center;
  }

  &.cta {
    line-height: 50px;
    font-size: 16px;
    font-weight: $font_weight_bold;
  }

  &.secondary {
    background-color: transparent;
    border: solid 1px $color_button_background;
    color: $color_button_background;

    &:hover {
      background: darken($color_button_background, 5%);
      color: white;
    }
  }
}
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: block;
}
.spinner {
  margin: 0 0.5rem 0 0;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.35);
  border-right: 2px solid rgba(255, 255, 255, 0.35);
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  transform: translateZ(0);
  animation: load8 0.5s infinite linear;
}
</style>
