<template>
  <div class="field" :class="{ hasError: error }">
    <input :id="id" class="input" type="text" :placeholder="placeholder" :value="value" @input="handleChange" />
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: ['error', 'placeholder', 'value', 'id'],
  methods: {
    handleChange(e) {
      this.$emit('handleChange', e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.field {
  margin-bottom: $space_sm;
}
.hasError {
  .input {
    border-color: $color_red;
  }
}
.input {
  border: solid 1px $color_input_border;
  border-radius: 6px;
  background: white;
  line-height: 58px;
  font-size: 16px;
  padding: 0 $space_sm;
  width: 100%;

  &:focus {
    outline: none;
    border-color: $color_input_focus;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);
  }
}
</style>
