<template>
  <div class="user-menu" v-if="user && user.displayName && !isGuest">
    <div class="user-button" @click="toggleMenu">
      {{ user.displayName }}
      <IconChevronDown />
    </div>
    <user-menu-dropdown @toggleMenu="toggleMenu" :isOpen="isOpen" :user="user" />
  </div>
</template>

<script>
import IconChevronDown from '@/components/icons/ChevronDown';
import UserMenuDropdown from '@/components/UserMenuDropdown';

export default {
  name: 'UserMenu',
  components: {
    IconChevronDown,
    UserMenuDropdown,
  },
  data: () => ({
    isOpen: null,
  }),
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    isGuest() {
      return this.$store.getters['user/isGuest'];
    },
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.user-menu {
  position: relative;
}
.user-button {
  cursor: pointer;
  font-weight: $font_weight_bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
.user-button {
  color: $color_white;

  .icon {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
  svg {
    fill: $color_white;
  }
}
.user-dropdown-item {
  .icon {
    width: 18px;
    height: 18px;
    margin-left: 4px;
    transform: rotate(270deg);
    position: relative;
    right: -4px;
  }
  svg {
    fill: $color_text;
  }
}
</style>
