<template>
  <div class="board-creator">
    <h2>New Board</h2>
    <p class="small">
      Create a new Retro board for your team or project. You can choose from a pre-configured template or do it yourself
      (via the Blank Template).
    </p>
    <div class="form-field">
      <label>Board name <em>(optional)</em></label>
      <input v-model="newBoard.name" type="text" class="form-input" />
    </div>
    <template-chooser :columns="newBoard.columns" @columnChange="onColumnChange" />
    <div class="modal-button">
      <Button @click="save" :isLoading="isSaving">Create board</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
import TemplateChooser from '@/components/TemplateChooser';

export default {
  name: 'BoardCreator',
  props: ['teamId'],
  components: {
    Button,
    TemplateChooser,
  },
  data: () => ({
    newBoard: {
      columns: [],
      settings: {
        votingAllowed: true,
        actionsAllowed: false,
        plus1sAllowed: true,
      },
    },
    inviteMode: 1,
    isSaving: false,
  }),
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
  methods: {
    getUserId() {
      return this.user && this.user.uid;
    },
    onColumnChange(cols) {
      this.newBoard.columns = [...cols];
    },
    close() {
      this.$emit('close');
    },
    async save() {
      const userId = this.getUserId();
      this.isSaving = true;
      // create board
      const newBoardId = await this.$store.dispatch('boards/add', {
        ...this.newBoard,
        teamId: this.teamId,
        createdBy: userId,
      });
      this.isSaving = false;
      // redirect to new board
      this.$router.push({
        name: 'Board',
        params: {
          teamId: this.teamId,
          boardId: newBoardId,
        },
        query: { prompt: 'true' },
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.board-creator {
  font-size: 14px;
}
.small {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: $space_md;
}
</style>
