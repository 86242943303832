<template>
  <div class="header">
    <div class="wrapper header-wrapper">
      <div class="header-item blue">
        <Logo :linkTo="logoLink" />
        <team-menu v-show="teamId" />
      </div>
      <div class="header-item user">
        <span class="user-button" v-if="!isLoggedIn && !isGuest" @click="login">Login</span>
        <user-menu />
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth';
import Logo from '@/components/Logo';
import TeamMenu from '@/components/TeamMenu';
import UserMenu from '@/components/UserMenu';

export default {
  name: 'Header',
  components: {
    Logo,
    UserMenu,
    TeamMenu,
  },
  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
    boardId() {
      return this.$route.params.boardId;
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    isGuest() {
      return this.$store.getters['user/isGuest'];
    },
    currentOrgId() {
      return this.$store.getters['organisations/currentId'];
    },
    logoLink() {
      return this.isGuest ? '/' : '/boards';
    },
  },
  methods: {
    login() {
      window.location.href = '/';
    },
    logout() {
      auth.logout();
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: $size_header;
  display: flex;
  align-items: center;
  padding: 0;
  background: $color_greyblue_700;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.header-item {
  display: flex;
  font-size: 13px;
  text-transform: uppercase;
  line-height: $size_header;

  &.blue {
    background: $color_greyblue_800;
  }
}
.nav {
  display: none;
}
@media (min-width: 768px) {
  .nav {
    display: block;
    line-height: $size_header;
    background: $color_greyblue_700;
  }
}
.nav-link {
  margin-left: $space_sm;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  position: relative;

  &:hover:after,
  &.active-board:after,
  &.router-link-exact-active:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: white;
  }
}
.user {
  margin-right: $space_sm;
}
.user-button {
  cursor: pointer;
  font-weight: $font_weight_bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    text-decoration: underline;
  }
}
</style>
