<template>
  <div class="team">
    <div class="team-name" v-if="currentTeam && currentTeam.name && !isLoggedIn">
      {{ currentTeam && currentTeam.name }}
    </div>
    <div class="team-menu" v-if="isLoggedIn">
      <div class="team-name btn" @click="toggleMenu">
        <span>{{ (currentTeam && currentTeam.name) || 'Choose team' }}</span>
        <IconChevronDown :class="{ rotate: isOpen }" />
      </div>
      <team-menu-dropdown @toggleMenu="toggleMenu" :isOpen="isOpen" />
    </div>
  </div>
</template>

<script>
import IconChevronDown from '@/components/icons/ChevronDown';
import TeamMenuDropdown from '@/components/TeamMenuDropdown';

export default {
  name: 'TeamMenu',
  components: {
    IconChevronDown,
    TeamMenuDropdown,
  },
  data: () => ({
    isOpen: null,
  }),
  computed: {
    currentTeam() {
      return this.$store.getters['teams/currentTeam'];
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.team {
  display: none;
}
@media (min-width: 768px) {
  .team {
    display: block;
  }
}
.team-name {
  line-height: $size_header;
  padding: 0 $space_sm;
  border-left: solid 1px rgba(0, 0, 0, 0.15);
  color: white;
  font-weight: $font_weight_bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.team-name.btn {
  cursor: pointer;
  min-width: 142px;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  .icon {
    width: 18px;
    height: 18px;
    margin-left: $space_xxs;
    transition: transform linear 100ms;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}
</style>

<style lang="scss">
.team-name.btn {
  svg {
    fill: white;
  }
}
</style>
