<template>
  <Layout>
    <div class="content">
      <message-bar v-if="showUpgradeBanner">
        You are currently on the Free version of Retro.
        <router-link to="/settings/plan">Upgrade to a Teams or Pro account</router-link> to invite others and create
        more Retros.
      </message-bar>
      <div class="teams">
        <div v-if="teams.length">
          <p><strong>Choose a team/project below to participate in a team Retro.</strong></p>
        </div>
        <div v-if="!teams.length">
          <p><strong>Create your first Retro Team to get started.</strong></p>
        </div>
        <div class="teams-grid">
          <team-card v-for="team in teams" :team="team" :key="team.id" @editTeam="editTeam" />
          <div class="new-team" @click="newTeam"><IconPlus />New Team / Project</div>
        </div>
        <modal v-if="isModalVisible" @close="closeModal">
          <new-team-form @close="closeModal" :existingTeam="editingTeam" />
        </modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import { isFreeVersion } from '@/utils/permissions';
import IconPlus from '@/components/icons/Plus';
import Layout from '@/components/Layout';
import MessageBar from '@/components/MessageBar';
import Modal from '@/components/Modal';
import NewTeamForm from '@/components/NewTeamForm';
import TeamCard from '@/components/TeamCard';

export default {
  name: 'TeamCards',
  components: {
    IconPlus,
    Layout,
    MessageBar,
    Modal,
    NewTeamForm,
    TeamCard,
  },
  data: () => ({
    isModalVisible: false,
    editingTeam: null,
  }),
  computed: {
    teams() {
      return this.$store.getters['teams/teams'];
    },
    showUpgradeBanner() {
      // Only show the upgrade banner if a user is on the free version
      // AND they have created a Retro board
      return isFreeVersion() && this.teams && this.teams.length;
    },
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    newTeam() {
      this.editingTeam = null;
      this.showModal();
    },
    editTeam(teamId) {
      this.editingTeam = this.teams.find(t => t.id === teamId);
      this.showModal();
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
}
.teams {
  padding: $space_sm;
}
.teams-grid {
  margin-top: $space_md;
  text-align: left;
  display: grid;
  grid-column-gap: 1.5rem;
  grid-template-columns: 1fr;
  max-width: 1368px;
}
@media (min-width: 500px) {
  .teams-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .teams-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 900px) {
  .teams-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
.new-team {
  margin-bottom: $space_sm;
  min-height: 115px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  color: $color_text_grey;
  font-weight: $font_weight_bold;
  text-transform: uppercase;
  border: dashed 1px #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }
}
</style>
<style lang="scss">
.new-team {
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-left: -4px;
  }
  svg {
    fill: $color_text_grey;
  }
}
</style>
