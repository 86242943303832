<template>
  <div class="scroller">
    <div v-if="!orderedColumns || !orderedColumns.length" class="message">
      You have not created any Columns on your board yet.<br />Click the ⚙︎ in the top right to create some.
    </div>
    <div class="cols">
      <div v-for="col in orderedColumns" class="col" :key="col.id">
        <div class="title">
          {{ col.title }}
        </div>
        <column
          :columnId="col.id"
          :boardId="board.id"
          :teamId="teamId"
          :settings="board.settings"
          :boardProgress="board.progress"
        />
      </div>
      <div class="col col-highlighted" :class="{ hidden: !board.progress || board.progress < 4 }">
        <div class="title"><IconChat color="#323d47" />Cards to discuss</div>
        <column
          :columnId="discussionColumnId"
          :boardId="board.id"
          :teamId="teamId"
          :settings="board.settings"
          :boardProgress="board.progress"
          :isLocked="true"
        >
          <div class="instruction">
            Drag cards here that you'd like to discuss more or click to gather the top cards automatically.
            <Button size="small" @click="gatherDiscussionCards()">Gather cards</Button>
          </div>
        </column>
      </div>
    </div>
    <card-merge-popup />
    <share-prompt v-if="shouldShowPrompt" :boardId="board.id" :teamId="teamId" />
  </div>
</template>

<script>
import Button from '@/components/Button';
import CardMergePopup from '@/components/CardMergePopup';
import Column from '@/components/Column';
import IconChat from '@/components/icons/Chat';
import SharePrompt from '@/components/SharePrompt';

export default {
  name: 'Columns',
  props: ['board', 'teamId'],
  components: {
    Button,
    Column,
    CardMergePopup,
    IconChat,
    SharePrompt,
  },
  data: () => ({
    discussionColumnId: 'discuss-a01',
  }),
  created() {
    this.fetchCards(this.board.id);
  },
  watch: {
    'board.id'(boardId) {
      if (boardId) {
        this.fetchCards(boardId);
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    orderedColumns() {
      return this.orderColumns();
    },
    shouldShowPrompt() {
      // Shows share prompt if
      // - User is the board creator
      // - The Board has columns > 1
      // - The board is in the first progress step
      const isCreator = this.board.createdBy === this.getUserId();
      return this.board.columns.length > 1 && isCreator && this.board.progress < 2;
    },
  },
  methods: {
    getUserId() {
      return this.user && this.user.uid;
    },
    orderColumns() {
      return this.board.columns ? this.board.columns.sort((a, b) => a.order - b.order) : [];
    },
    fetchCards(boardId) {
      this.$store.dispatch('cards/reset');
      this.$store.dispatch('cards/fetchCards', boardId);
      this.$store.dispatch('tasks/fetchTasksForTeam', this.teamId);
      this.$store.dispatch('mergeQueue/reset');
    },
    gatherDiscussionCards() {
      // gathers cards with votes and sorts them by most votes and then moves them to the discussion board
      const newColumnId = this.discussionColumnId;
      const cardsWithVotes = this.$store.getters['cards/cards'].filter(x => x.votes.length);
      const sortedCards = cardsWithVotes.sort(
        (a, b) => b.votes.length - a.votes.length || b.plus1s.length - a.plus1s.length
      );

      // Loop through cards and save them
      for (let i = 0; i < sortedCards.length; i++) {
        const card = sortedCards[i];
        const newPosition = i * 100;
        this.$store.dispatch('cards/editCard', {
          ...card,
          position: newPosition,
          columnId: newColumnId,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  padding: $space_sm;
}
.cols {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: $space_xxs;
  min-width: 800px;
  width: 100%;
}
.col {
  height: calc(100vh - #{$size_header * 2});
  overflow-y: auto;
  padding: $space_sm $space_xxs;
  display: flex;
  flex-direction: column;

  &.hidden {
    display: none;
  }
}
.col-highlighted {
  background: rgb(245 246 248);
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.05);
  margin-right: -1rem;
  padding-left: $space_xs;
  padding-right: $space_xs;

  .title {
    margin-bottom: $space_xs;
  }
}
.title {
  color: $color_text;
  font-weight: $font_weight_bold;
  display: flex;
  align-items: center;
  margin-bottom: $space_xxs;
  position: relative;
  padding-right: 24px;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: $space_xxs;
  }
}
.instruction {
  font-size: 13px;
  margin-bottom: $space_xs;

  .button {
    margin-top: $space_xs;
  }
}
.handle {
  width: 24px;
  height: 24px;
  margin-right: $space_xxs;
  cursor: move;
  position: absolute;
  top: 0;
  right: -8px;
}
/* DragDrop Ghost */
.col.ghost {
  opacity: 0.4;
}
</style>
