<template>
  <div class="dropdown-container" v-if="isOpen">
    <div class="dropdown">
      <div class="dropdown-content">
        <div class="title">Click the steps below to progress the Retro.</div>
        <div class="progress-bar">
          <ul :class="['step-' + activeStep]">
            <li class="active" @click="setProgress(1)">
              <span>Add cards to board</span><small>Cards will be blurred</small>
            </li>
            <li :class="{ active: activeStep > 1 }" @click="setProgress(2)">
              <span>Reveal / share cards</span>
              <small>Take turns to explain your cards </small>
            </li>
            <li :class="{ active: activeStep > 2 }" @click="setProgress(3)">
              <span>Vote on cards</span>
              <small>That you want to discuss more</small>
            </li>
            <li :class="{ active: activeStep > 3 }" @click="setProgress(4)">
              <span>Discuss top cards</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="dropdown-footer" @click="openSettings"><IconSettings />Edit board settings</div>
    </div>
    <div class="overlay" @click="toggleMenu"></div>
  </div>
</template>

<script>
import IconSettings from '@/components/icons/Settings';

export default {
  name: 'UserMenuDropdown',
  props: ['isOpen', 'user', 'board', 'activeStep'],
  components: {
    IconSettings,
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
    },
    openSettings() {
      this.$emit('openSettings');
      this.toggleMenu();
    },
    setProgress(step) {
      this.$store.dispatch('boards/edit', {
        ...this.board,
        progress: step,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.overlay {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19;
}
.dropdown-container {
  position: relative;
}
.dropdown {
  position: absolute;
  top: 100%;
  margin-top: 2px;
  right: -$space_sm;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 20;
  min-width: 100%;
  text-transform: none;
}
.dropdown-content {
  padding: $space_xs 20px;
  line-height: 1.2;
  font-size: 14px;
  color: $color_text_grey;
  font-weight: $font_weight_regular;
  min-width: 280px;
}
.title {
  font-size: 14px;
  max-width: 180px;
}
.dropdown-footer {
  font-size: 14px;
  border-top: solid 1px #eee;
  line-height: 24px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.progress-bar {
  margin: $space_sm 0 8px 0;

  small {
    display: block;
    color: $color_icon_grey;
    font-weight: $font_weight_regular;
  }

  ul {
    list-style: none;
    padding: 0 0 0 28px;
    margin: 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 0;
      width: 14px;
      border-radius: 7px;
      background: #dfdfdf;
    }

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 14px;
      min-height: 14px;
      height: 10%;
      border-radius: 7px;
      background: $color_primary;
      transition: height ease-out 150ms;
    }

    &.step-2:after {
      height: 40%;
    }

    &.step-3:after {
      height: 70%;
    }

    &.step-4:after {
      height: 99%;
    }
  }

  li {
    position: relative;
    z-index: 2;
    color: $color_icon_grey;
    font-weight: $font_weight_bold;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: -25px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #fff;
    }

    &:hover {
      color: $color_primary;

      span {
        text-decoration: underline;
      }
    }

    &.active {
      color: $color_primary;
    }
  }

  li + li {
    margin-top: 12px;
  }
}
</style>

<style lang="scss">
.dropdown-footer {
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  svg {
    fill: $color_primary;
  }
  &:hover {
    svg {
      fill: $color_primary;
    }
  }
}
</style>
