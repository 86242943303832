<template>
  <div
    class="card"
    @click.shift="onShiftClick"
    :class="{
      hidden: shouldHideCard,
      isActive: cardIsActive,
      isInQueue: cardIsInQueue,
    }"
    :id="card.id"
  >
    <span v-if="cardIsActive" @click="removeHighlight" class="remove-highlight"><IconClose color="white"/></span>
    <div @dblclick="highlightCard">
      <div class="text" v-if="!isEditing"><span v-html="linkifiedTitle"></span></div>
      <div class="input" v-if="isEditing">
        <resizable-textarea>
          <textarea
            class="input"
            placeholder="Write something..."
            data-gramm_editor="false"
            v-model="card.title"
            @keyup.esc="onCancelEdit"
            @keydown.enter="onSubmitEdit"
            rows="1"
            ref="titleTextarea"
          >
          </textarea>
        </resizable-textarea>
      </div>
      <div class="footer">
        <avatar :user="card.user" />
        <span
          v-if="canPlus1"
          class="bttn bttn-plus1"
          :class="{ voted: didPlus1, compact: !card.plus1s.length }"
          @click="plus1Card"
          data-tooltip="+1 this"
          data-tooltip-top
        >
          <IconVote />
          <span v-if="card.plus1s && card.plus1s.length" class="vote-count">
            {{ card.plus1s.length }}
          </span>
        </span>
        <span
          v-if="canVote"
          class="bttn bttn-vote"
          :class="{ voted: didVote, compact: !card.votes.length }"
          @click="voteOnCard"
          data-tooltip="Vote on this"
          data-tooltip-top
        >
          <IconUpVote />
          <span v-if="card.votes && card.votes.length" class="vote-count">
            {{ card.votes.length }}
          </span>
        </span>
        <span v-if="canAddTask" class="bttn bttn-action" @click="toggleAddTask()">
          Add action
        </span>
        <div class="edit-options">
          <span v-if="isEditing" @click="onSubmitEdit" class="bttn bttn-dark">Save</span>
          <span v-if="canEdit && !isEditing" class="menu-btn" ref="trigger" v-popover="{ name: popoverName }">
            <IconEllipsis color="#a7aab0" />
          </span>
        </div>
      </div>
    </div>
    <card-actions
      :tasks="tasks"
      :cardId="card.id"
      :boardId="card.boardId"
      :teamId="card.teamId"
      :addingTask="addingTask"
      @toggleAddTask="toggleAddTask"
    />
    <popover :name="popoverName">
      <ul class="menu">
        <li class="menu-item" @click="toggleEditable">Edit</li>
        <li class="menu-item" @click="deleteCard">Delete</li>
      </ul>
    </popover>
  </div>
</template>

<script>
import Vue from 'vue';
import anchorme from 'anchorme';
import Avatar from '@/components/Avatar';
import CardActions from '@/components/CardActions';
import IconEllipsis from '@/components/icons/Ellipsis';
import IconUpVote from '@/components/icons/UpVote';
import IconVote from '@/components/icons/Vote';
import IconClose from '@/components/icons/Close';
import Popover from '@/plugins/popover';
import ResizableTextarea from '@/components/ResizableTextarea';

Vue.use(Popover);

export default {
  name: 'Card',
  props: ['card', 'settings', 'addToQueue', 'boardProgress'],
  components: {
    Avatar,
    ResizableTextarea,
    CardActions,
    IconClose,
    IconEllipsis,
    IconUpVote,
    IconVote,
  },
  data: () => ({
    isEditing: false,
    addingTask: false,
  }),
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    popoverName() {
      return `popover-${this.card.id}`;
    },
    linkifiedTitle() {
      return anchorme({
        input: this.card.title,
        options: {
          truncate: 40,
          attributes: {
            target: '_blank',
          },
        },
      });
    },
    canEdit() {
      // handle deprecated userID, since deprecated
      const user = this.card.user;
      const userIsInArray = Array.isArray(user);
      const userIds = userIsInArray ? user.map(u => u.userId) : [this.card.userId];
      return userIds.indexOf(this.getUserId()) > -1;
    },
    canVote() {
      return this.boardProgress && this.boardProgress > 2;
    },
    canPlus1() {
      return this.boardProgress && this.boardProgress > 1 && this.settings.plus1sAllowed;
    },
    canAddTask() {
      return (this.boardProgress && this.boardProgress > 3) || this.settings.actionsAllowed;
    },
    shouldHideCard() {
      return this.canEdit ? false : this.boardProgress && this.boardProgress < 2;
    },
    cardIsActive() {
      return this.settings && this.settings.activeCard && this.settings.activeCard === this.card.id;
    },
    cardIsInQueue() {
      return this.$store.getters['mergeQueue/isCardInQueue'](this.card.id);
    },
    tasks() {
      return this.$store.getters['tasks/tasksForCard'](this.card.id);
    },
    didPlus1() {
      const userId = this.getUserId();
      const plusArr = this.card.plus1s;
      return plusArr && plusArr.indexOf(userId) > -1;
    },
    didVote() {
      const userId = this.getUserId();
      const votesArr = this.card.votes;
      return votesArr && votesArr.indexOf(userId) > -1;
    },
  },
  methods: {
    getUserId() {
      return this.user && this.user.uid;
    },
    onShiftClick() {
      if (!this.cardIsInQueue) {
        this.$store.dispatch('mergeQueue/add', this.card.id);
      } else {
        // TODO remove card from queue
      }
    },
    highlightCard() {
      if (!this.cardIsActive) {
        const cardId = this.card.id;
        const boardId = this.card.boardId;
        this.$store.dispatch('boards/setActiveCard', { cardId, boardId });
      }
    },
    removeHighlight() {
      const cardId = null;
      const boardId = this.card.boardId;
      this.$store.dispatch('boards/setActiveCard', { cardId, boardId });
    },
    voteOnCard() {
      const userId = this.getUserId();
      const card = this.card;
      if (!this.didVote) {
        this.$store.dispatch('cards/editCard', {
          ...card,
          votes: [...card.votes, userId],
        });
      } else {
        const votes = card.votes.filter(vote => vote !== userId);
        this.$store.dispatch('cards/editCard', {
          ...card,
          votes: [...votes],
        });
      }
    },
    plus1Card() {
      const userId = this.getUserId();
      const card = this.card;
      if (!this.didPlus1) {
        this.$store.dispatch('cards/editCard', {
          ...card,
          plus1s: [...card.plus1s, userId],
        });
      } else {
        const plus1s = card.plus1s.filter(vote => vote !== userId);
        this.$store.dispatch('cards/editCard', {
          ...card,
          plus1s: [...plus1s],
        });
      }
    },
    toggleEditable() {
      this.isEditing = !this.isEditing;
      if (this.isEditing) {
        this.$nextTick(() => {
          this.$refs.titleTextarea.focus();
        });
      } else {
        this.save();
      }
    },
    onCancelEdit() {
      this.toggleEditable();
    },
    onSubmitEdit(event) {
      event.preventDefault();
      this.save();
      this.toggleEditable();
    },
    save() {
      const card = this.card;
      const newTitle = this.card.title.trim();
      this.$store.dispatch('cards/editCard', {
        ...card,
        title: newTitle,
      });
    },
    toggleAddTask() {
      this.addingTask = !this.addingTask;
    },
    deleteCard() {
      if (confirm('Are you sure you want to delete this card?')) {
        this.$store.dispatch('cards/removeCard', this.card);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background: white;
  padding: $space_xxs $space_xs;
  margin-bottom: $space_xxs;
  border-radius: 4px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;

  &:hover {
    .bttn-action {
      visibility: visible;
    }
    .remove-highlight {
      visibility: visible;
    }
  }

  &.hidden {
    .text {
      filter: blur(5px);
    }
  }

  &.isActive {
    outline: dashed 2px $color_teal;
  }

  &.isInQueue {
    outline: dashed 2px rgba(0, 0, 0, 0.2);
  }
}
.card.ghost {
  opacity: 0.5;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0);
}
.footer {
  margin-top: $space_xxs;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
.text {
  margin-top: 2px;
  white-space: pre-line;
}
.input {
  textarea {
    font-family: $font_family;
    width: 100%;
    font-size: 14px;
    color: $color_text;
    padding: 4px 6px;
    border-radius: 3px;
    border: solid 1px #ddd;

    &:focus,
    &:active {
      border-color: #bbb;
      outline: none;
    }
  }
}
.actions {
  margin-top: 1rem;
  padding-top: 0.5rem;
  display: flex;
  background: #f2f5f7;
  padding: 0.5rem 1rem;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -8px;
  border-radius: 0 0 4px 4px;

  .icon {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 2px;
    margin-right: 0.5rem;
  }

  textarea {
    width: 100%;
    padding: 0px;
    border: none;
    font-size: 13px;
    resize: none;
    background: transparent;

    &:active,
    &:focus {
      outline: none;
    }
  }
}
.menu-btn {
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f6f6f6;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
.remove-highlight {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  position: absolute;
  top: -4px;
  right: -4px;
  background: $color_teal;
  color: white;
  cursor: pointer;
  display: grid;
  place-items: center;
  visibility: hidden;

  .icon {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background: darken($color_teal, 10%);
  }
}
</style>

<style lang="scss">
.bttn {
  background: #f2f5f7;
  color: #93969c;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;

  &.compact {
    padding: 0 3px;
  }

  .icon {
    width: 14px;
    height: 14px;
  }

  .vote-count {
    margin-left: 3px;
    margin-right: 2px;
    color: $color_text_grey;
    font-weight: $font_weight_bold;
  }

  svg {
    fill: #6c7377;
  }

  &.voted,
  &:hover {
    background: $color_primary;
    color: $color_white;

    .vote-count {
      color: $color_white;
    }

    svg {
      fill: $color_white;
    }
  }
}
.bttn + .bttn {
  margin-left: $space_xxs;
}
.bttn-action {
  visibility: hidden;
}
.bttn-plus1 {
  .icon {
    position: relative;
    top: -1px;
  }
}
.bttn-vote {
  &.voted,
  &:hover {
    background: $color_orange;
    fill: white;
  }
}
.edit-options {
  margin-left: auto;
}
.bttn-dark {
  background: $color_primary;
  color: white;
}
.text a {
  text-decoration: underline;
  color: inherit;

  &:hover {
    background-color: #eceef2;
  }
}
</style>
