<template>
  <div class="settings">
    <h4>Board settings</h4>
    <div class="checkboxes">
      <div class="check" @click="applySetting('plus1sAllowed')">
        <span class="checkbox" :class="{ checked: settings.plus1sAllowed }">
          <icon-check />
        </span>
        Allow +1s on cards
      </div>
      <div class="check" @click="applySetting('actionsAllowed')">
        <span class="checkbox" :class="{ checked: settings.actionsAllowed }">
          <icon-check />
        </span>
        Allow Actions at any time
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from '@/components/icons/Check';

export default {
  name: 'BoardSettings',
  props: ['settings'],
  components: { IconCheck },
  methods: {
    applySetting(setting) {
      if (this.settings[setting] === undefined) {
        this.settings[setting] = false;
      }
      this.settings[setting] = !this.settings[setting];
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  margin-bottom: $space_sm;

  h4 {
    margin-bottom: $space_xxs;
  }
}
.checkboxes {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
}
.check {
  display: flex;
  cursor: pointer;
}
.checkbox {
  width: 16px;
  height: 16px;
  margin-right: $space_xxs;
}
</style>
