// Popover Plugin
//
import Popover from './Popover.vue';
import { events } from './bus';

const optionsDefaults = {
  position: 'bottom',
};

const prepareBinding = ({ arg = '', modifiers = {}, value = {} }, options) => {
  let mods = Object.keys(modifiers);
  let name = typeof value === 'object' && value.name ? value.name : arg;
  let position = mods[0] || value.position || options.position;

  return { name, position, value };
};

const addClickEventListener = (el, options) => {
  const click = srcEvent => {
    events.$emit('showPopover', { ...options, el, srcEvent });

    let handler = srcEvent => {
      events.$emit('hidePopover', { ...options, el, srcEvent });
      document.removeEventListener('click', handler);
    };

    document.addEventListener('click', handler);
    srcEvent.stopPropagation();
  };

  el.addEventListener('click', click);

  el.$popoverRemoveClickHandlers = () => {
    el.removeEventListener('click', click);
  };
};

export default {
  install(Vue, opts) {
    const options = { ...optionsDefaults, ...opts };

    Vue.component('Popover', Popover);

    Vue.directive('popover', {
      bind(el, binding) {
        let params = prepareBinding(binding, options);
        addClickEventListener(el, params);
      },
      unbind(el) {
        el.$popoverRemoveClickHandlers();
      },
    });
  },
};
