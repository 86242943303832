<template>
  <div>Redirecting...</div>
</template>

<script>
export default {
  name: 'RedirectToBoard',
  props: ['teamId', 'board'],
  created() {
    // only redirect if we are on the right team page
    console.log('Redirecting...');
    if (this.board.teamId === this.teamId) {
      this.$router.replace({ name: 'Board', params: { boardId: this.board.id, teamId: this.teamId } });
      console.log('Redirected!');
    }
  },
};
</script>
