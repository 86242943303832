<template>
  <div
    class="team-card"
    @click="itemClicked(team.id)"
    :class="{
      isArchived: team.archived,
    }"
  >
    <span v-if="team.settings && team.settings.anonUsersAllowed" class="team-privacy alt">Public board</span>
    <span v-if="!team.settings || !team.settings.anonUsersAllowed" class="team-privacy">Private board</span>
    <div class="team-block">
      <span v-if="canEdit" class="team-menu-btn" v-popover="{ name: popoverName }">
        <IconEllipsis />
      </span>
      <span class="team-title">{{ team.name }}</span>
      <span class="team-cta">Go to board <IconChevronDown /></span>
      <popover :name="popoverName">
        <ul class="menu">
          <li class="menu-item" @click="openEditTeam">Edit</li>
          <li class="menu-item" @click="archiveTeam">Archive</li>
        </ul>
      </popover>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Popover from '@/plugins/popover';
import IconChevronDown from '@/components/icons/ChevronDown';
import IconEllipsis from '@/components/icons/Ellipsis';

Vue.use(Popover);

export default {
  name: 'TeamCard',
  props: ['team'],
  components: {
    IconChevronDown,
    IconEllipsis,
  },
  computed: {
    popoverName() {
      return `popover-${this.team.id}`;
    },
    canEdit() {
      return true;
    },
  },
  methods: {
    itemClicked(teamId) {
      this.$store.dispatch('onTeamChange');
      this.$nextTick(() => {
        this.$router.push({ name: 'Boards', params: { teamId } });
      });
    },
    openEditTeam(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('editTeam', this.team.id);
    },
    archiveTeam(e) {
      e.preventDefault();
      e.stopPropagation();
      if (confirm('Archiving teams can only be undone by an admin, are you sure you want to continue?')) {
        this.$store.dispatch('teams/edit', {
          ...this.team,
          archived: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.team-card {
  position: relative;
  margin-bottom: $space_sm;
  background: white;
  font-size: 16px;
  font-weight: $font_weight_bold;
  border-radius: 4px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  &.isArchived {
    display: none;
  }

  &:hover {
    top: -1px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
  }
}
.team-block {
  padding: $space_xs 20px;
  position: relative;
}
.team-title {
  line-height: 1.3;
  display: inline-block;
}
.team-cta {
  font-size: 11px;
  display: block;
  color: $color_text_grey;
  margin-top: $space_xs;
  font-weight: $font_weight_regular;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}
.team-privacy {
  background: $color_greyblue_100;
  color: $color_text_grey;
  font-size: 11px;
  display: block;
  line-height: 13px;
  padding: 6px 20px;
  border-radius: 4px 4px 0 0;
  text-transform: uppercase;

  &.alt {
    color: $color_primary;
    background: $color_secondary;
  }
}
.team-menu-btn {
  cursor: pointer;
  position: absolute;
  top: -24px;
  right: 8px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      fill: $color_primary;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
</style>

<style lang="scss">
.team-menu-btn {
  svg {
    fill: rgba(0, 0, 0, 0.3);
  }
  &:hover {
    svg {
      fill: $color_primary;
    }
  }
}
.team-cta {
  .icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    transform: rotate(270deg);
    position: relative;
    right: -4px;
  }
  svg {
    fill: $color_text_grey;
  }
}
</style>
