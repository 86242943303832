<template>
  <div class="plans" :class="{ plansLocked: locked }">
    <div
      class="plan"
      v-for="plan in plans"
      :key="plan.key"
      :class="{ selected: selectedPlan == plan.id }"
      @click="selectPlan(plan)"
    >
      <div class="plan-label" :class="[plan.key]">{{ plan.name }}</div>
      <Check />
      <ul class="plan-details">
        <li v-for="(item, index) in plan.details" :key="`str-${index}`"><span v-html="item"></span></li>
      </ul>
      <div class="price" v-if="plan.prices">
        {{ formatPrice(plan.prices[0]) }}<em>/{{ plan.prices[0].interval }}</em>
      </div>
      <div class="price" v-if="plan.id == 'free'">Free</div>
    </div>
  </div>
</template>

<script>
import subscriptions from '@/db/subscriptions';
import Check from '@/components/atoms/Check';

export default {
  name: 'ChoosePlan',
  props: ['locked', 'selectedPlan'],
  components: {
    Check,
  },
  data: () => ({
    plans: [{ key: 'free' }, { key: 'team' }, { key: 'pro' }],
  }),
  created() {
    this.fetchPlans();
  },
  computed: {},
  methods: {
    async fetchPlans() {
      this.plans = await subscriptions.fetchPlans();
    },
    selectPlan(plan) {
      const planId = plan.id;
      const priceId = plan.prices ? plan.prices[0].id : null;
      const priceOptions = plan.prices || null;
      this.$emit('selectPlan', { planId, priceId, priceOptions });
    },
    formatPrice(priceData) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: priceData.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format((priceData.unit_amount / 100).toFixed(2));
    },
  },
};
</script>

<style lang="scss" scoped>
.plans {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}
@media (min-width: 768px) {
  .plans {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 240px;
  }
}
.plan {
  position: relative;
  border: solid 2px $color_input_border;
  border-radius: 6px;
  padding: $space_xs;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  &:hover {
    border: solid 2px $color_blue;
    cursor: pointer;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .check {
    display: none;
  }

  &.selected {
    border: solid 2px $color_blue !important;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1) !important;

    .check {
      display: grid;
      position: absolute;
      top: $space_xs;
      right: $space_xs;
    }
  }
}
.plan-label {
  font-size: 16px;
  font-weight: $font_weight_bold;
  background: $color_secondary;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  border-radius: 5px;

  &.free {
    background: $color_tier_free;
  }

  &.team {
    background: $color_tier_teams;
    color: white;
  }

  &.pro {
    background: $color_tier_pro;
    color: white;
  }
}
.plan-details {
  list-style: none;
  padding: 0;
  font-size: 14px;
  margin-bottom: $space_sm;

  li + li {
    margin-top: $space_xxs;
  }
}
.price {
  margin-top: auto;
  font-weight: $font_weight_bold;

  em {
    font-weight: $font_weight_regular;
    font-style: normal;
    opacity: 0.7;
  }
}
/* Locked Plans */
.plansLocked {
  .plan {
    opacity: 0.6;

    &:hover {
      border: solid 2px $color_input_border;
      cursor: default;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .plan.selected {
    opacity: 1;
  }
}
</style>
