<template>
  <div class="avatars" v-if="users && users.length">
    <div class="avatar" v-for="user in users" :key="user.userId">
      <span class="avatar-img" v-if="hasAvatar(user)">
        <img :src="user.avatar" :title="user.name" />
      </span>
      <span
        v-if="!hasAvatar(user)"
        class="avatar-icon"
        :style="{ background: getAvatarColor(user.name) }"
        :title="user.name"
      >
        {{ firstChar(user.name) }}
      </span>
      <span class="avatar-popout">{{ user.name }}</span>
    </div>
  </div>
</template>

<script>
import { generateId, generateAvatarColor } from '@/utils';

export default {
  name: 'Avatar',
  props: ['user'],
  computed: {
    users() {
      let users = [];
      if (this.user) {
        users = Array.isArray(this.user) ? this.user : [this.user];
        // handle missing user ID before returning
        users = users.map(user => {
          user.userId = user.userId || generateId();
          return user;
        });
      }
      return users;
    },
  },
  methods: {
    getAvatarColor(userName) {
      return generateAvatarColor(userName);
    },
    hasAvatar(user) {
      return user.avatar;
    },
    firstChar(str) {
      if (str) {
        return str.charAt(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatars {
  display: flex;
  margin-right: $space_xxs;
}
.avatar {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;

  &:hover {
    .avatar-popout {
      display: block;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
    border: solid 1px white;
  }
}
.avatar + .avatar {
  margin-left: -10px;
}
.avatar-img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}
.avatar-icon {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 11px;
  color: white;
  background: $color_primary;
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
  line-height: 20px;
  cursor: default;
}
.avatar-popout {
  display: none;
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  font-size: 11px;
  color: white;
  background: $color_primary;
  padding: 0 6px;
  z-index: 15;
  white-space: nowrap;
}
</style>
