<template>
  <div class="task">
    <div class="checkbox" @click="toggleDone()" :class="{ checked: task.done }">
      <icon-check />
    </div>
    <div class="content">
      <div class="title" v-if="!isEditing">{{ task.description }}</div>
      <div class="editor" v-if="isEditing">
        <resizable-textarea>
          <textarea
            class="input"
            placeholder="Write something..."
            data-gramm_editor="false"
            v-model="task.description"
            @keydown.enter="onSubmitEdit"
            @keyup.esc="onCancelEdit"
            rows="1"
            ref="titleTextarea"
          >
          </textarea>
        </resizable-textarea>
        <div class="btns">
          <div class="btn" @click="onSubmitEdit">Save</div>
        </div>
      </div>
    </div>
    <div class="meta" v-if="!isEditing">
      <div class="options">
        <span class="menu-btn" v-popover="{ name: popoverName }">
          <IconEllipsis color="#a7aab0" />
        </span>
      </div>
    </div>
    <popover :name="popoverName">
      <ul class="menu">
        <li class="menu-item" @click="toggleEdit">Edit</li>
        <li class="menu-item" @click="onDelete">Delete</li>
      </ul>
    </popover>
  </div>
</template>

<script>
import ResizableTextarea from '@/components/ResizableTextarea';
import { format } from 'date-fns';
import IconCheck from '@/components/icons/Check';
import IconEllipsis from '@/components/icons/Ellipsis';
import Vue from 'vue';
import Popover from '@/plugins/popover';

Vue.use(Popover);

export default {
  name: 'Task',
  props: ['task'],
  components: {
    IconCheck,
    IconEllipsis,
    ResizableTextarea,
  },
  data: () => ({
    isEditing: false,
  }),
  computed: {
    popoverName() {
      return `popover-${this.task.id}`;
    },
  },
  methods: {
    formatTaskDate(date) {
      return format(new Date(date), 'dd MMM yyyy');
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
      if (this.isEditing) {
        this.$nextTick(() => {
          this.$refs.titleTextarea.focus();
        });
      }
    },
    onCancelEdit() {
      this.toggleEdit();
    },
    onSubmitEdit(event) {
      event.preventDefault();
      this.saveChanges();
      this.toggleEdit();
    },
    onDelete() {
      if (confirm('Are you sure you want to delete this task?')) {
        this.$store.dispatch('tasks/remove', this.task);
      }
    },
    toggleDone() {
      this.task.done = !this.task.done;
      this.saveChanges();
    },
    saveChanges() {
      const task = this.task;
      this.$store.dispatch('tasks/edit', {
        ...task,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.task {
  background: #f2f5f7;
  border-top: solid 1px #e1e4e6;
  padding: 10px $space_xs;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}
.meta {
  display: flex;
}
.content {
  flex: 1;
}
.title {
  line-height: 24px;
}
.editor {
  margin: -3px -2px;
  line-height: 24px;
  flex: 1;
  display: flex;
  align-items: flex-start;

  textarea {
    font-family: $font_family;
    width: 100%;
    font-size: 16px;
    color: $color_text;
    padding: 4px 6px;
    border-radius: 3px;
    border: solid 1px #ddd;
    display: block;

    &:focus,
    &:active {
      border-color: #bbb;
      outline: none;
    }
  }

  .btns {
    margin-left: 8px;
    display: flex;
  }
}
.btn {
  padding: 0 12px;
  line-height: 30px;
  background: $color_primary;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: darken($color_primary, 5%);
  }
}
.btn-light {
  background: #eee;
  color: $color_text;

  &:hover {
    background: #e7e7e7;
  }
}
.btn + .btn {
  margin-left: 4px;
}
.date {
  margin-left: auto;
  font-size: 14px;
  line-height: 24px;
  color: #bbb;
  margin-left: $space_xs;
}
.menu-btn {
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f6f6f6;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
</style>
