<template>
  <pattern-background>
    <div class="box">
      <Logo variant="dark-logo" />
      <p class="message" v-if="email">We have sent an email to {{ email }}</p>
      <p class="message" v-if="!email">We've sent you an email!</p>
      <p>Please check your email and follow the provided instructions to continue signing in.</p>
    </div>
  </pattern-background>
</template>

<script>
import Logo from '@/components/Logo';
import PatternBackground from '@/components/PatternBackground';

export default {
  name: 'SignInComplete',
  components: {
    Logo,
    PatternBackground,
  },
  data: () => ({
    email: '',
  }),
  created() {
    this.email = window.localStorage.getItem('retro:emailForSignIn');
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: -2rem;
  padding: $space_md 0;
  max-width: 400px;
  width: 100%;
  font-weight: $font_weight_regular;

  .message {
    font-size: 20px;
    line-height: 1.4;
    margin: $space_md 0;
    font-weight: $font_weight_bold;
  }
}
</style>
