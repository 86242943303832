<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.4443 12.3934C3.28685 12.0254 3.36396 11.599 3.64032 11.3095L11.2767 3.30952C11.4654 3.11184 11.7268 3 12 3C12.2733 3 12.5347 3.11184 12.7234 3.30952L20.3598 11.3095C20.6361 11.599 20.7132 12.0254 20.5558 12.3934C20.3983 12.7614 20.0366 13 19.6364 13H16.5V20C16.5 20.5523 16.0523 21 15.5 21H8.50002C7.94774 21 7.50002 20.5523 7.50002 20V13H4.36367C3.96344 13 3.60175 12.7614 3.4443 12.3934Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'ChevronUp',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
