<template>
  <div>
    <HeroBlock />
    <HomeContentBlock />
  </div>
</template>

<script>
import HeroBlock from '@/components/HeroBlock';
import HomeContentBlock from '@/components/HomeContentBlock';

export default {
  name: 'Home',
  components: {
    HeroBlock,
    HomeContentBlock,
  },
};
</script>
