<template>
  <div>
    <div class="block blue">
      <div class="content">
        <div class="center-block">
          <h2>Online Retros for Teams</h2>
          <p>
            Project Retrospectives are designed to help you identify how to improve teamwork by reflecting on what
            worked, what didn’t, and why. Retros for Teams enables these reflections to happen online and in an fun and
            collaborative way.
          </p>
          <img class="screenshot" src="product.png" alt="Product Screenshot" />
        </div>
      </div>
    </div>
    <div class="block white">
      <div class="content">
        <div class="center-block">
          <h2>Key Features</h2>
          <p>
            The features we offer that make us stand out from the crowd.
          </p>
        </div>
        <div class="features">
          <div class="feature">
            <h3>🗃 Retro Templates</h3>
            <p>Tried and tested Retro Templates that you can use to speed up your Retros and get results quicker.</p>
          </div>
          <div class="feature">
            <h3>📝 Voting</h3>
            <p>Vote on cards that you think need further discussion or to be raised and actioned further.</p>
          </div>
          <div class="feature">
            <h3>✍️ Retro Actions</h3>
            <p>Keep track of Actions that your team decided upon and review them over time.</p>
          </div>
          <div class="feature">
            <h3>👋 Guest access</h3>
            <p>You can allow external Guests to join your boards and collaborate with your team on Retros.</p>
          </div>
          <div class="feature">
            <h3>🙈 Card masking</h3>
            <p>Cards are blurred out initially to encourage unique thoughts and perspectives amongst your team.</p>
          </div>
          <div class="feature">
            <h3>👍 Plus 1s</h3>
            <p>You can +1 cards that you agree with during the dicussion to add more importance to the topic.</p>
          </div>
          <div class="feature">
            <h3>↩️ Drag and Drop</h3>
            <p>Easily move cards around to group them by common themes when discussing them.</p>
          </div>
          <div class="feature">
            <h3>👀 Card Focus</h3>
            <p>
              Double click cards to highlight them so that everyone participating in the retro can easily follow along.
            </p>
          </div>
          <div class="feature">
            <h3>😍 Simple interface</h3>
            <p>An easy to use interface and experience that will delight everyone who uses it.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="content">
        <div class="center-block">
          <h2>Plans &amp; prices</h2>
          <p>Choose the plan thats right for your Team. Remember, you can upgrade at any time!</p>
        </div>
        <div class="plans">
          <div class="plan">
            <h3 class="plan-title free">Free</h3>
            <p class="plan-description">To familiarize yourself with Retros</p>
            <ul class="plan-list">
              <li>1 Team</li>
              <li>Unlimited Retros</li>
              <li>Public Boards only</li>
            </ul>
            <div class="plan-price">$0 <em>/ month</em></div>
          </div>
          <div class="plan">
            <h3 class="plan-title team">Team</h3>
            <p class="plan-description">For a small business that has only a few Teams</p>
            <ul class="plan-list">
              <li>5 Teams</li>
              <li>Unlimited Retros</li>
              <li>Public &amp; Private Boards</li>
              <li>Guest access to Retros</li>
            </ul>
            <div class="plan-price">$15 <em>USD / month</em></div>
          </div>
          <div class="plan">
            <h3 class="plan-title pro">Pro</h3>
            <p class="plan-description">For a larger business that needs to run lots of different Retros</p>
            <ul class="plan-list">
              <li>25 Teams</li>
              <li>Unlimited Retros</li>
              <li>Unlimited Team members</li>
              <li>Public &amp; Private Boards</li>
              <li>Guest access to Retros</li>
            </ul>
            <div class="plan-price">$55 <em>USD /month</em></div>
          </div>
        </div>
        <div class="center-block">
          <Button size="cta" @click="goToLink('/create-account')">Sign up and get started 🎉</Button>
          <p class="small">
            No credit card required! <br />All you have to do is
            <router-link to="/create-account">create an account</router-link> and follow the instructions.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'HomeContentBlock',
  components: { Button },
  methods: {
    goToLink(path) {
      this.$nextTick(() => {
        this.$router.push({ path });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: flex;

  &.blue {
    background: $color_greyblue_800;
    color: white;
  }

  &.white {
    background: white;
  }
}
.content {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
}
@media (min-width: 768px) {
  .content {
    padding: 4rem 1.5rem;
  }
}
.center-block {
  margin: 2rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    font-size: 34px;
    margin: 0;
  }

  p {
    max-width: 700px;
    margin: 1.5rem auto;
  }

  p.small {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .center-block {
    margin: 4rem 0;
  }
}

.plans {
  margin-top: 4rem;
}
@media (min-width: 768px) {
  .plans {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-column-gap: 1.5rem;
  }
}
.plan {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 2rem;
  border: solid 2px #dee3ec;
}
@media (min-width: 768px) {
  .plan {
    margin: 0;
  }
}
.plan-title {
  font-size: 28px;
  margin: 0 0 1.5rem 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 3px;
    background: #eee;
  }

  &.free:after {
    background: $color_tier_free;
  }

  &.team:after {
    background: $color_tier_teams;
  }

  &.pro:after {
    background: $color_tier_pro;
  }
}
.plan-price {
  margin-top: auto;
  font-size: 22px;
  font-weight: $font_weight_bold;

  em {
    font-size: 14px;
    font-style: normal;
    color: $color_text_grey;
    font-weight: $font_weight_regular;
  }
}
.plan-description {
  margin: 0;
  font-size: 14px;
  color: $color_text_grey;
}
@media (min-width: 768px) {
  .plan-description {
    min-height: 40px;
  }
}
.plan-list {
  padding: 0 0 0 1.25rem;
  margin: 1rem 0;
  font-size: 14px;
}
.features {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .features {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.feature {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 1rem 2rem;
  border: solid 2px #dee3ec;

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }
}
.screenshot {
  width: 100%;
  max-width: 920px;
  margin: 2rem auto;
}
</style>
