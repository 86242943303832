<template>
  <div class="container">
    <Logo variant="dark-logo" />
    <p class="message">
      👋 Welcome to {{ team.name ? `the ${team.name} Retro` : 'Retro' }}, to participate as a Guest please enter a name
      and join us!
    </p>
    <div class="form-field">
      <TextInput
        placeholder="Please enter a name..."
        :value="guestName"
        @handleChange="handleEmailInputChange($event)"
      />
      <Button @click="joinAsGuest()">Join</Button>
    </div>
    <div class="note">Already a member of this team? <span class="link" @click="showLogin">Log in in now</span></div>
  </div>
</template>

<script>
import auth from '@/auth';
import Button from '@/components/Button';
import Logo from '@/components/Logo';
import TextInput from '@/components/atoms/TextInput';

export default {
  name: 'GuestAccessForm',
  data: () => ({
    guestName: '',
    uid: null,
  }),
  props: ['team'],
  components: {
    Button,
    Logo,
    TextInput,
  },
  created() {
    const existingUser = JSON.parse(localStorage.getItem('retro:user'));
    if (existingUser) {
      this.guestName = existingUser.displayName;
    }
  },
  methods: {
    joinAsGuest() {
      if (!this.guestName) {
        alert('A name is required!');
        return;
      }
      auth.signInAnonymously({
        displayName: this.guestName,
      });
    },
    showLogin() {
      this.$emit('showLogin');
    },
    handleEmailInputChange(value) {
      this.guestName = value;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  margin-top: -2rem;
  padding: $space_md 0;
  max-width: 410px;
  width: 100%;
  font-weight: $font_weight_regular;

  .message {
    font-size: 20px;
    line-height: 1.4;
    margin: $space_md 0;
  }
}
.form-field {
  position: relative;

  .button {
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 40px;
  }
}
.note {
  font-size: 0.875rem;

  .link {
    text-decoration: underline;
    color: inherit;
    cursor: pointer;
  }
}
</style>
