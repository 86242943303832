import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/auth';
import store from '@/store';

import Actions from '@/views/Actions';
import Board from '@/views/Board';
import Boards from '@/views/Boards';
import Retros from '@/views/Retros';
import Home from '@/views/Home';
import Tasks from '@/views/Tasks';
import OrganisationNew from '@/views/OrganisationNew';
import NotFound from '@/views/NotFound';
import Invite from '@/views/Invite';
import Privacy from '@/views/Privacy';
import TermsConditions from '@/views/TermsConditions';
import Settings from '@/views/Settings';
import SignIn from '@/views/SignIn';
import CreateAccount from '@/views/CreateAccount';
import SignInComplete from '@/views/SignInComplete';
import SignInPending from '@/views/SignInPending';
import ChangePlan from '@/views/ChangePlan';

import { boardAccessAllowed, isOrgOwner } from '@/utils/permissions';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {},
    },
    {
      path: '/signin',
      name: 'SignIn',
      component: SignIn,
    },
    {
      path: '/create-account',
      name: 'CreateAccount',
      component: CreateAccount,
    },
    {
      path: '/privacy-policy',
      name: 'Privacy',
      component: Privacy,
    },
    {
      path: '/terms-and-conditions',
      name: 'TermsConditions',
      component: TermsConditions,
    },
    {
      path: '/signin/complete',
      name: 'SignInComplete',
      component: SignInComplete,
    },
    {
      path: '/signin/pending',
      name: 'SignInPending',
      component: SignInPending,
    },
    {
      path: '/404',
      name: 'NotFound',
      component: NotFound,
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        requiresAuth: true,
        ownerOnly: true,
      },
    },
    {
      path: '/settings/plan',
      name: 'ChangePlan',
      component: ChangePlan,
      meta: {
        requiresAuth: true,
        ownerOnly: true,
      },
    },
    {
      path: '/organisation/new',
      name: 'OrganisationNew',
      component: OrganisationNew,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/invite/:inviteCode',
      name: 'Invite',
      component: Invite,
    },
    {
      path: '/boards',
      name: 'Retros',
      component: Retros,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/boards/:teamId',
      name: 'Boards',
      component: Boards,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/boards/:teamId/actions',
      name: 'Actions',
      component: Actions,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/boards/:teamId/tasks',
      name: 'Tasks',
      component: Tasks,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/boards/:teamId/:boardId',
      name: 'Board',
      component: Board,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    { path: '*', redirect: '/404' },
  ],
});

const handleUnauthorizedTeam = () => {
  // unset current team
  store.dispatch('teams/resetCurrentTeam');
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const ownerOnly = to.matched.some(record => record.meta.ownerOnly);
  const isTeamPage = !!to.params.teamId;
  let team = null;

  if (isTeamPage) {
    // Check Team permissions and settings
    const teamId = to.params.teamId;
    const teamExists = store.getters['teams/teamById'](teamId);
    team = teamExists || (await store.dispatch('teams/fetchTeamById', teamId));
    store.dispatch('teams/setCurrentTeam', team);
  } else {
    store.dispatch('teams/resetCurrentTeam');
  }

  if ((to.name === 'SignIn' || to.name === 'Home') && !auth.getRedirectUrl()) {
    // set a default redirect url if one doesnt exist and user is on the signin or home page
    auth.setRedirectUrl('/boards');
  }

  if (requiresAuth) {
    // set redirect url
    auth.setRedirectUrl(to.path);

    // wait for the user to be logged in
    if (await auth.getCurrentUser()) {
      // Checks if the route is an owner only route
      if (ownerOnly && !isOrgOwner()) {
        // redirect if user is trying to access an owner only page and doesn't have permissions
        next('/boards');
      }
      // Check if the user can access a board
      if (isTeamPage && !boardAccessAllowed(team)) {
        handleUnauthorizedTeam();
        // redirect if user is trying to access a team they're not part of
        next('/boards');
      } else {
        // else continue
        next();
      }
    } else {
      // User is not logged in
      // Check if the board access is allowed for anon users;
      if (isTeamPage && boardAccessAllowed(team)) {
        next();
      } else {
        handleUnauthorizedTeam();
        next('/signin');
      }
    }
  } else {
    next();
  }
});

export default router;
