<template>
  <div class="popup" v-if="cardsToMerge.length">
    <div class="text">
      <span class="circle">{{ cardsToMerge.length }}</span> cards selected
    </div>
    <div class="btns">
      <div class="btn" @click="onMergeCards">Merge cards</div>
      <div class="btn" @click="cancelCardMerge">Cancel</div>
    </div>
  </div>
</template>

<script>
import { mergeVotes, mergeUsers } from '@/utils';

export default {
  name: 'CardMergePopup',
  computed: {
    cardsToMerge() {
      return this.$store.getters['mergeQueue/queue'];
    },
  },
  methods: {
    onMergeCards() {
      if (confirm('Merging cards is permanent and cannot be undone. Are you sure you want to continue?')) {
        this.performCardMerge();
      } else {
        this.cancelCardMerge();
      }
    },
    performCardMerge() {
      let masterCard = null;
      const cardsToDelete = [];

      // fetch cards
      for (const cardId of this.cardsToMerge) {
        const card = this.$store.getters['cards/cardById'](cardId);

        if (!masterCard) {
          masterCard = JSON.parse(JSON.stringify(card));
          masterCard.user = mergeUsers(masterCard.user);
          masterCard.plus1s = mergeVotes(masterCard.plus1s);
          masterCard.votes = mergeVotes(masterCard.votes);
        } else {
          masterCard.title = `${masterCard.title}\n-----\n${card.title}`;
          masterCard.user = mergeUsers(masterCard.user, card.user);
          masterCard.plus1s = mergeVotes(masterCard.plus1s, card.plus1s);
          masterCard.votes = mergeVotes(masterCard.votes, card.votes);
          cardsToDelete.push(card);
        }
      }

      // merge to master card
      this.$store.dispatch('cards/editCard', {
        ...masterCard,
      });

      // delete remaining cards
      for (const card of cardsToDelete) {
        this.$store.dispatch('cards/removeCard', card);
      }

      this.cancelCardMerge();
    },
    cancelCardMerge() {
      this.$store.dispatch('mergeQueue/reset');
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 400px;
  width: 100%;
  background: $color_primary;
  color: white;
  padding: $space_xxs;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  font-size: 14px;
}
.circle {
  background: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
  text-align: center;
  display: inline-block;
  border-radius: 24px;
  margin-right: 4px;
}
.btns {
  display: flex;
}
.btn {
  background: white;
  color: $color_text;
  margin-left: $space_xxs;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 $space_xxs;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}
</style>
