<template>
  <div class="column">
    <div v-if="!isLocked" class="shuffle-btn-container">
      <div
        class="shuffle-btn"
        @click="shuffleCards"
        content="Shuffle cards"
        v-tippy="{ placement: 'left', arrow: true, size: 'small' }"
      >
        <icon-shuffle />
      </div>
    </div>
    <slot v-if="!cards.length"></slot>
    <new-card v-if="!isLocked" :cards="cards" :columnId="columnId" :boardId="boardId" :teamId="teamId" />
    <draggable :list="cards" @change="onChange" group="column" ghost-class="ghost" class="dragArea">
      <card v-for="card in cards" :key="card.id" :card="card" :settings="settings" :boardProgress="boardProgress" />
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Card from '@/components/Card';
import NewCard from '@/components/NewCard';
import IconShuffle from '@/components/icons/Shuffle';

export default {
  name: 'Column',
  props: ['columnId', 'teamId', 'boardId', 'settings', 'boardProgress', 'isLocked'],
  components: {
    Card,
    NewCard,
    draggable,
    IconShuffle,
  },
  data: () => ({
    justDropped: null,
  }),
  computed: {
    cards: {
      get() {
        return this.getCards(this.columnId);
      },
      set() {},
    },
  },
  methods: {
    getCards(columnId) {
      const filteredCards = this.$store.getters['cards/cards'].filter(x => x.columnId === columnId);
      return filteredCards.sort((a, b) => a.position - b.position);
    },
    onChange($event) {
      if ($event.moved) {
        // card was moved in the column
        const card = $event.moved.element;
        const newIndex = $event.moved.newIndex;
        this.justDropped = $event.moved.newIndex;
        const newPosition = this.calcNewPosition(this.cards, newIndex);
        // update position
        this.$store.dispatch('cards/editCard', {
          ...card,
          position: newPosition,
        });
      }
      if ($event.added) {
        // card was added to the column
        const card = $event.added.element;
        const newIndex = $event.added.newIndex;
        const newPosition = this.calcNewPosition(this.cards, newIndex);
        // update position and column ID
        this.$store.dispatch('cards/editCard', {
          ...card,
          columnId: this.columnId,
          position: newPosition,
        });
      }
    },
    calcNewPosition(cards, index) {
      const increment = 100;
      let newPosition = increment; // used if list is empty
      const prevCard = cards[index == 0 ? false : index - 1];
      const nextCard = cards[index == cards.length - 1 ? false : index + 1];

      if (prevCard && nextCard) {
        // middle of list
        newPosition = (nextCard.position - prevCard.position) / 2 + prevCard.position;
      } else if (!prevCard && nextCard) {
        // start of list
        newPosition = nextCard.position / 2;
      } else if (prevCard && !nextCard) {
        // end of list
        newPosition = prevCard.position + increment;
      }

      return newPosition;
    },
    shuffleCards() {
      const cards = this.cards;
      // only allow shuffling if there is more than 1 card
      if (cards.length > 1) {
        const shuffledCards = cards.sort(() => Math.random() - 0.5);

        // Loop through cards and save them
        for (let i = 0; i < shuffledCards.length; i++) {
          const card = shuffledCards[i];
          const newPosition = i * 100;
          this.$store.dispatch('cards/editCard', {
            ...card,
            position: newPosition,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dragArea {
  flex: 1;

  & > :last-child {
    margin-bottom: 0;
  }
}
.shuffle-btn-container {
  position: relative;
}
</style>

<style lang="scss">
.shuffle-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute !important;
  top: -32px;
  right: 0;

  .icon {
    width: 12px;
    height: auto;
  }

  svg {
    fill: $color_icon_grey;
  }

  &:hover {
    svg {
      fill: $color_primary;
    }
  }
}
</style>
