<template>
  <div class="prompt" v-if="!isHidden">
    <h3 class="prompt-title">Share this Retro</h3>
    <div class="btn-close" @click="hidePrompt"><IconClose color="#323d47" /></div>
    <div class="prompt-field">
      <label>Link to share with your team</label>
      <TextInput :value="linkToShare" id="input-prompt" />
      <p class="note" v-if="allowsAnonUsers">
        🚨 <strong>This Retro is Public</strong>, anyone with this link will be able to access and contribute to it.
      </p>
      <p class="note" v-if="!allowsAnonUsers">
        🔒 <strong>This Retro is Private</strong>, only logged in team members will be able to access and contribute to it.
      </p>
      <div class="buttons">
        <Button @click="onClickCopy">{{ copied ? 'Copied!' : 'Copy link' }}</Button>
        <Button type="secondary" @click="hidePrompt">Hide this</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
import IconClose from '@/components/icons/Close';
import TextInput from '@/components/atoms/TextInput';
import { teamAllowsAnonUsers } from '@/utils/permissions';

export default {
  name: 'SharePrompt',
  components: {
    Button,
    IconClose,
    TextInput,
  },
  props: ['boardId', 'teamId'],
  data: () => ({
    isHidden: false,
    copied: false,
  }),
  computed: {
    currentTeam() {
      return this.$store.getters['teams/currentTeam'];
    },
    allowsAnonUsers() {
      return teamAllowsAnonUsers(this.currentTeam);
    },
    linkToShare() {
      return `${window.location.origin}/boards/${this.teamId}/${this.boardId}`;
    },
    isPublic() {
      // .settings.anonUsersAllowed
      return true;
    },
  },
  methods: {
    onClickCopy() {
      const inputToCopy = document.querySelector('#input-prompt');
      inputToCopy.select();

      // try to copy
      try {
        const success = document.execCommand('copy');
        this.copied = success ? true : false;
      } catch (err) {
        alert('Oops, unable to copy');
      }
      // remove selection
      window.getSelection().removeAllRanges();
    },
    hidePrompt() {
      this.isHidden = true;
    },
  },
};
</script>

<style scoped lang="scss">
.prompt {
  background: white;
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 580px;
  width: 100%;
  z-index: 2;
}
.prompt-title {
  color: $color_text;
  font-weight: $font_weight_bold;
  font-size: 18px;
  margin: 0 0 1.5rem 0;
}
.btn-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 20px;

  &:hover {
    background: $color_body;
  }
}
.note {
  display: block;
  color: $color_text_grey;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
}
.prompt-field {
  label {
    font-size: 14px;
    color: $color_text;
  }
}
.buttons {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1rem;
  }
}
</style>

<style lang="scss">
.prompt-field {
  .field {
    margin: 0;
  }
}
</style>
