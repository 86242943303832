import Vue from 'vue';

import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import auth from '@/auth';
import VueTippy from 'vue-tippy';

Vue.use(VueTippy);

import '@/styles/global.scss';

// Init App
new Vue({
  router,
  store,
  beforeCreate() {
    auth.init(this);
  },
  render: h => h(App),
}).$mount('#app');
