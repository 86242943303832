<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 10.0815 16.9401 11.9154 15.3305 12.9915C15.2735 13.021 15.2199 13.0558 15.1702 13.095C14.2504 13.6686 13.1639 14 12 14C8.68629 14 6 11.3137 6 8ZM14.9861 15.4241C14.0635 15.7955 13.0556 16 12 16C10.9448 16 9.93735 15.7957 9.01499 15.4245L8.26447 21.0751L11.4855 19.1425C11.8022 18.9525 12.1978 18.9525 12.5145 19.1425L15.7359 21.0753L14.9861 15.4241ZM7.13952 14.3547C5.23084 12.8926 4 10.5901 4 8C4 3.58172 7.58172 0 12 0C16.4183 0 20 3.58172 20 8C20 10.5895 18.7697 12.8917 16.8617 14.3538L17.9913 22.8685C18.0418 23.2491 17.8699 23.625 17.549 23.8358C17.2281 24.0466 16.8148 24.055 16.4855 23.8575L12 21.1662L7.51452 23.8575C7.18525 24.0551 6.77193 24.0466 6.45099 23.8358C6.13004 23.625 5.95817 23.249 6.00873 22.8683L7.13952 14.3547Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'Award',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
