// handles invites and adding users to organisations
import db from '@/db/invites';

const invites = {
  context: null,
  currentInviteId: null,
  init(context) {
    this.context = context;
  },
  async checkForInvite(user) {
    console.log('Finding orgs to invite user to...');
    let orgsArr = [];

    // Check if url contains an invite code
    orgsArr = await this.checkForInviteCode(orgsArr, user);
    // Check if users email matches any organisations
    orgsArr = await this.findInviteByDomain(orgsArr, user);

    // remove any duplicates and return
    return [...new Set(orgsArr)];
  },
  async checkForInviteCode(orgsArr) {
    const inviteCode = this.getInviteCode();
    const validInvite = await invites.validateInvite(inviteCode);
    if (validInvite && validInvite.organisationId) {
      orgsArr = [...orgsArr, validInvite.organisationId];
      // and set the current org to that.
      this.context.$store.dispatch('organisations/setCurrentOrg', validInvite.organisationId);
    }
    this.clearInviteCode();
    return orgsArr;
  },
  async findInviteByDomain(orgsArr, user) {
    // First check if users email matches any organisations
    const domainMatchedOrg = await this.context.$store.dispatch('organisations/findByDomain', user.email);
    if (domainMatchedOrg && domainMatchedOrg.length) {
      orgsArr = [...orgsArr, ...domainMatchedOrg];
    }
    return orgsArr;
  },
  async validateInvite(code) {
    // validate a code
    if (code) {
      const isValid = await db.validateInvite(code);
      return isValid;
    }
    return false;
  },
  setInviteCode(inviteId) {
    this.currentInviteId = inviteId;
    window.localStorage.setItem('retro:inviteId', inviteId);
  },
  getInviteCode() {
    const inviteId = window.localStorage.getItem('retro:inviteId');
    return this.currentInviteId || inviteId || null;
  },
  clearInviteCode() {
    this.currentInviteId = null;
    window.localStorage.removeItem('retro:inviteId');
  },
};

export default invites;
