<template>
  <pattern-background>
    <div class="login-wrapper">
      <login showLogo="true" showNote="true" message="🙋‍♀️ Please log in to participate in this Retro with your team." />
    </div>
  </pattern-background>
</template>

<script>
import auth from '@/auth';
import Login from '@/components/Login';
import PatternBackground from '@/components/PatternBackground';

export default {
  name: 'SignIn',
  components: {
    Login,
    PatternBackground,
  },
  created() {
    this.checkIfLoggedIn();
  },
  methods: {
    async checkIfLoggedIn() {
      if (await auth.getCurrentUser()) {
        console.log('--- is logged in already, redirecting');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  padding: 0 1.5rem;
}
</style>
