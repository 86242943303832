<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6746 4.33795C22.1085 4.78856 22.1085 5.51913 21.6746 5.96974L9.45234 18.662C9.01843 19.1127 8.31491 19.1127 7.88099 18.662L2.32544 12.8928C1.89152 12.4422 1.89152 11.7116 2.32544 11.261C2.75935 10.8104 3.46287 10.8104 3.89679 11.261L8.66667 16.2144L20.1032 4.33795C20.5371 3.88735 21.2406 3.88735 21.6746 4.33795Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'Check',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
