<template>
  <div class="hero">
    <div class="hero-intro">
      <pattern-background>
        <div class="hero-intro-content">
          <Logo variant="dark-large-logo" />
          <h1><strong>Simple</strong> and <u>effective</u> retrospectives for your team.</h1>
          <h2>Why? Because great retros make great teams.</h2>
        </div>
        <div class="hero-footer"><ArrowDown color="#323d47" /> See what makes Retrospectives so effective</div>
        <div v-if="false" class="hero-footer">Sign in now to create an account and run your first Retro.</div>
      </pattern-background>
    </div>
    <div class="hero-login">
      <Login />
    </div>
  </div>
</template>

<script>
import auth from '@/auth';
import ArrowDown from '@/components/icons/ArrowDown';
import Logo from '@/components/Logo';
import Login from '@/components/Login';
import PatternBackground from '@/components/PatternBackground';

export default {
  name: 'Hero',
  components: {
    ArrowDown,
    Logo,
    Login,
    PatternBackground,
  },
  created() {
    this.checkIfLoggedIn();
  },
  methods: {
    async checkIfLoggedIn() {
      if (await auth.getCurrentUser()) {
        console.log('--- is logged in already, redirecting');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .hero {
    flex-direction: row;
    min-height: 600px;
    height: 100vh;
  }
}
.hero-intro,
.hero-login {
  width: 100%;
}
@media (min-width: 768px) {
  .hero-intro,
  .hero-login {
    width: 50%;
  }
}
.hero-intro {
  h1 {
    margin: 2.5rem 0;
    font-size: 40px;
    font-weight: 400;
    font-family: 'Merriweather', serif;

    strong {
      font-weight: 700;
    }
  }

  h2 {
    font-size: 22px;
    line-height: 1.4;
    font-weight: $font_weight_regular;
  }
}
@media (min-width: 768px) {
  .hero-intro {
    min-height: 80vh;

    h1 {
      margin: 3rem 0;
      font-size: 48px;
    }
  }
}

.hero-intro-content {
  max-width: 550px;
  margin: 2rem;
}
@media (min-width: 768px) {
  .hero-intro-content {
    padding-top: 50px;
  }
}
.hero-login {
  background: white;
  padding: 2rem;
}
@media (min-width: 768px) {
  .hero-login {
    display: grid;
    place-items: center;
  }
}
.hero-footer {
  display: none;
}
@media (min-width: 768px) {
  .hero-footer {
    margin: 3rem 2rem 0 2rem;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 550px;

    .icon {
      margin-right: 0.5rem;
    }
  }
}
</style>
