<template>
  <div class="login">
    <Logo v-if="showLogo" variant="dark-logo" />
    <p class="message" v-if="message">
      {{ message }}
    </p>
    <button-sign-in logo="google" @click="loginWithGoogle">Sign in with Google</button-sign-in>
    <div class="seperator">
      <span></span>
      <div class="label">Or</div>
      <span></span>
    </div>
    <TextInput
      placeholder="Enter your email address"
      :value="userEmail"
      :error="emailInputError"
      @handleChange="handleEmailInputChange($event)"
    />
    <Button size="large" @click="loginWithEmail" :isLoading="emailLoginIsLoading">Log in with email</Button>
    <div v-if="!hideTerms" class="note">
      By signing in, you are agreeing to our <router-link to="/terms-and-conditions">Terms of Service</router-link> and
      that you have read our <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
    <div v-if="showNote" class="note">Not using Retro yet? <router-link to="/">Learn more</router-link>.</div>
  </div>
</template>

<script>
import auth from '@/auth';
import Button from '@/components/Button';
import ButtonSignIn from '@/components/ButtonSignIn';
import Logo from '@/components/Logo';
import TextInput from '@/components/atoms/TextInput';

export default {
  name: 'Login',
  props: ['showLogo', 'showNote', 'hideTerms', 'message'],
  components: {
    Button,
    ButtonSignIn,
    Logo,
    TextInput,
  },
  data: () => ({
    userEmail: '',
    emailInputError: false,
    emailLoginIsLoading: false,
  }),
  methods: {
    loginWithGoogle() {
      auth.signInWithGoogle();
    },
    loginWithGithub() {
      auth.signInWithGithub();
    },
    loginWithMicrosoft() {
      auth.signInWithMicrosoft();
    },
    loginWithEmail() {
      if (!this.userEmail) {
        this.emailInputError = true;
        return;
      }
      this.emailInputError = false;
      this.emailLoginIsLoading = true;
      const existingRedirectUrl = auth.getRedirectUrl();
      auth.setRedirectUrl(existingRedirectUrl || '/boards', true);
      auth.signInWithEmail(this.userEmail);
    },
    handleEmailInputChange(userEmail) {
      this.userEmail = userEmail;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding: $space_md 0;
  max-width: 410px;
  width: 100%;
  font-weight: $font_weight_regular;

  .message {
    font-size: 20px;
    line-height: 1.4;
    margin: $space_md 0;
  }
}
.seperator {
  font-size: 0.875rem;
  color: $color_text_lightGrey;
  margin: $space_sm 0;
  display: flex;
  align-items: center;

  .label {
    padding: 0 $space_sm;
  }

  span {
    width: 50%;
    height: 1px;
    background: rgba(0, 0, 0, 0.05);
  }
}
.note {
  font-size: 0.875rem;
  text-align: center;
  margin-top: $space_sm;

  a {
    color: inherit;
  }
}
</style>
