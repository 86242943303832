<template>
  <div class="container">
    <h2 v-if="title">{{ title }}</h2>
    <slot></slot>
    <div class="form-button" v-if="showButton">
      <Button @click="onClick" :isLoading="isLoading">Save</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'SettingContainer',
  props: ['title', 'isLoading', 'showButton'],
  components: {
    Button,
  },
  methods: {
    onClick() {
      this.$emit('onSave');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  border: solid 1px #e7e7e7;
  padding: $space_md;
  border-radius: 6px;
  background: white;

  p {
    font-size: 14px;
  }
}
.container + .container {
  margin-top: $space_lg;
}
</style>
