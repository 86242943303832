<template>
  <div class="layout-content">
    <Logo variant="dark-logo" />
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
  name: 'LayoutContent',
  components: {
    Logo,
  },
};
</script>

<style scoped lang="scss">
.layout-content {
  max-width: 750px;
  margin: 80px auto;
  padding: 0 24px;
}
</style>
