<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M0 0H24V24H0V0Z" />
      </mask>
      <g mask="url(#mask0)">
        <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M20 9H4V11H20V9ZM4 15H20V13H4V15Z" />
      </g>
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'Drag',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
