<template>
  <div class="check">
    <IconCheck color="white" />
  </div>
</template>

<script>
import IconCheck from '@/components/icons/Check';

export default {
  name: 'Check',
  components: {
    IconCheck,
  },
};
</script>

<style scoped lang="scss">
.check {
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  background: $color_blue;
  border-radius: 12px;
  border: solid 2px $color_blue;

  .icon {
    width: 16px;
    height: 16px;
  }
}
</style>
