<template>
  <div>
    <settings-org-members :organisation="organisation" v-if="hasOrgData" />
    <settings-org-invite :organisation="organisation" v-if="hasOrgData && canInviteUsers" />
    <settings-org-name :organisation="organisation" v-if="hasOrgData" />
    <settings-org-plan :organisation="organisation" v-if="hasOrgData" />
  </div>
</template>

<script>
import plans from '@/constants/plans';
import SettingsOrgInvite from '@/components/SettingsOrgInvite';
import SettingsOrgMembers from '@/components/SettingsOrgMembers';
import SettingsOrgName from '@/components/SettingsOrgName';
import SettingsOrgPlan from '@/components/SettingsOrgPlan';

export default {
  name: 'OrgSettings',
  props: ['orgId'],
  components: {
    SettingsOrgInvite,
    SettingsOrgMembers,
    SettingsOrgName,
    SettingsOrgPlan,
  },
  computed: {
    organisation() {
      const org = this.$store.getters['organisations/organisationById'](this.orgId);
      return org || {};
    },
    hasOrgData() {
      return this.organisation && this.organisation.id;
    },
    canInviteUsers() {
      return this.organisation ? plans.findPlan(this.organisation.plan.planId).permissions.inviteUsers : false;
    },
  },
};
</script>
