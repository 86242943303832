import formatISO from 'date-fns/formatISO';
import db from '@/db/cards';
import { generateId } from '@/utils';

const state = {
  cards: null,
  actionCards: null,
};

const getters = {
  cards: state => state.cards || [],
  cardById: state => id => {
    return state.cards ? state.cards.find(card => card.id === id) : {};
  },
  actionCards: state => state.actionCards || [],
};

const mutations = {
  setCards: (state, cards) => {
    state.cards = cards;
  },
  setActionCards: (state, actionCards) => {
    console.log('actionCards', actionCards);
    state.actionCards = actionCards;
  },
  addCard(state, card) {
    state.cards.push(card);
  },
  removeCard(state, card) {
    state.cards.splice(state.cards.indexOf(card), 1);
  },
  editCard(state, card) {
    const index = state.cards.findIndex(x => x.id === card.id);
    state.cards.splice(index, 1, card);
  },
  reset: state => {
    console.log('resetting card watcher..');
    db.stopCardWatcher && db.stopCardWatcher();
    state.cards = null;
  },
};

const actions = {
  fetchCards({ commit }, boardId) {
    if (boardId) {
      db.fetchCardsByBoardId(boardId, cards => commit('setCards', cards));
    }
  },
  async fetchActionCards({ commit }, ids) {
    if (ids && ids.length) {
      //db.fetchCardsByIdArr(ids, cards => commit('setActionCards', cards));
      const cards = await db.fetchCardsByIdArr(ids);
      commit('setActionCards', cards);
    }
  },
  addCard({ commit }, card) {
    const newCard = {
      ...card,
      id: generateId(),
      createdDate: formatISO(new Date()),
      votes: [],
      plus1s: [],
    };
    commit('addCard', newCard);
    // Sync to DB
    db.addCard(newCard);
  },
  removeCard({ commit }, card) {
    commit('removeCard', card);
    // Sync to DB
    db.removeCard(card.id);
  },
  editCard({ commit }, card) {
    commit('editCard', card);
    // Sync to DB
    db.updateCard(card.id, card);
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
