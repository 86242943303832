<template>
  <div class="board-editor">
    <h2>Board Settings</h2>
    <p class="small">
      Edit your board settings, change its name and update or add new columns to your Retro board.
    </p>
    <div class="form-field">
      <label>Board name</label>
      <input v-model="board.name" type="text" class="form-input" />
    </div>
    <column-manager :columns="board.columns" @columnChange="onColumnChange" />
    <div class="separator"></div>
    <board-settings v-if="board.settings" :settings="board.settings" />
    <div class="modal-button">
      <Button @click="save">Save changes</Button>
    </div>
  </div>
</template>

<script>
import BoardSettings from '@/components/BoardSettings';
import Button from '@/components/Button';
import ColumnManager from '@/components/ColumnManager';

export default {
  name: 'BoardEditor',
  props: ['board'],
  components: {
    BoardSettings,
    Button,
    ColumnManager,
  },
  computed: {
    columns() {
      return this.board.columns;
    },
  },
  methods: {
    onColumnChange(cols) {
      this.board.columns = [...cols];
    },
    close() {
      this.$emit('close');
    },
    save() {
      const board = this.board;
      this.$store.dispatch('boards/edit', {
        ...board,
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.board-editor {
  font-size: 14px;
}
.small {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.separator {
  margin: 1rem 0;
  border-top: solid 1px #eee;
  width: 100%;
  height: 0px;
}
</style>
