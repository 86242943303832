<template>
  <div class="popover" v-if="isVisible" v-bind:style="style" ref="dropdown">
    <slot></slot>
  </div>
</template>

<script>
/* based on: https://github.com/euvl/vue-js-popover/blob/master/src/Popover.vue */

import { events } from './bus';

const directions = {
  left: [-1, 0],
  right: [1, 0],
  top: [0, 1],
  bottom: [0, -1],
};

export default {
  name: 'Popover',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 100,
    },
    target: {},
  },
  data: () => ({
    isVisible: false,
    position: {
      left: 0,
      top: 0,
    },
  }),
  mounted() {
    events.$on('showPopover', this.handleShowPopover);
    events.$on('hidePopover', this.handleHidePopover);
  },
  beforeDestroy() {
    // TODO remove events
    // events.$off(this.showEventName, this.showEventListener);
    // events.$off(this.hideEventName, this.hideEventListener);
  },
  computed: {
    showEventName() {
      return `show:${this.event}`;
    },
    hideEventName() {
      return `hide:${this.event}`;
    },
    style() {
      return {
        width: `${this.width}px`,
        ...this.position,
      };
    },
  },
  methods: {
    handleShowPopover(event) {
      const { el, name, position } = event;

      if (this.isVisible) {
        this.handleHidePopover();
      } else {
        if (name === this.name) {
          this.isVisible = true;
          this.$emit('show', event);

          this.$nextTick(() => {
            this.$nextTick(() => {
              const direction = directions[position];
              const dropdownPos = this.getDropdownPosition(el, this.$refs.dropdown, direction);
              this.position = {
                left: `${dropdownPos.left}px`,
                top: `${dropdownPos.top}px`,
              };
            });
          });
        }
      }
    },
    handleHidePopover(event) {
      if (this.isVisible) {
        this.isVisible = false;
        this.$emit('hide', event);
      }
    },
    getDropdownPosition(el, dropdown) {
      const elRect = el.getBoundingClientRect();
      const ddRect = dropdown.getBoundingClientRect();

      // Scroll offset of the current document
      // const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;

      // Position within the parent
      // const offsetLeft = elRect.left + scrollLeft;
      // const offsetTop = elRect.top + scrollTop;

      // Positioning realtive to everythign
      // const leftX = offsetLeft + elRect.width - ddRect.width;
      // const topY = offsetTop; // + elRect.height;

      const elOffsetTop = el.offsetTop;
      const elOffsetLeft = el.offsetLeft;

      const leftX = elOffsetLeft - (ddRect.width - elRect.width);
      const topY = elOffsetTop;

      return {
        left: leftX,
        top: topY,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.popover {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border-radius: 4px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 20;
  overflow: hidden;
}
.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu-item {
  padding: 6px 10px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  text-align: left;

  &:hover {
    background: #f6f6f6;
  }
}
.menu-item + .menu-item {
  border-top: solid 1px #eee;
}
</style>
