import { db } from './firebase-config';

const actions = {
  fetchTasksByTeamId(teamId, resolve) {
    // Fetch store and set up watcher
    const query = db.collection('actions').where('teamId', '==', teamId);

    query.onSnapshot(
      snap => {
        const actions = [];
        snap.forEach(doc => {
          actions.push(doc.data());
        });
        // resolve promise
        resolve(actions);
      },
      err => {
        /* eslint-disable */
        console.log(`Encountered fetch error: ${err}`);
        /* eslint-enable */
      }
    );
  },
  addTask(action) {
    if (action) {
      db.collection('actions')
        .doc(action.id)
        .set(action);
    }
  },
  updateTask(id, data) {
    if (data) {
      const query = db.collection('actions').doc(id);
      query.update(data);
    }
  },
  removeTask(id) {
    if (id) {
      db.collection('actions')
        .doc(id)
        .delete();
    }
  },
};

export default actions;
