<template>
  <settings-container title="Members">
    <p>
      These are the current members who are part of your organisation.
    </p>
    <user-list :orgId="organisation.id" />
  </settings-container>
</template>

<script>
import SettingsContainer from '@/components/SettingsContainer';
import UserList from '@/components/UserList';

export default {
  name: 'SettingOrgMembers',
  props: ['organisation'],
  components: {
    SettingsContainer,
    UserList,
  },
};
</script>
