import Vue from 'vue';
import Vuex from 'vuex';

import tasks from './modules/tasks';
import boards from './modules/boards';
import cards from './modules/cards';
import mergeQueue from './modules/mergeQueue';
import organisations from './modules/organisations';
import teams from './modules/teams';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggingIn: false,
  },
  actions: {
    onTeamChange({ commit }) {
      commit('tasks/reset');
      commit('boards/reset');
      commit('cards/reset');
      commit('mergeQueue/reset');
    },
    onOrgChange({ commit }) {
      commit('tasks/reset');
      commit('boards/reset');
      commit('cards/reset');
      commit('mergeQueue/reset');
      commit('teams/reset');
    },
    onLogOut({ commit }) {
      commit('tasks/reset');
      commit('boards/reset');
      commit('cards/reset');
      commit('mergeQueue/reset');
      commit('teams/reset');
      commit('user/reset');
    },
    toggleLoggingIn({ commit }, toggle) {
      commit('toggleLoggingIn', toggle);
    },
  },
  mutations: {
    toggleLoggingIn(state, toggle) {
      state.loggingIn = toggle;
    },
  },
  getters: {
    isLoggingIn: state => {
      return state.loggingIn;
    },
  },
  modules: {
    tasks,
    boards,
    cards,
    mergeQueue,
    organisations,
    teams,
    user,
  },
});
