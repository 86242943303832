<template>
  <Layout>
    <board-bar v-if="isLoggedInOrGuest" />
    <div class="board">
      <div v-if="boards && boards.length">
        <redirect-to-board :teamId="teamId" :board="boards[0]" />
      </div>
      <div v-if="!boards || !boards.length">
        You have not created any Retro boards yet.<br />Click the plus to start one!
      </div>
    </div>
  </Layout>
</template>

<script>
import BoardBar from '@/components/BoardBar';
import Layout from '@/components/Layout';
import RedirectToBoard from '@/components/RedirectToBoard';

export default {
  name: 'Boards',
  components: {
    BoardBar,
    Layout,
    RedirectToBoard,
  },
  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
    isLoggedInOrGuest() {
      return this.$store.getters['user/isLoggedIn'] || this.$store.getters['user/isGuest'];
    },
    boards() {
      return this.$store.getters['boards/boards'];
    },
  },
};
</script>

<style scoped lang="scss">
.board {
  flex: 1;
  padding: $space_sm;
}
</style>
