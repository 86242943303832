const state = {
  mergeQueue: [],
};

const getters = {
  queue: state => state.mergeQueue,
  isCardInQueue: state => cardId => {
    return state.mergeQueue.indexOf(cardId) > -1;
  },
};

const mutations = {
  add: (state, cardId) => {
    state.mergeQueue.push(cardId);
  },
  reset: state => {
    state.mergeQueue = [];
  },
};

const actions = {
  add({ commit }, cardId) {
    commit('add', cardId);
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
