import formatISO from 'date-fns/formatISO';
import { db } from './firebase-config';
import { generateId } from '@/utils';
// import organisations from './organisations';

const invites = {
  updateInvite(id, data) {
    if (data) {
      const query = db.collection('inviteCodes').doc(id);
      query.update(data);
    }
  },
  async validateInvite(code) {
    // query invite from DB
    const invite = await db
      .collection('inviteCodes')
      .doc(code)
      .get();

    if (invite.exists) {
      const inviteRes = invite.data();
      // if the invite has not expired, check the organisation can accept invites
      if (!inviteRes.expired) {
        //const org = await organisations.fetchOrgById(inviteRes.organisationId);
        // if there is an organisation and its not a free org, return true
        //if (org && org.plan.tier > 1) {
        return inviteRes;
        //}
        // return false;
      }
      return false;
    }
    return false;
  },
  async getInvitesForOrg(organisationId) {
    const collection = db.collection('inviteCodes');
    let snapshot = await collection
      .where('organisationId', '==', organisationId)
      .where('expired', '==', false)
      .get();

    if (snapshot.empty) {
      return [];
    } else {
      const arr = [];
      snapshot.forEach(doc => {
        arr.push({ ...doc.data(), id: doc.id });
      });
      return arr;
    }
  },
  async generateNewInvite(organisationId, resolve) {
    // TODO: this should be a cloud function
    // Expire old invites fiurst
    if (await this.expireAllOrgInvites(organisationId)) {
      // Add new invite
      const newInviteCode = `${generateId()}${generateId()}`;
      const newInviteObj = {
        expired: false,
        organisationId,
        createdDate: formatISO(new Date()),
      };
      db.collection('inviteCodes')
        .doc(newInviteCode)
        .set(newInviteObj);
      // return response
      resolve({ ...newInviteObj, id: newInviteCode });
    }
  },
  async expireAllOrgInvites(organisationId) {
    const existingInvites = await this.getInvitesForOrg(organisationId);
    existingInvites.forEach(invite => {
      this.updateInvite(invite.id, {
        expired: true,
      });
    });
    return true;
  },
};

export default invites;
