<template>
  <Layout layout="center">
    <h1>Settings</h1>
    <org-settings v-if="currentOrgId && accessAllowed" :orgId="currentOrgId" />
  </Layout>
</template>

<script>
import Layout from '@/components/Layout';
import OrgSettings from '@/components/OrgSettings';
import { isOrgOwner } from '@/utils/permissions';

export default {
  name: 'Settings',
  components: {
    Layout,
    OrgSettings,
  },
  computed: {
    currentOrgId() {
      return this.$store.getters['organisations/currentId'];
    },
    accessAllowed() {
      if (this.currentOrgId) {
        return isOrgOwner(this.currentOrgId);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-layout {
  max-width: 800px;
  margin: $space_lg auto;

  h1 {
    margin-bottom: $space_lg;
  }
}
</style>
