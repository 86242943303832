<template>
  <div class="user-list">
    <table>
      <thead>
        <tr>
          <th><div>User</div></th>
          <th><div>Email</div></th>
          <th><div>Role</div></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>
            <div>{{ user.displayName }}</div>
          </td>
          <td>
            <div>{{ user.email }}</div>
          </td>
          <td>
            <div>
              <span v-if="!isCurrentUser(user)">
                <select @change="onRoleChange($event, user)">
                  <option value="0">Member</option>
                  <option :selected="isOwner(user)" value="10">Owner</option>
                </select>
              </span>
              <small v-if="isCurrentUser(user)">{{ isOwner(user) ? 'Owner' : 'Member' }}</small>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <small>{{ users.length }} active {{ users > 1 ? 'members' : 'member' }} in this organisation</small>
  </div>
</template>

<script>
import Vue from 'vue';
import Popover from '@/plugins/popover';

Vue.use(Popover);

export default {
  name: 'UserList',
  props: ['orgId'],
  created() {
    this.getUsers(this.orgId);
  },
  computed: {
    users() {
      const users = this.$store.getters['organisations/users'];
      return users.sort((a, b) => a.displayName.localeCompare(b.displayName));
    },
    currentUser() {
      return this.$store.getters['user/user'];
    },
  },
  methods: {
    getUsers(organisation) {
      if (organisation) {
        this.$store.dispatch('organisations/fetchUsersForOrg', organisation);
      }
    },
    isCurrentUser(user) {
      return this.currentUser && this.currentUser.uid && this.currentUser.uid == user.uid;
    },
    isOwner(user) {
      const hasRole = this.getUserRole(user, this.orgId);
      return !!hasRole && hasRole.role;
    },
    getUserRole(user, orgId) {
      return user.roles && user.roles.find(o => o.organisationId === orgId);
    },
    onRoleChange(event, user) {
      const role = Number(event.target.value);
      // const userRole = this.getUserRole(user, this.orgId);
      const newRole = {
        organisationId: this.orgId,
        role,
      };
      const withoutRole = !user.roles ? [] : user.roles.filter(o => o.organisationId !== this.orgId);
      const roles = [...withoutRole, newRole];

      this.$store.dispatch('organisations/editUser', {
        ...user,
        roles,
      });
    },
    onRemove(user) {
      console.log(user);
    },
  },
};
</script>

<style scoped lang="scss">
.user-list {
  small {
    color: $color_text_grey;
    font-size: 12px;
  }
}
table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0px;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: $space_xs;
}
th {
  color: $color_text;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 10px;
  border-top: 1px solid $color_table_border;
  border-bottom: 1px solid $color_table_border;
  background-color: #fafafa;
  font-weight: $font_weight_bold;

  &:first-child {
    border-radius: 4px 0px 0px 4px;
    border-left: 1px solid $color_table_border;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
    border-right: 1px solid $color_table_border;
  }

  > div {
    align-items: center;
    display: flex;
    min-height: 40px;
    font-size: 12px;
    text-transform: uppercase;
  }
}
td {
  padding: 0px 10px;
  font-weight: 400;
  color: $color_text;
  border-bottom: 1px solid $color_table_border;

  > div {
    align-items: center;
    display: flex;
    min-height: 50px;
    flex-flow: row wrap;
  }

  &:last-child {
    text-align: right;
  }

  select,
  small {
    font-size: 14px;
  }
}
.menu-btn {
  cursor: pointer;
  margin-left: auto;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f6f6f6;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
</style>
