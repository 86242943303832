<template>
  <team-home />
</template>

<script>
import TeamHome from '@/components/TeamHome';

export default {
  name: 'Retros',
  components: {
    TeamHome,
  },
};
</script>
