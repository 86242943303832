<template>
  <settings-container title="Organisation Name" @onSave="onSave" :isLoading="isLoading" :showButton="true">
    <p>This is your organisation's visible name within Retro. For example, the name of your company or department.</p>
    <div class="form-field">
      <input class="form-input" type="text" v-model="organisation.name" placeholder="ex. 'Project X' or 'Developers'" />
    </div>
  </settings-container>
</template>

<script>
import SettingsContainer from '@/components/SettingsContainer';

export default {
  name: 'SettingOrgName',
  props: ['organisation'],
  components: {
    SettingsContainer,
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    onSave() {
      if (!this.organisation.name) {
        alert('A name is required');
        return;
      }
      this.isLoading = true;

      this.$store.dispatch('organisations/edit', {
        ...this.organisation,
      });
      // TODO This is hacky...fix it
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>
