<template>
  <Layout v-if="canAccessBoard">
    <board-bar v-if="isLoggedInOrGuest" v-show="isLoggedIn" />
    <div class="board" v-if="board">
      <TitleBar :board="board" :teamId="teamId" />
      <Columns :board="board" :teamId="teamId" />
    </div>
    <login-gate :teamId="teamId" v-if="!isLoggedInOrGuest" />
  </Layout>
</template>

<script>
import BoardBar from '@/components/BoardBar';
import Columns from '@/components/Columns';
import Layout from '@/components/Layout';
import LoginGate from '@/components/LoginGate';
import TitleBar from '@/components/TitleBar';
import { trackTeamView } from '@/utils';
import { boardAccessAllowed } from '@/utils/permissions';

export default {
  name: 'Board',
  components: {
    BoardBar,
    Columns,
    Layout,
    LoginGate,
    TitleBar,
  },
  data: () => ({
    boardId: null,
    teamId: null,
  }),
  created() {
    this.teamId = this.$route.params.teamId;
    this.boardId = this.$route.params.boardId;
    trackTeamView(this.teamId);
  },
  beforeRouteUpdate(to, from, next) {
    this.boardId = to.params.boardId;
    next();
  },
  computed: {
    board() {
      const boardId = this.boardId;
      const board = this.$store.getters['boards/boardById'](boardId);
      return board;
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    isLoggedInOrGuest() {
      return this.isLoggedIn || this.$store.getters['user/isGuest'];
    },
    canAccessBoard() {
      return boardAccessAllowed();
    },
  },
};
</script>

<style scoped lang="scss">
.board {
  flex: 1;
  padding: 0 $space_xs;
}
</style>

<style lang="scss">
.board-settings {
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
  height: $size_sidebar;
  background: $color_sidebar;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
  }
  svg {
    fill: #cacaca;
  }
  &:hover {
    svg {
      fill: $color_primary;
    }
  }
}
</style>
