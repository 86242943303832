<template>
  <div class="generate-invite">
    <div class="form-field inline">
      <input class="form-input" ref="input" type="text" v-model="inviteCode" readonly @click="selectAll" />
      <Button @click="generateInviteCode()" :isLoading="isLoading">Regenerate invite</Button>
    </div>
  </div>
</template>

<script>
import invites from '@/db/invites';
import Button from '@/components/Button';

export default {
  name: 'GenerateInvite',
  props: ['organisationId'],
  data: () => ({
    isLoading: false,
    inviteCode: '',
  }),
  created() {
    this.getInvite();
  },
  components: {
    Button,
  },
  methods: {
    selectAll() {
      this.$refs.input.select();
    },
    async getInvite() {
      const invite = await invites.getInvitesForOrg(this.organisationId);
      if (invite.length) {
        this.updateInvite(invite);
      } else {
        this.generateInviteCode();
      }
    },
    generateInviteCode() {
      this.isLoading = true;
      invites.generateNewInvite(this.organisationId, data => {
        this.isLoading = false;
        this.updateInvite([data]);
      });
    },
    updateInvite(inviteArr) {
      const firstInvite = inviteArr[0];
      if (firstInvite && firstInvite.id) {
        this.inviteCode = `${window.location.origin}/invite/${firstInvite.id}`;
      }
    },
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped></style>
