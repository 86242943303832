<template>
  <div class="card" :id="card.id">
    <div class="card-content">
      <avatar :user="card.user" />
      <div class="text"><span v-html="linkifiedTitle"></span></div>
    </div>
    <div class="card-tasks" v-if="card.tasks">
      <task v-for="task in card.tasks" :task="task" :key="task.id" />
    </div>
  </div>
</template>

<script>
import anchorme from 'anchorme';
import Avatar from '@/components/Avatar';
import Task from '@/components/Task';

export default {
  name: 'ActionCard',
  props: ['card'],
  components: {
    Avatar,
    Task,
  },
  computed: {
    linkifiedTitle() {
      return anchorme({
        input: this.card.title,
        options: {
          truncate: 40,
          attributes: {
            target: '_blank',
          },
        },
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background: white;
  margin-bottom: $space_xs;
  border-radius: 4px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
}
.card-content {
  display: grid;
  grid-template-columns: 32px 1fr;
  padding: $space_xs;
  font-size: 14px;
}
</style>
