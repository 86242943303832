<template>
  <pattern-background>
    <div v-if="loading" class="loader" />
    <div v-if="!loading && !error && user && !user.displayName" class="box">
      <Logo variant="dark-logo" />
      <p class="message">
        Please enter a name to continue signing in.
      </p>
      <div class="form-field">
        <TextInput
          placeholder="Please enter your name..."
          :value="name"
          :error="formError"
          @handleChange="handleInputChange($event)"
        />
        <Button @click="completeSignIn()">Continue</Button>
      </div>
    </div>
    <div v-if="error && !loading" class="box">
      <Logo variant="dark-logo" />
      <p>Sorry, something went wrong when we tried to sign you in. <a href="/">Please try again.</a></p>
    </div>
  </pattern-background>
</template>

<script>
import auth from '@/auth';
import Button from '@/components/Button';
import Logo from '@/components/Logo';
import TextInput from '@/components/atoms/TextInput';
import PatternBackground from '@/components/PatternBackground';

export default {
  name: 'SignInComplete',
  components: {
    Button,
    Logo,
    PatternBackground,
    TextInput,
  },
  data: () => ({
    loading: true,
    error: false,
    user: {},
    formError: false,
    showForm: false,
  }),
  created() {
    auth.completeEmailSignup(
      signInContinue => {
        this.loading = false;
        this.error = false;
        this.user = signInContinue;

        if (this.user.displayName) {
          this.finaliseSignIn(this.user);
        } else {
          this.showForm = true;
        }
      },
      signInError => {
        console.log(signInError);
        this.showForm = false;
        this.loading = false;
        this.error = true;
      }
    );
  },
  methods: {
    handleInputChange(value) {
      this.name = value;
    },
    completeSignIn() {
      if (this.name) {
        this.loading = true;
        const user = { ...this.user, displayName: this.name };
        this.finaliseSignIn(user);
      } else {
        this.formError = true;
      }
    },
    finaliseSignIn(user) {
      auth.authenticateUser({ ...user });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: -2rem;
  padding: $space_md 0;
  max-width: 410px;
  width: 100%;
  font-weight: $font_weight_regular;

  .message {
    font-size: 20px;
    line-height: 1.4;
    margin: $space_md 0;
  }
}
.form-field {
  position: relative;

  .button {
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 40px;
  }
}
</style>
