import { db } from './firebase-config';

const organisations = {
  async queryByDomain(domain) {
    const collection = db.collection('organisations');
    let snapshot = await collection.where('domain', '==', domain).get();

    if (snapshot.empty) {
      return false;
    } else {
      const arr = [];
      snapshot.forEach(doc => {
        arr.push(doc.id);
      });
      return arr;
    }
  },
  addOrganisation(organisation) {
    return db
      .collection('organisations')
      .doc(organisation.id)
      .set(organisation)
      .then(() => {
        return organisation.id;
      });
  },
  updateOrg(id, data) {
    if (data) {
      const query = db.collection('organisations').doc(id);
      query.update(data);
    }
  },
  async fetchOrgById(id) {
    const org = await db
      .collection('organisations')
      .doc(id)
      .get();

    if (org.exists) {
      return org.data();
    } else {
      return false;
    }
  },
  async fetchOrgsByIdArr(ids) {
    if (!ids || !ids.length) {
      return false;
    }

    const collection = db.collection('organisations');
    let snapshot = await collection.where('id', 'in', ids).get();

    if (snapshot.empty) {
      return false;
    } else {
      const arr = [];
      snapshot.forEach(doc => {
        arr.push(doc.data());
      });
      return arr;
    }
  },
  async fetchUsersForOrg(orgId) {
    const collection = db.collection('users');
    let snapshot = await collection.where('organisations', 'array-contains', orgId).get();

    if (snapshot.empty) {
      return false;
    } else {
      const arr = [];
      snapshot.forEach(doc => {
        const uid = doc.id;
        const user = { ...doc.data(), uid };
        arr.push(user);
      });
      return arr;
    }
  },
  updateOrgUser(uid, data) {
    if (data) {
      const query = db.collection('users').doc(uid);
      query.update(data);
    }
  },
};

export default organisations;
