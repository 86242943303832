import formatISO from 'date-fns/formatISO';
import db from '@/db/teams';
import { generateId } from '@/utils';

const state = {
  teams: [],
  currentTeam: null,
};

const getters = {
  teams: state => state.teams,
  teamById: state => id => {
    return state.teams ? state.teams.find(team => team.id === id) : {};
  },
  currentTeam: state => state.currentTeam,
  teamCount: state => state.teams.length,
};

const mutations = {
  setTeams: (state, teams) => {
    state.teams = [...state.teams, ...teams];
  },
  mergeTeams: (state, teams) => {
    const initialTeams = state.teams;
    const ids = new Set(initialTeams.map(d => d.id));
    state.teams = [...initialTeams, ...teams.filter(d => !ids.has(d.id))];
  },
  add(state, team) {
    state.teams.push(team);
  },
  edit(state, team) {
    const index = state.teams.findIndex(x => x.id === team.id);
    state.teams.splice(index, 1, team);
  },
  setCurrentTeam: (state, team) => {
    state.currentTeam = { ...team };
  },
  reset: state => {
    state.currentTeam = null;
  },
};

const actions = {
  fetchTeams({ commit }, organisation) {
    return new Promise(resolve => {
      if (organisation) {
        db.fetchTeamsByOrganisation(organisation, teams => {
          commit('mergeTeams', teams);
          resolve(teams);
        });
      }
    });
  },
  async fetchTeamById({ commit }, teamId) {
    const team = await db.fetchTeamById(teamId);
    commit('setCurrentTeam', team);
    return team;
  },
  add({ commit }, team) {
    commit('mergeTeams', [team]);
  },
  create({ commit }, team) {
    const newTeam = {
      ...team,
      id: generateId(),
      createdDate: formatISO(new Date()),
    };
    commit('add', newTeam);
    // Sync to DB
    db.addTeam(newTeam);
  },
  edit({ commit }, team) {
    commit('edit', team);
    // Sync to DB
    db.updateTeam(team.id, team);
  },
  setCurrentTeam({ commit }, team) {
    commit('setCurrentTeam', team);
  },
  resetCurrentTeam({ commit }) {
    commit('setCurrentTeam', null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
