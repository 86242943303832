<template>
  <div class="dropdown-container" v-if="isOpen">
    <div class="dropdown">
      <div class="dropdown-row">
        <div class="row-text">
          <strong>{{ (currentOrganisation && currentOrganisation.name) || 'Your free account' }}</strong>
          <div class="tag" :class="['tier-' + currentOrgTierName]" v-if="shouldShowOrgSettings && currentOrgTierName">
            {{ currentOrgTierName }}
          </div>
        </div>
        <div class="row-small row-link" v-if="shouldShowOrgSettings" @click="settingClicked('/settings')">
          Settings
        </div>
      </div>
      <div
        class="dropdown-row highlighted row-link"
        @click="settingClicked('/settings/plan')"
        v-if="shouldShowOrgUpgradeButton"
      >
        <div class="row-text"><IconAward color="#323d47" /> Upgrade your account</div>
      </div>
      <div class="dropdown-row" v-if="otherOrganisations && otherOrganisations.length">
        <div class="row-small">
          Swap organisation
        </div>
        <div v-for="org in otherOrganisations" :key="org.id" class="row-text row-link" @click="orgClicked(org.id)">
          <strong>{{ org.name }}</strong>
        </div>
      </div>
      <div class="dropdown-row row-link" @click="settingClicked('/organisation/new')" v-if="shouldShowCreateOrgButton">
        <div class="row-text"><IconPlus color="#323d47" /> New organisation</div>
      </div>
      <div class="dropdown-row row-link" @click="logout">
        <div class="row-text">Logout</div>
      </div>
    </div>
    <div class="overlay" @click="toggleMenu"></div>
  </div>
</template>

<script>
import auth from '@/auth';
import IconAward from '@/components/icons/Award';
import IconPlus from '@/components/icons/Plus';
import { canCreateAnOrganisation, canUpgradeOrganisation, isOrgOwner } from '@/utils/permissions';

export default {
  name: 'UserMenuDropdown',
  props: ['isOpen', 'user'],
  components: {
    IconAward,
    IconPlus,
  },
  data: () => ({
    organisation: null,
  }),
  created() {
    this.getMyOrganisations(this.user);
  },
  computed: {
    currentOrgId() {
      return this.$store.getters['organisations/currentId'];
    },
    organisations() {
      return this.$store.getters['organisations/organisations'];
    },
    currentOrganisation() {
      return this.organisations ? this.organisations.find(o => o.id === this.currentOrgId) : {};
    },
    otherOrganisations() {
      return this.organisations ? this.organisations.filter(o => o.id !== this.currentOrgId) : [];
    },
    currentOrgTierName() {
      return this.currentOrganisation?.plan?.name ? this.currentOrganisation.plan.name : 'Free';
    },
    shouldShowOrgSettings() {
      return isOrgOwner(this.currentOrgId, this.user);
    },
    shouldShowCreateOrgButton() {
      return this.currentOrganisation?.plan && canCreateAnOrganisation(this.currentOrganisation.plan.tier);
    },
    shouldShowOrgUpgradeButton() {
      return this.currentOrganisation?.plan && canUpgradeOrganisation(this.currentOrganisation.plan.tier);
    },
  },
  methods: {
    logout() {
      this.toggleMenu();
      auth.logout();
    },
    toggleMenu() {
      this.$emit('toggleMenu');
    },
    openCreateOrg() {
      this.$emit('openCreateOrg');
      this.toggleMenu();
    },
    getMyOrganisations(user) {
      if (user) {
        this.$store.dispatch('organisations/fetchOrganisationsForUser', user.organisations);
      }
    },
    settingClicked(path) {
      this.toggleMenu();
      this.$nextTick(() => {
        this.$router.push({ path });
      });
    },
    orgClicked(orgId) {
      this.toggleMenu();
      this.$store.dispatch('organisations/setCurrentOrg', orgId);
      this.$store.dispatch('onOrgChange');
      window.location.href = '/boards';
      /* this.$nextTick(() => {
        this.$router.push('/');
      }); */
    },
  },
};
</script>

<style scoped lang="scss">
.overlay {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: $size_header;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19;
}
.dropdown-container {
  position: relative;
}
.dropdown {
  position: absolute;
  top: 100%;
  right: -$space_sm;
  margin-top: -2px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 20;
  min-width: 100%;
  text-transform: none;
}
.dropdown-row {
  font-size: 14px;
  line-height: 24px;
  padding: 12px $space_xs;
  background: #fff;
  color: $color_text;
  min-width: 240px;

  &.highlighted {
    background: $color_secondary;
    border-top: none !important;

    &:hover {
      cursor: pointer;
    }
  }
}
.dropdown-row + .dropdown-row {
  border-top: solid 1px #eee;
}
.dropdown-row.highlighted + .dropdown-row {
  border-top: none;
}
.row-text {
  display: flex;
  align-items: center;

  .icon {
    width: 18px;
    height: 18px;
    margin-right: 0.4rem;
  }
}
.row-small {
  font-size: 13px;
  color: $color_text_grey;
}
.row-link {
  cursor: pointer;
  color: $color_text;

  &:hover {
    text-decoration: underline;
  }
}
.tag {
  background: $color_secondary;
  font-size: 11px;
  line-height: 17px;
  padding: 0 4px;
  border-radius: 4px;
  text-transform: uppercase;
  margin-left: auto;

  &.tier-Free {
    background: $color_tier_free;
  }

  &.tier-Team {
    background: $color_tier_teams;
    color: white;
  }

  &.tier-Pro {
    background: $color_tier_pro;
    color: white;
  }
}
</style>
