import formatISO from 'date-fns/formatISO';
import db from '@/db/boards';
import { generateId } from '@/utils';

const state = {
  boards: [],
};

const getters = {
  boards: state => (state.boards ? state.boards.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) : []),
  boardById: state => id => {
    return state.boards ? state.boards.find(board => board.id === id) : {};
  },
};

const mutations = {
  setBoards: (state, boards) => {
    state.boards = boards;
  },
  add(state, board) {
    state.boards.push(board);
  },
  edit(state, board) {
    const index = state.boards.findIndex(x => x.id === board.id);
    state.boards.splice(index, 1, board);
  },
  reset: state => {
    state.boards = null;
  },
};

const actions = {
  fetchBoardsForTeam({ commit }, teamId) {
    db.fetchBoardsByTeamId(teamId, boards => {
      commit('setBoards', boards);
    });
  },
  async add({ commit }, board) {
    const newBoard = {
      ...board,
      id: generateId(),
      createdDate: formatISO(new Date()),
      progress: 1,
    };
    commit('add', newBoard);
    // Sync to DB
    const boardId = await db.addBoard(newBoard);
    return boardId;
  },
  edit({ commit }, board) {
    commit('edit', board);
    // Sync to DB
    db.updateBoard(board.id, board);
  },
  reset({ commit }) {
    commit('reset');
  },
  setActiveCard({ dispatch }, { cardId, boardId }) {
    const board = this.getters['boards/boardById'](boardId);
    const updatedBoard = {
      ...board,
      settings: { ...board.settings, activeCard: cardId },
    };
    dispatch('edit', updatedBoard);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
