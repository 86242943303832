import userDB from '@/db/users';
import { firebaseAuth } from '@/db';
import router from '@/router';
import invites from './invites';

const googleProvider = new firebaseAuth.GoogleAuthProvider();
const githubProvider = new firebaseAuth.GithubAuthProvider();
const microsoftProvider = new firebaseAuth.OAuthProvider('microsoft.com');

var emailActionCodeSettings = {
  // URL you want to redirect back to.
  url: `${window.location.origin}/signin/complete`,
  handleCodeInApp: true,
};

const auth = {
  context: null,
  redirectUrl: null,
  guestUserObj: null,
  isLoggingIn: true,
  init(context) {
    this.context = context;
    invites.init(context);
  },
  user() {
    return this.context ? firebaseAuth().currentUser : null;
  },
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebaseAuth().onAuthStateChanged(async user => {
        auth.startLoggingIn();
        const userIsAuthenticated = await auth.authenticateUser(user);
        if (userIsAuthenticated) {
          unsubscribe();
          resolve(user);
        } else {
          unsubscribe();
          resolve(false);
        }
      }, reject);
    });
  },
  async authenticateUser(user) {
    // check if user is already in state
    const userState = auth.context.$store.getters['user/user'];
    if (!userState) {
      if (user) {
        if (user.email) {
          // User is authenticated and logged in
          const userCreated = await auth.createAuthenticatedUser(user);
          if (userCreated) {
            auth.endLoggingIn();
            return true;
          }
        } else if (user.isAnonymous) {
          // User is a guest
          // Handle Guest
          console.log('Handle Guest');
          auth.endLoggingIn();
          return false;
        }
      } else {
        // user is not authenticated and is not logged in
        auth.endLoggingIn();
        return false;
      }
    } else {
      // user is already authenticated
      auth.endLoggingIn();
      return true;
    }
  },
  signInWithGoogle() {
    auth.startSpinner();
    // Handle Google Sign in
    firebaseAuth()
      .signInWithPopup(googleProvider)
      .then(result => {
        const user = result.user;
        auth.authenticateUser(user);
      });
  },
  signInWithGithub() {
    auth.startSpinner();
    // Handle Github Sign in
    firebaseAuth()
      .signInWithPopup(githubProvider)
      .then(result => {
        const user = result.user;
        auth.authenticateUser(user);
      });
  },
  loginWithMicrosoft() {
    auth.startSpinner();
    // Handle Microsoft Sign in
    firebaseAuth()
      .signInWithPopup(microsoftProvider)
      .then(result => {
        const user = result.user;
        auth.authenticateUser(user);
      });
  },
  signInWithEmail(email) {
    firebaseAuth()
      .sendSignInLinkToEmail(email, emailActionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        window.localStorage.setItem('retro:emailForSignIn', email);
        router.push({ name: 'SignInPending' });
      });
  },
  completeEmailSignup(signInContinue, signInError) {
    // https://firebase.google.com/docs/auth/web/email-link-auth?authuser=0
    // Confirm the link is a sign-in with email link
    if (firebaseAuth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('retro:emailForSignIn');
      if (!email) {
        // prompt for email if this browser did not store it
        email = window.prompt('Please provide your email for confirmation');
      }
      firebaseAuth()
        .signInWithEmailLink(email, window.location.href)
        .then(async result => {
          // Clear email from storage.
          window.localStorage.removeItem('retro:emailForSignIn');
          const pendingUser = result.user;

          // Check if there is a matching user already in the DB
          const existingUser = await userDB.fetchUser(pendingUser.uid);
          const existinUserData = existingUser && existingUser.exists ? existingUser.data() : false;
          const user = existinUserData ? { ...pendingUser, ...existinUserData } : { ...pendingUser };

          // return back to the sign in process
          signInContinue(user);
        })
        .catch(error => {
          // Handle 'something went wrong'
          signInError(error);
        });
    } else {
      signInError('Error');
    }
  },
  signInAnonymously(userObj) {
    auth.startSpinner();
    // Handle Anon Sign ins
    this.guestUserObj = userObj;
    const existingUser = auth.user();

    if (existingUser) {
      auth.createGuestUser({
        ...auth.guestUserObj,
        uid: existingUser.uid,
        isAnonymous: existingUser.isAnonymous,
      });
    } else {
      firebaseAuth()
        .signInAnonymously()
        .then(() => {
          auth.signInAnonymously(this.guestUserObj);
        });
    }
  },
  async createAuthenticatedUser(user) {
    // Creates an authenticated user in app State
    // Check if a user exists, or create one
    const matchedUser = await auth.context.$store.dispatch('user/fetch', user);
    // if a user is returned, process them
    if (matchedUser) {
      const newUser = {
        ...matchedUser,
        uid: user.uid,
        photoURL: user.photoURL,
      };
      auth.context.$store.dispatch('user/setCurrentUser', newUser);
      // Set the users default organisation
      auth.setCurrentOrganisation(newUser);
      auth.endLoggingIn();
      // Handle redirect
      auth.redirectOnEntry(newUser);
      return true;
    } else {
      return false;
    }
  },
  createGuestUser(user) {
    // Creates the guest in app State
    if (user && user.displayName && user.isAnonymous) {
      console.log('Logging in as guest...');
      auth.context.$store.dispatch('user/createGuest', {
        ...user,
      });
    }
    auth.endLoggingIn();
  },
  setCurrentOrganisation(newUser) {
    const userOrgs = newUser.organisations || [];
    const orgFromStorage = localStorage.getItem('retro:org');
    const currentTeam = auth.context.$store.getters['teams/currentTeam'];
    const teamOrg = currentTeam ? currentTeam.organisation : null;
    /* Ways to determine current organisations
     * 1. Get organisation from current Team (url) - done elsewhere
     * 2. Get organisation from Localstorage
     * 3. Get organisation from user permissions
     */
    if (teamOrg && userOrgs.length && userOrgs.indexOf(teamOrg) > -1) {
      // if team or is in user ORG list, then set it to the default ORG
      auth.context.$store.dispatch('organisations/setCurrentOrg', teamOrg);
    } else if (userOrgs.length && userOrgs.indexOf(orgFromStorage) > -1) {
      // Check if org from localstorage is in user list
      // Set storage org as current one
      auth.context.$store.dispatch('organisations/setCurrentOrg', orgFromStorage);
    } else {
      // Set first org from user profile as the active one
      auth.context.$store.dispatch('organisations/setCurrentOrg', userOrgs[0]);
    }
  },
  startSpinner() {
    if (auth.context) {
      auth.context.$store.dispatch('toggleLoggingIn', true);
    }
  },
  startLoggingIn() {
    auth.isLoggingIn = true;
    auth.startSpinner();
  },
  endLoggingIn() {
    auth.isLoggingIn = false;
    auth.context.$store.dispatch('toggleLoggingIn', false);
  },
  logout() {
    firebaseAuth()
      .signOut()
      .then(function() {
        // Sign-out successful.
        console.log('Sign-out successful');
        auth.context.$store.dispatch('onLogOut');
        // window.localStorage.clear();
        router.push({ name: 'Home' });
      })
      .catch(function(error) {
        // An error happened.
        console.log(error);
      });
  },
  getRedirectUrl() {
    const storedRedirect = window.localStorage.getItem('retro:redirectUrl');
    return this.redirectUrl || storedRedirect || null;
  },
  setRedirectUrl(location, persist = false) {
    this.redirectUrl = location;
    if (persist) window.localStorage.setItem('retro:redirectUrl', location);
  },
  clearRedirectUrl() {
    this.redirectUrl = null;
    window.localStorage.removeItem('retro:redirectUrl');
  },
  redirectOnEntry(user) {
    let redirect = this.getRedirectUrl();
    if (user && redirect) {
      // Handle recusive signin redirects
      redirect = redirect == 'SignIn' ? 'Boards' : redirect;
      console.log('redirecting on entry...');
      router.push({
        path: redirect,
      });
      this.clearRedirectUrl();
    }
  },
};

export default auth;
