<template>
  <Layout layout="center">
    <div class="content">
      <h1>Retro Tasks</h1>
      <retro-outcomes v-if="taskIds && taskIds.length" :tasks="tasks" :taskIds="taskIds" />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout';
import RetroOutcomes from '@/components/RetroOutcomes';

export default {
  name: 'Outcomes',
  components: {
    Layout,
    RetroOutcomes,
  },
  created() {
    this.teamId = this.$route.params.teamId;
    this.fetchTasks(this.teamId);
  },
  computed: {
    tasks() {
      return this.$store.getters['tasks/tasks'];
    },
    taskIds() {
      // reduce tasks to just ids and remove duplicates
      const ids = this.tasks.reduce((accumulator, task) => accumulator.concat(task.cardId), []);
      const uniqueSet = new Set(ids);
      return [...uniqueSet];
    },
  },
  methods: {
    fetchTasks(teamId) {
      this.$store.dispatch('tasks/fetchTasksForTeam', teamId);
    },
  },
};
</script>
