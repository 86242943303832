<template>
  <div class="modal-container">
    <div class="modal-backdrop" @click="close"></div>
    <div class="scroller">
      <div class="modal">
        <header class="modal-close">
          <button type="button" class="btn-close" @click="close"></button>
        </header>
        <section class="modal-body">
          <slot>
            I'm the default body!
          </slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  text-transform: none;
  line-height: 1.6;
  font-size: 16px;
  overflow-y: auto;
}

.scroller {
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
}

.modal {
  background: #fff;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.modal-body {
  position: relative;
  padding: 32px 32px 40px 32px;
}

.modal-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.btn-close {
  padding: 0;
  margin: 0;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: transparent;
  position: relative;
  z-index: 2;
}

.btn-close:focus {
  outline: none;
}

.btn-close:before,
.btn-close:after {
  content: '';
  width: 18px;
  height: 2px;
  background-color: #222;
  position: absolute;
  top: 19px;
  left: 11px;
  transform: rotate(45deg);
}
.btn-close:after {
  transform: rotate(-45deg);
}

.btn-close:hover:before,
.btn-close:hover:after {
  background-color: $color_red;
}
</style>

<style lang="scss">
.modal-button {
  margin-top: $space_xs;
}
</style>
