<template>
  <Layout layout="center">
    <div class="content">
      <h1>Actions</h1>
      <h3>Outstanding actions</h3>
      <div v-if="tasks" class="list">
        <task v-for="task in outstandingTasks" :task="task" :key="task.id" />
      </div>
      <h3>Completed actions</h3>
      <div v-if="doneTasks" class="list">
        <task v-for="task in doneTasks" :task="task" :key="task.id" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Task from '@/components/Task';
import Layout from '@/components/Layout';

export default {
  name: 'Actions',
  components: {
    Task,
    Layout,
  },
  created() {
    this.teamId = this.$route.params.teamId;
    this.fetchTasks(this.teamId);
  },
  computed: {
    tasks() {
      return this.$store.getters['tasks/tasks'];
    },
    outstandingTasks() {
      return this.tasks.filter(task => task.done == false);
    },
    doneTasks() {
      return this.tasks.filter(task => task.done == true);
    },
  },
  methods: {
    fetchTasks(teamId) {
      this.$store.dispatch('tasks/fetchTasksForTeam', teamId);
    },
  },
};
</script>

<style scoped lang="scss">
.list {
  margin-bottom: $space_xl;
}
</style>
