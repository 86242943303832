<template>
  <div class="pattern">
    <div class="top-left">
      <svg width="317" height="477" viewBox="0 0 317 477" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M227.691 0C227.851 14.5683 229.284 28.7977 233.158 42.2531C240.29 67.0251 257.835 88.9094 275.005 110.328C299.615 141.024 323.457 170.764 314.778 206.676C305.871 243.538 270.137 259.63 233.303 276.217C209.416 286.974 185.066 297.939 167.27 314.913C147.276 333.983 133.25 360.81 119.488 387.131C97.5805 429.032 76.3435 469.65 32.77 475.648C21.4044 477.212 10.4961 476.52 0 474.033V0H227.691Z"
          fill="#EEF1F4"
        />
      </svg>
    </div>
    <div class="bottom-right">
      <svg width="406" height="200" viewBox="0 0 406 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M406 0.826822V200H0.488129C19.3168 188.586 44.0813 182.066 69.3509 175.412C98.3069 167.788 127.926 159.989 150.038 144.453C171.362 129.47 187.867 108.671 204.176 88.1208C227.008 59.3491 249.454 31.0647 284.195 19.9116C321.673 7.8794 365.558 -1.49725 406 0.826822Z"
          fill="#EEF1F4"
        />
      </svg>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatternBackground',
};
</script>

<style lang="scss" scoped>
.pattern {
  background: $color_greyblue_100;
  width: 100%;
  height: 100%;
  position: relative;
}
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 317px;
  height: auto;
}
.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 406px;
  height: auto;
  display: flex;
}
.content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: grid;
  place-items: center;
}
</style>
