<template>
  <settings-container title="Current Plan" :showButton="false" v-if="canUpgrade">
    <div class="plan-description" v-if="isOnPaidPlan">
      <p>
        Your organisation is currently on the <strong>{{ planDetails(selectedPlan).name }} Plan</strong>.
      </p>
      <Button size="small" :isLoading="isLoadingPortal" @click="openSubscriptionPortal">Change your plan</Button>
    </div>
    <div class="plan-description" v-if="!isOnPaidPlan">
      <p>Your organisation is currently on a <strong>Free Plan</strong>.</p>
      <router-link class="link" to="/settings/plan">Upgrade your plan</router-link>
    </div>
    <choose-plan v-if="this.subscription" :selectedPlan="selectedPlan" :locked="true" />
  </settings-container>
</template>

<script>
import subscriptions from '@/db/subscriptions';
import plans from '@/constants/plans';
import features from '@/constants/features';
import Button from '@/components/Button';
import ChoosePlan from '@/components/ChoosePlan';
import SettingsContainer from '@/components/SettingsContainer';

export default {
  name: 'SettingOrgPlan',
  props: ['organisation'],
  data: () => ({
    isLoadingPortal: false,
    subscription: null,
  }),
  created() {
    this.fetchSubscriptionPlan();
  },
  components: {
    Button,
    ChoosePlan,
    SettingsContainer,
  },
  computed: {
    selectedPlan() {
      return this.organisation.plan.planId;
    },
    isOnPaidPlan() {
      return this.organisation.plan.tier > 1;
    },
    canUpgrade() {
      return features.upgradeOrganisations;
    },
  },
  methods: {
    planDetails(planId) {
      return plans.findPlan(planId);
    },
    async fetchSubscriptionPlan() {
      subscriptions.fetchOrganisationSubscription(this.organisation.id, data => {
        this.subscription = data;
      });
    },
    async openSubscriptionPortal() {
      this.isLoadingPortal = true;
      const data = await subscriptions.createPortalLink();
      if (data && data.url) {
        window.location.assign(data.url);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.plan-description {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 1rem;

  p {
    margin: 0;
  }

  .button {
    margin-left: auto;
  }
}
.link {
  margin-left: auto;
  font-weight: $font_weight_bold;
  text-decoration: underline;
  cursor: pointer;
  color: $color_text;
}
</style>
