<template>
  <Layout layout="settings" hideHeader="true">
    <div class="content">
      <div class="page-close">
        <router-link to="/boards"><IconClose color="#323d47"/></router-link>
      </div>
      <Logo variant="dark-logo" />
      <h1>Create a new Organisation</h1>
      <p>
        Create a new space for your team to participate in and manage their own Retros.
      </p>
      <div class="form">
        <div class="form-field">
          <label>1. Organisation name</label>
          <input
            v-model="org.name"
            type="text"
            class="form-input"
            placeholder="i.e the name of your company or department"
          />
        </div>
        <div class="form-row">
          <h3>2. Choose a plan</h3>
          <choose-plan :selectedPlan="plan.planId" @selectPlan="onSelectPlan" />
        </div>
        <div class="form-row" v-if="plan.priceId && plan.priceOptions">
          <label>3. Choose a billing option</label>
          <choose-payment-plan
            :selectedPlan="plan.planId"
            :selectedPrice="plan.priceId"
            :priceOptions="plan.priceOptions"
            @selectPriceOption="onSelectPriceOption"
          />
        </div>
        <div class="form-row">
          <h3>Review &amp; Confirm</h3>
          <p>
            If you decide in the future that this plan does not meet your needs, you can change it at anytime.
          </p>
          <Button @click="createOrganisation()" :isLoading="isLoading">Create Organisation</Button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import subscriptions from '@/db/subscriptions';
import ChoosePlan from '@/components/ChoosePlan';
import ChoosePaymentPlan from '@/components/ChoosePaymentPlan';
import Button from '@/components/Button';
import IconClose from '@/components/icons/Close';
import Layout from '@/components/Layout';
import Logo from '@/components/Logo';

export default {
  name: 'OrganisationUpgrade',
  data: () => ({
    isLoading: false,
    priceOptions: null,
    plan: {
      planId: 'free',
      priceId: null,
    },
    org: {
      name: '',
      options: {},
    },
  }),
  components: {
    Button,
    ChoosePlan,
    ChoosePaymentPlan,
    IconClose,
    Layout,
    Logo,
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
  methods: {
    onSelectPlan({ planId, priceId, priceOptions }) {
      this.plan = { planId, priceId, priceOptions };
    },
    onSelectPriceOption(option) {
      this.plan.priceId = option;
    },
    async createOrganisation() {
      if (!this.org.name) {
        return;
      }
      console.log('... creating organisation');

      this.isLoading = true;
      const isOwner = true;

      // Create an organisation
      const newOrgId = await this.$store.dispatch('organisations/create', {
        name: this.org.name,
        ownerId: this.user.uid,
      });
      // and add the user to the new org
      this.$store.dispatch('user/addToOrganisation', { newOrgId, isOwner });
      // Set the users active Org to the new one
      this.$store.dispatch('organisations/setCurrentOrg', newOrgId);

      // If required, open Stripe Checkout
      if (this.plan.priceId) {
        subscriptions.createSessionAndRedirect(this.plan.priceId, newOrgId);
      } else {
        // Free Plan - just redirect home
        window.location.href = '/';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.page-scroller {
}
.page-wrapper {
  max-width: 750px;
  width: 100%;
  margin: 5rem auto;
}
.page-close {
  position: fixed;
  top: $space_xs;
  right: $space_xs;
  border-radius: 20px;
  cursor: pointer;

  a {
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
  }

  &:hover {
    background: $color_body;
  }
}
.form {
  margin: $space_md 0;
}
.form-field {
  max-width: 500px;
}
.input-description {
  font-size: 13px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
}

.form-row {
  margin: $space_lg 0;
}

/* Plans */
.plans {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
}
.plan {
  position: relative;
  border: solid 2px $color_input_border;
  border-radius: 6px;
  padding: $space_xs;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  &:hover {
    border: solid 2px $color_blue;
    cursor: pointer;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .plan-check {
    display: none;
  }

  &.selected {
    border: solid 2px $color_blue;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);

    .plan-check {
      display: grid;
      position: absolute;
      top: $space_xs;
      right: $space_xs;
    }
  }
}
.plan-label {
  font-size: 16px;
  font-weight: $font_weight_bold;
  background: $color_secondary;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  border-radius: 5px;

  &.free {
    background: $color_tier_free;
  }

  &.teams {
    background: $color_tier_teams;
    color: white;
  }

  &.pro {
    background: $color_tier_pro;
    color: white;
  }
}
.plan-details {
  list-style: none;
  padding: 0;
  font-size: 14px;
  margin-bottom: $space_sm;

  li + li {
    margin-top: $space_xxs;
  }
}
.price {
  margin-top: auto;
  font-weight: $font_weight_bold;

  em {
    font-weight: $font_weight_regular;
    font-style: normal;
    opacity: 0.7;
  }
}
.check {
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  background: $color_blue;
  border-radius: 12px;
  border: solid 2px $color_blue;

  .icon {
    width: 16px;
    height: 16px;
  }
}
/* Payment options */
.payment-options {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
}
.payment-option {
  border: solid 2px $color_input_border;
  border-radius: 6px;
  padding: $space_xs;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    border: solid 2px $color_blue;
    cursor: pointer;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .check {
    background: transparent;
    border-color: $color_input_border;
  }

  &.selected {
    border: solid 2px $color_blue;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);

    .check {
      background: $color_blue;
      border-color: $color_blue;
    }
  }
}
.payment-details {
  display: flex;
  padding-left: $space_xxs;
  flex: 1;

  .price {
    margin-left: auto;
  }
}
</style>
