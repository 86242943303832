import db from '@/db/users';

const state = {
  user: null,
};

const getters = {
  user: state => state.user,
  isLoggedIn: state => state.user !== null && !state.user.isGuest,
  isGuest: state => state.user !== null && state.user.isGuest,
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  edit(state, user) {
    state.user = { ...user };
  },
  reset: state => {
    state.user = null;
  },
};

const actions = {
  setCurrentUser: ({ commit }, userObj) => {
    commit('setUser', userObj);
  },
  createGuest: ({ commit }, userObj) => {
    const guestUser = {
      ...userObj,
      organisations: [],
      photoURL: null,
      isGuest: true,
    };
    commit('setUser', guestUser);
    // also store in localstorage
    localStorage.setItem('retro:user', JSON.stringify(guestUser));
  },
  edit({ commit }, user) {
    commit('edit', user);
    // Sync to DB
    db.updateUser(user.id, user);
  },
  addToOrganisation({ commit, state }, { newOrgId, isOwner = false }) {
    const user = state.user;
    const userRoles = state.user.roles || [];
    const newRole = { organisationId: newOrgId, role: isOwner ? 10 : 0 };
    const editedUser = {
      ...user,
      organisations: [...user.organisations, newOrgId],
      roles: [...userRoles, newRole],
    };
    commit('edit', editedUser);
    // Sync to DB
    db.updateUser(editedUser.uid, editedUser);
  },
  // eslint-disable-next-line no-empty-pattern
  async fetch({}, user) {
    const matchedUser = await db.fetchOrCreateUser(user);
    console.log(matchedUser);
    return matchedUser;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
