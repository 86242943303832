<template>
  <div class="card-tasks">
    <div class="task-list">
      <ActionItem v-for="task in tasks" :task="task" :key="task.id" />
    </div>
    <div class="new-task" v-if="addingTask">
      <resizable-textarea>
        <textarea
          class="input"
          placeholder="Write an action and press enter..."
          data-gramm_editor="false"
          v-model="newTask"
          @keydown.enter="onEnter"
          @keydown.esc="onCancel"
          @blur="onBlur"
          rows="1"
          ref="taskTextarea"
        >
        </textarea>
      </resizable-textarea>
    </div>
  </div>
</template>

<script>
import ResizableTextarea from '@/components/ResizableTextarea';
import ActionItem from '@/components/ActionItem';

export default {
  name: 'Action',
  data: () => ({
    newTask: '',
  }),
  props: ['tasks', 'cardId', 'boardId', 'teamId', 'addingTask'],
  components: { ActionItem, ResizableTextarea },
  watch: {
    // eslint-disable-next-line prettier/prettier
    addingTask(state) {
      if (state) {
        this.$nextTick(() => {
          this.$refs.taskTextarea.focus();
        });
      } else {
        this.$refs.taskTextarea.blur();
      }
    },
  },
  methods: {
    onEnter(event) {
      event.preventDefault();
      this.saveTask();
    },
    saveTask() {
      const description = this.newTask;

      if (!description) {
        alert('You have not written an action');
        return;
      }

      const task = {
        description,
        cardId: this.cardId,
        boardId: this.boardId,
        teamId: this.teamId,
      };

      this.$store.dispatch('tasks/add', task);
      this.newTask = '';
      this.$emit('toggleAddTask');
    },
    onCancel() {
      this.newTask = '';
      this.$emit('toggleAddTask');
    },
    onBlur() {
      this.$emit('toggleAddTask');
    },
  },
};
</script>

<style scoped lang="scss">
.card-tasks {
  margin-top: 1rem;
  border-radius: 0 0 4px 4px;
  background: #f2f5f7;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -8px;
}
.input {
  textarea {
    font-family: $font_family;
    width: 100%;
    font-size: 14px;
    color: $color_text;
    padding: 4px 6px;
    border-radius: 3px;
    border: solid 1px #ddd;

    &:focus,
    &:active {
      border-color: #bbb;
      outline: none;
    }
  }
}
.new-task {
  display: flex;
  padding: 0.5rem 1rem;
  border-top: solid 1px #e1e4e6;

  textarea {
    width: 100%;
    padding: 0px;
    border: none;
    font-size: 13px;
    resize: none;
    background: transparent;

    &:active,
    &:focus {
      outline: none;
    }
  }
}
</style>
