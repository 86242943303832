import formatISO from 'date-fns/formatISO';
import { generateId } from '@/utils';
import db from '@/db/tasks';

const state = {
  tasks: null,
};

const getters = {
  tasks: state => (state.tasks ? state.tasks.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) : []),
  tasksForCard: state => cardId => {
    const tasks = state.tasks ? state.tasks.filter(action => action.cardId === cardId) : [];
    return tasks.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));
  },
};

const mutations = {
  setTasks: (state, tasks) => {
    state.tasks = tasks;
  },
  add(state, task) {
    state.tasks.push(task);
  },
  remove(state, task) {
    const index = state.tasks.findIndex(x => x.id === task.id);
    state.tasks.splice(index, 1);
  },
  edit(state, task) {
    const index = state.tasks.findIndex(x => x.id === task.id);
    state.tasks.splice(index, 1, task);
  },
  reset: state => {
    state.tasks = null;
  },
};

const actions = {
  fetchTasksForTeam({ commit }, teamId) {
    db.fetchTasksByTeamId(teamId, tasks => commit('setTasks', tasks));
  },
  fetchTasksForBoard({ commit }, boardId) {
    db.fetchTasksByBoardId(boardId, tasks => commit('setTasks', tasks));
  },
  add({ commit }, task) {
    const newAction = {
      ...task,
      done: false,
      id: generateId(),
      createdDate: formatISO(new Date()),
    };
    commit('add', newAction);
    // Sync to DB
    db.addTask(newAction);
  },
  edit({ commit }, task) {
    commit('edit', task);
    // Sync to DB
    db.updateTask(task.id, task);
  },
  remove({ commit }, task) {
    commit('remove', task);
    // Sync to DB
    db.removeTask(task.id);
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
