import { db } from './firebase-config';

const cards = {
  stopCardWatcher: null,
  fetchCardsByBoardId(boardId, resolve) {
    // Fetch store and set up watcher
    const query = db.collection('cards').where('boardId', '==', boardId);
    this.stopCardWatcher = query.onSnapshot(
      snap => {
        const cards = [];
        snap.forEach(doc => {
          const card = doc.data();
          card.plus1s = card.plus1s || [];
          cards.push(card);
        });
        // resolve promise
        resolve(cards);
      },
      err => {
        /* eslint-disable */
        console.log(`Encountered fetch error: ${err}`);
        /* eslint-enable */
      }
    );
  },
  async fetchCardsByIdArr(ids) {
    if (!ids || !ids.length) {
      return false;
    }

    const collection = db.collection('cards');
    let snapshot = await collection.where('id', 'in', ids).get();

    if (snapshot.empty) {
      return false;
    } else {
      const arr = [];
      snapshot.forEach(doc => {
        arr.push(doc.data());
      });
      return arr;
    }
  },
  addCard(card) {
    if (card) {
      db.collection('cards')
        .doc(card.id)
        .set(card);
    }
  },
  updateCard(id, data) {
    if (data) {
      const query = db.collection('cards').doc(id);
      query.update(data);
    }
  },
  removeCard(id) {
    if (id) {
      db.collection('cards')
        .doc(id)
        .delete();
    }
  },
};

export default cards;
