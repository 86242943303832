<template>
  <div class="title-bar">
    <div class="name">
      {{ board.name || titleDate(board.createdDate) }}
      <div class="status-bar">
        <div
          class="status"
          :class="{ active: activeStep == 1, recent: activeStep > 1 }"
          @click="setProgress(1)"
          content="Cards will be blurred initially"
          v-tippy="{ placement: 'bottom', arrow: true, size: 'small' }"
        >
          <IconPencil color="white" />Add your cards
        </div>
        <div
          class="status"
          :class="{ active: activeStep == 2, recent: activeStep > 2 }"
          @click="setProgress(2)"
          content="Take turns to explain your cards"
          v-tippy="{ placement: 'bottom', arrow: true, size: 'small' }"
        >
          <IconEye color="white" />Show all cards
        </div>
        <div
          class="status"
          :class="{ active: activeStep == 3, recent: activeStep > 3 }"
          @click="setProgress(3)"
          content="Vote on cards that need further discussion"
          v-tippy="{ placement: 'bottom', arrow: true, size: 'small' }"
        >
          <IconUpVote color="white" />Vote on cards
        </div>
        <div
          class="status"
          :class="{ active: activeStep == 4 }"
          @click="setProgress(4)"
          content="Discuss these cards in more detail"
          v-tippy="{ placement: 'bottom', arrow: true, size: 'small' }"
        >
          <IconChat color="white" />Discuss top cards
        </div>
      </div>
    </div>
    <div class="status-bar-cover" v-if="!canEditBoard"></div>
    <div class="settings" v-if="canEditBoard">
      <div class="settings-btn" @click="toggleMenu">Settings <IconSettings /></div>
      <progress-dropdown
        @toggleMenu="toggleMenu"
        @openSettings="showModal"
        :isOpen="isOpen"
        :board="board"
        :activeStep="activeStep"
      />
    </div>
    <modal v-if="isModalVisible" @close="closeModal">
      <board-editor v-if="board" :board="board" @close="closeModal" />
    </modal>
  </div>
</template>

<script>
import { format } from 'date-fns';
import BoardEditor from '@/components/BoardEditor';
import IconChat from '@/components/icons/Chat';
import IconEye from '@/components/icons/Eye';
import IconPencil from '@/components/icons/Pencil';
import IconSettings from '@/components/icons/Settings';
import IconUpVote from '@/components/icons/UpVote';
import Modal from '@/components/Modal';
import ProgressDropdown from '@/components/ProgressDropdown';
import { isMemberOfTeam } from '@/utils/permissions';

export default {
  name: 'HostBar',
  props: ['board', 'teamId'],
  components: {
    BoardEditor,
    IconChat,
    IconEye,
    IconPencil,
    IconSettings,
    IconUpVote,
    Modal,
    ProgressDropdown,
  },
  data: () => ({
    isModalVisible: false,
    isOpen: null,
  }),
  watch: {
    '$route.params.boardId'() {
      if (this.$route.query.prompt && !this.board.columns.length) {
        this.showModal();
      }
    },
  },
  computed: {
    activeStep() {
      return this.board.progress || 1;
    },
    canEditBoard() {
      const teamId = this.teamId;
      return isMemberOfTeam(teamId);
    },
  },
  methods: {
    titleDate(date) {
      return format(new Date(date), 'dd MMM yyyy');
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    setProgress(step) {
      this.$store.dispatch('boards/edit', {
        ...this.board,
        progress: step,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-bar {
  background: white;
  height: $size_header;
  margin: 0 -16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $space_sm;
  position: relative;
  z-index: 3;
}
.settings {
}
.settings-btn {
  height: $size_header;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  text-transform: uppercase;
}
.name {
  display: flex;
  align-items: center;
}
.status-bar {
  display: flex;
  margin-left: 1.5rem;
  border-radius: 4px;
  overflow: hidden;
}
.status {
  display: flex;
  align-items: center;
  background: $color_greyblue_100;
  border-color: $color_greyblue_100;
  color: #bbb;
  font-size: 13px;
  line-height: 24px;
  padding: 0 6px 0 18px;
  margin-right: 3px;
  font-weight: $font_weight_bold;
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  &:first-child {
    padding-left: 8px;
  }

  &:last-child {
    padding-right: 8px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    border: 12px solid transparent;
    border-right-width: 0;
    border-left-width: 10px;
    z-index: 2;
    border-left-color: inherit;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    border: 12px solid transparent;
    border-right-width: 0;
    border-left-width: 10px;
    z-index: 1;
    border-left-color: white;
    transform: translateX(3px);
  }

  &.recent {
    background: #3b4959;
    border-color: #3b4959;
    color: white;
  }

  &.active,
  &:hover {
    background: $color_secondary;
    border-color: $color_secondary;
    color: $color_text;
  }
}
.status-bar-cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 3;
}
</style>

<style lang="scss">
.settings-btn {
  .icon {
    width: 16px;
    height: 16px;
    margin-left: 0.5rem;
  }
  svg {
    fill: #bebebe;
  }
  &:hover {
    svg {
      fill: $color_primary;
    }
  }
}
.status {
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;

    svg > * {
      fill: #bbb;
    }
  }

  &.recent .icon svg > * {
    fill: white;
  }

  &:hover .icon svg > *,
  &.active .icon svg > * {
    fill: $color_text;
  }
}
</style>
