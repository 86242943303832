<template>
  <div class="message-bar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MessageBar',
};
</script>

<style lang="scss" scoped>
.message-bar {
  background: $color_secondary;
  font-size: 14px;
  padding: $space_xxs $space_sm;

  a {
    color: inherit;
  }
}
</style>
