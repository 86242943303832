const generateId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 12 characters
  // after the decimal.
  return `${Math.random()
    .toString(36)
    .substring(2, 12)}`;
};

const getEmailDomain = email => {
  return email && email.split('@')[1];
};

const mergeUsers = (users1, users2 = []) => {
  // Handle old user objects that aren't arrays
  users1 = Array.isArray(users1) ? users1 : [users1];
  users2 = Array.isArray(users2) ? users2 : [users2];
  const mergedUsers = [...users1, ...users2];
  const uniqueUsers = [];

  for (const user of mergedUsers) {
    const found = uniqueUsers.find(u => u.userId === user.userId || u.name === user.name);
    if (!found) uniqueUsers.push(user);
  }

  return uniqueUsers;
};

const mergeVotes = (votes1, votes2 = []) => {
  const mergedVotes = [...votes1, ...votes2];
  const uniqueVotes = new Set(mergedVotes);
  return [...uniqueVotes];
};

const generateAvatarColor = string => {
  var colors = [
    '#e51c23',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#5677fc',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#259b24',
    '#8bc34a',
    '#afb42b',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
  ];

  let hash = 0;
  if (string.length === 0) return hash;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

const trackTeamView = teamId => {
  let wasAdded = false;
  let views = JSON.parse(localStorage.getItem('retro:viewCount'));
  views = views || [];

  views.forEach(v => {
    if (v.team == teamId) {
      v.views = v.views + 1;
      wasAdded = true;
    }
  });

  if (!wasAdded) {
    views.push({
      team: teamId,
      views: 1,
    });
  }

  localStorage.setItem('retro:viewCount', JSON.stringify(views));
};

export { generateAvatarColor, generateId, getEmailDomain, mergeUsers, mergeVotes, trackTeamView };
