<template>
  <router-link :to="linkTo || '/'" class="logo" :class="[variant || '']"
    ><svg width="287" height="73" viewBox="0 0 287 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.291 31.6895H27.0996C31.3639 31.6895 34.6842 30.7617 37.0605 28.9062C39.4368 27.0508 40.625 24.2188 40.625 20.4102C40.625 17.7409 39.8438 15.7389 38.2812 14.4043C36.7513 13.0697 34.3262 12.4023 31.0059 12.4023H27.3926L23.291 31.6895ZM20.7031 43.9941L14.8926 71.3867H0L15.0879 0H32.6172C40.332 0 46.1263 1.66016 50 4.98047C53.8737 8.30078 55.8105 13.1673 55.8105 19.5801C55.8105 24.7233 54.4596 29.1504 51.7578 32.8613C49.056 36.5397 45.166 39.2741 40.0879 41.0645L52.832 71.3867H36.6211L26.5137 43.9941H20.7031Z"
        fill="white"
      />
      <path
        d="M94.834 26.3672C91.9694 26.3672 89.2676 27.6693 86.7285 30.2734C84.1895 32.8776 82.5293 36.0514 81.748 39.7949H83.9453C88.9909 39.7949 92.9134 39.0137 95.7129 37.4512C98.5449 35.8561 99.9609 33.7077 99.9609 31.0059C99.9609 27.9134 98.252 26.3672 94.834 26.3672ZM86.8262 72.3633C79.9902 72.3633 74.6842 70.5241 70.9082 66.8457C67.1322 63.1673 65.2441 57.9753 65.2441 51.2695C65.2441 44.5312 66.5788 38.3952 69.248 32.8613C71.9499 27.2949 75.5957 23.0632 80.1855 20.166C84.7754 17.2363 89.9674 15.7715 95.7617 15.7715C101.523 15.7715 106.016 17.1061 109.238 19.7754C112.461 22.4121 114.072 26.0417 114.072 30.6641C114.072 36.7513 111.354 41.4551 105.918 44.7754C100.482 48.0632 92.7181 49.707 82.627 49.707H80.1367L80.0391 50.7324V51.709C80.0391 54.6712 80.8691 57.015 82.5293 58.7402C84.222 60.4329 86.6309 61.2793 89.7559 61.2793C92.5879 61.2793 95.1595 60.9701 97.4707 60.3516C99.7819 59.7331 102.581 58.6426 105.869 57.0801V68.1641C100.27 70.9635 93.9225 72.3633 86.8262 72.3633Z"
        fill="white"
      />
      <path
        d="M144.209 60.498C146.325 60.498 148.962 59.9284 152.119 58.7891V69.7754C148.506 71.5007 144.176 72.3633 139.131 72.3633C134.248 72.3633 130.651 71.3379 128.34 69.2871C126.061 67.2363 124.922 64.0625 124.922 59.7656C124.922 58.138 125.117 56.3151 125.508 54.2969L131.123 27.9785H123.701L125.117 20.8008L134.688 16.6992L141.133 5.17578H150.605L148.213 16.7969H162.031L159.59 27.9785H145.82L140.205 54.2969C140.01 55.2734 139.912 56.1361 139.912 56.8848C139.912 59.2936 141.344 60.498 144.209 60.498Z"
        fill="white"
      />
      <path
        d="M203.74 15.7715C205.661 15.7715 207.223 15.9505 208.428 16.3086L205.205 30.4688C203.74 29.9479 202.113 29.6875 200.322 29.6875C196.546 29.6875 193.226 31.1849 190.361 34.1797C187.529 37.1419 185.511 41.4062 184.307 46.9727L179.131 71.3867H164.434L176.006 16.7969H187.236L186.211 26.9043H186.504C191.289 19.4824 197.035 15.7715 203.74 15.7715Z"
        fill="white"
      />
      <path
        d="M246.523 37.4023C246.523 30.9896 244.196 27.7832 239.541 27.7832C237.1 27.7832 234.902 28.776 232.949 30.7617C231.029 32.7474 229.45 35.6608 228.213 39.502C227.008 43.3431 226.406 47.2982 226.406 51.3672C226.406 57.3893 228.848 60.4004 233.73 60.4004C236.172 60.4004 238.369 59.4076 240.322 57.4219C242.275 55.4036 243.789 52.6204 244.863 49.0723C245.97 45.4915 246.523 41.6016 246.523 37.4023ZM261.318 37.4023C261.318 44.2708 260.163 50.3581 257.852 55.6641C255.573 60.9701 252.269 65.0879 247.939 68.0176C243.61 70.9147 238.467 72.3633 232.51 72.3633C226.162 72.3633 221.084 70.459 217.275 66.6504C213.499 62.8092 211.611 57.7148 211.611 51.3672C211.611 44.4336 212.767 38.265 215.078 32.8613C217.422 27.4251 220.791 23.2259 225.186 20.2637C229.58 17.2689 234.723 15.7715 240.615 15.7715C246.995 15.7715 252.041 17.6921 255.752 21.5332C259.463 25.3743 261.318 30.6641 261.318 37.4023Z"
        fill="white"
      />
      <path
        d="M269.434 65.7715C269.434 62.8418 270.296 60.498 272.021 58.7402C273.779 56.9824 276.237 56.1035 279.395 56.1035C281.608 56.1035 283.382 56.722 284.717 57.959C286.051 59.196 286.719 60.9375 286.719 63.1836C286.719 66.0156 285.824 68.3105 284.033 70.0684C282.243 71.8262 279.899 72.7051 277.002 72.7051C274.626 72.7051 272.77 72.1029 271.436 70.8984C270.101 69.6615 269.434 67.9525 269.434 65.7715Z"
        fill="white"
      /></svg
  ></router-link>
</template>

<script>
export default {
  name: 'Logo',
  props: ['variant', 'linkTo'],
};
</script>

<style lang="scss" scoped>
.logo {
  font-weight: 600;
  text-decoration: none;
  padding: 0 24px;
  color: white;
  display: flex;
  align-items: center;
  height: $size_header;
  overflow: hidden;

  svg {
    display: block;
    width: 75px;
    height: auto;
  }

  &.dark-logo,
  &.dark-large-logo {
    padding: 0;
    height: auto;
    margin-bottom: $space_md;

    svg {
      width: 100px;
    }

    svg > * {
      fill: $color_text;
    }
  }

  &.dark-large-logo {
    svg {
      width: 120px;
    }
  }
}
</style>
