<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893L19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L16.5858 6H5C4.20435 6 3.44129 6.31607 2.87868 6.87868C2.31607 7.44129 2 8.20435 2 9V11C2 11.5523 1.55228 12 1 12C0.447715 12 0 11.5523 0 11V9C0 7.67392 0.526784 6.40215 1.46447 5.46447C2.40215 4.52678 3.67392 4 5 4H16.5858L14.2929 1.70711C13.9024 1.31658 13.9024 0.683417 14.2929 0.292893Z"
        :fill="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 12C19.5523 12 20 12.4477 20 13V15C20 16.3261 19.4732 17.5979 18.5355 18.5355C17.5979 19.4732 16.3261 20 15 20H3.41421L5.70711 22.2929C6.09763 22.6834 6.09763 23.3166 5.70711 23.7071C5.31658 24.0976 4.68342 24.0976 4.29289 23.7071L0.292893 19.7071C-0.0976311 19.3166 -0.0976311 18.6834 0.292893 18.2929L4.29289 14.2929C4.68342 13.9024 5.31658 13.9024 5.70711 14.2929C6.09763 14.6834 6.09763 15.3166 5.70711 15.7071L3.41421 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15V13C18 12.4477 18.4477 12 19 12Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'Shuffle',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
