<template>
  <div class="team-form">
    <div class="team-form-container" v-if="!team">
      <div v-if="!hasReachedTeamLimit">
        <h2>New Team / Project</h2>
        <p>
          Choose a name for this Retro Team. We recommend that its either the name of a project or your teams name.
        </p>
        <div class="form-field">
          <input class="form-input" type="text" v-model="newTeam.name" placeholder="ex. 'Project X' or 'Developers'" />
        </div>
        <div class="form-field" v-if="privateTeamsAllowed">
          <div class="check" @click="applyNewSetting('anonUsersAllowed')">
            <span class="checkbox" :class="{ checked: newTeam.settings.anonUsersAllowed }">
              <icon-check />
            </span>
            <label class="check-label">
              Allow guests
              <small>This means anyone with the link can access these retro boards</small>
            </label>
          </div>
        </div>
        <div class="modal-button">
          <Button @click="createTeam">Create team</Button>
        </div>
      </div>
      <div v-if="hasReachedTeamLimit">
        <h2>Teams limit reached</h2>
        <p>
          You have reached the limit of how many teams you can create on your current account.
        </p>
        <p>
          Consider <router-link to="/settings/plan">Upgrading your account</router-link>, or archiving some existing
          teams.
        </p>
      </div>
    </div>
    <div class="team-form-container" v-if="team">
      <h2>Edit Team</h2>
      <p>
        Update your Team/Project details
      </p>
      <label>Team name</label>
      <div class="form-field">
        <input class="form-input" type="text" v-model="team.name" placeholder="ex. 'Project X' or 'Developers'" />
      </div>
      <div class="form-field" v-if="privateTeamsAllowed">
        <div class="check" @click="applySetting('anonUsersAllowed')">
          <span class="checkbox" :class="{ checked: team.settings && team.settings.anonUsersAllowed }">
            <icon-check />
          </span>
          <label class="check-label">
            Allow guests
            <small>This means anyone with the link can access these retro boards</small>
          </label>
        </div>
      </div>
      <div class="modal-button">
        <Button @click="editTeam">Save changes</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
import IconCheck from '@/components/icons/Check';
import { canCreateMoreTeams, canCreatePrivateTeams } from '@/utils/permissions';

export default {
  name: 'NewTeamForm',
  props: ['existingTeam'],
  components: {
    Button,
    IconCheck,
  },
  data: () => ({
    newTeam: {
      name: '',
      settings: {
        anonUsersAllowed: false,
      },
    },
    team: null,
  }),
  created() {
    this.team = this.existingTeam ? JSON.parse(JSON.stringify(this.existingTeam)) : null;
  },
  computed: {
    currentOrg() {
      return this.$store.getters['organisations/currentId'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    hasReachedTeamLimit() {
      return !canCreateMoreTeams();
    },
    privateTeamsAllowed() {
      return canCreatePrivateTeams();
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    editTeam() {
      if ((!this.team && !this.team.name) || !this.user.uid) {
        alert('A Team or Project name is required');
        return;
      }
      this.$store.dispatch('teams/edit', {
        ...this.team,
      });
      this.close();
    },
    async createTeam() {
      if (!this.newTeam.name || !this.user.uid) {
        alert('A Team or Project name is required');
        return;
      }

      const newTeam = this.newTeam;
      // when creating a team, get the current organisation or create one
      const organisation = await this.getOrCreateOrg(this.currentOrg);
      // If Private teams are not allowed, force AllowAnon to true
      newTeam.settings.anonUsersAllowed = this.privateTeamsAllowed ? newTeam.settings.anonUsersAllowed : true;

      if (organisation) {
        // create the team once the organisation exists
        this.$store.dispatch('teams/create', {
          ...newTeam,
          organisation,
        });
        this.newTeam.name = '';
      } else {
        alert('Sorry, something went wrong creating the new team.');
      }
      this.close();
    },
    async getOrCreateOrg(currentOrg, name = '') {
      const isOwner = true;
      if (!currentOrg || !currentOrg.length) {
        // automatically create an org if one doesn't exist
        const newOrgId = await this.$store.dispatch('organisations/create', {
          name,
          ownerId: this.user.uid,
        });
        // and set the current org to that.
        this.$store.dispatch('organisations/setCurrentOrg', newOrgId);
        // and add the user to it
        this.$store.dispatch('user/addToOrganisation', { newOrgId, isOwner });
        return newOrgId;
      }

      return currentOrg;
    },
    applySetting(setting) {
      const settings = this.team.settings ? { ...this.team.settings } : {};
      if (settings[setting] === undefined) {
        settings[setting] = false;
      }
      settings[setting] = !settings[setting];

      this.team = { ...this.team, settings };
    },
    applyNewSetting(setting) {
      this.newTeam.settings[setting] = !this.newTeam.settings[setting];
    },
  },
};
</script>

<style lang="scss" scoped>
.team-form {
}

.callout {
  background: #fffbe6;
  border: solid 1px #ffe58f;
  border-radius: 4px;
  padding: $space_xs;

  .form-field {
    margin: 0;
  }

  p {
    margin: 0 0 $space_xs 0;
    font-size: 14px;
  }
}
.check {
  display: flex;
  cursor: pointer;
}
.check-label {
  cursor: pointer;

  small {
    display: block;
    color: $color_text_grey;
    font-weight: $font_weight_regular;
  }
}
.checkbox {
  width: 16px;
  height: 16px;
  margin-right: $space_xxs;
  margin-top: 1px;
}
</style>
