<template>
  <div class="new-card">
    <div
      class="new"
      @click="setFocus()"
      :class="{
        focus: focus,
      }"
    >
      <resizable-textarea ref="resizableInput">
        <textarea
          class="new-input"
          placeholder="Add new card"
          data-gramm_editor="false"
          v-model="newCard"
          ref="input"
          rows="1"
          @keyup.enter="addCard"
          @blur="setBlur()"
        >
        </textarea>
      </resizable-textarea>
    </div>
    <span v-if="newCard.length" class="note">Press Enter to submit your card</span>
  </div>
</template>

<script>
import ResizableTextarea from '@/components/ResizableTextarea';

export default {
  name: 'NewCard',
  components: {
    ResizableTextarea,
  },
  data: () => ({
    newCard: '',
    focus: false,
  }),
  props: ['cards', 'columnId', 'boardId', 'teamId'],
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
  methods: {
    setFocus() {
      this.$refs.input.focus();
      this.focus = !this.focus;
    },
    setBlur() {
      this.focus = false;
    },
    addCard() {
      let title = this.newCard.trim();
      title = title.replace(/[\n\r]/g, '');
      const userId = this.user && this.user.uid;

      if (!title || !userId) {
        alert('Oops something is wrong');
        return;
      }

      const newCard = {
        title,
        description: '',
        boardId: this.boardId,
        teamId: this.teamId,
        columnId: this.columnId,
        position: this.getLastPosition(),
        user: [
          {
            userId,
            name: this.user.displayName,
            avatar: this.user.photoURL,
          },
        ],
      };

      this.$store.dispatch('cards/addCard', newCard);
      this.newCard = '';
      this.$refs.resizableInput.resetTextarea();
    },
    getLastPosition() {
      const increment = 100;
      const cards = this.cards;
      const lastPosition = cards.length ? cards[cards.length - 1].position : 0;
      return lastPosition + increment;
    },
  },
};
</script>

<style scoped lang="scss">
.new-card {
  margin-bottom: $space_xxs;
}
.new {
  display: flex;
  align-items: center;
  color: #9b9b9b;
  cursor: pointer;
  padding: 0 $space_xs;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.07);
}
.new:hover {
  background: rgba(0, 0, 0, 0.02);
}
.new.focus {
  border: dashed 1px #d4d4d4;
}
.new-input {
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  font-family: $font_family;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #2c3e50;
  resize: none;
}
.new-input:focus {
  outline: none;
}
.note {
  font-size: 11px;
  color: #999;
  line-height: 1;
  display: block;
  margin-top: 6px;
}
</style>
