<template>
  <div class="layout">
    <Header v-if="showHeader" />
    <div class="body" :class="[layout || '']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';

export default {
  name: 'LayoutApp',
  props: ['layout', 'hideHeader'],
  components: {
    Header,
  },
  computed: {
    showHeader() {
      return this.hideHeader ? false : true;
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  display: flex;
  min-height: calc(100vh - #{$size_header});
  overflow-x: auto;

  &.center {
    flex-direction: column;
    align-items: center;
    padding: $space_xxl $space_sm;

    > * {
      width: 100%;
      max-width: 850px;
    }
  }

  &.settings {
    flex-direction: column;
    align-items: center;
    background: white;
    padding: $space_xxl $space_sm;

    > * {
      width: 100%;
      max-width: 750px;
    }
  }
}
@media (min-width: 768px) {
  .body {
    overflow-x: none;
  }
}
</style>
