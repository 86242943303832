const templates = [
  {
    id: 'default-quick-001',
    name: 'Quick Retro',
    description: 'What went well\nWhat could be improved\nWhat wasn’t great',
    columns: [
      {
        title: '🎉 What went well?',
        id: 'went-well-001',
        order: 1,
      },
      {
        title: '🤔 What could be improved?',
        id: 'be-improved-001',
        order: 2,
      },
      {
        title: "👎 What wasn't great?",
        id: 'not-great-001',
        order: 3,
      },
    ],
  },
  {
    id: 'default-4ls-001',
    name: 'The 4 L’s',
    description: 'Liked\nLearned\nLacked\nLonged For',
    columns: [
      {
        title: '😍 Liked',
        id: 'liked-001',
        order: 1,
      },
      {
        title: '🧐 Learned',
        id: 'learned-001',
        order: 2,
      },
      {
        title: '🤷‍♀️ Lacked',
        id: 'lacked-001',
        order: 3,
      },
      {
        title: '🙏 Longed For',
        id: 'longed-for-001',
        order: 4,
      },
    ],
  },
  {
    id: 'default-ssc-001',
    name: 'Start, Stop, Continue',
    description: 'Start\nStop\nContinue',
    columns: [
      {
        title: '🚀 Start',
        id: 'start-001',
        order: 1,
      },
      {
        title: '🚫 Stop',
        id: 'stop-001',
        order: 2,
      },
      {
        title: '💯 Continue',
        id: 'continue-001',
        order: 3,
      },
    ],
  },
  {
    id: 'default-blank-001',
    name: 'Blank Template',
    description: 'Create your own Retro',
    columns: [],
  },
];

export default templates;
