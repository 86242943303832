<template>
  <div class="dropdown-container" v-if="isOpen">
    <div class="dropdown">
      <div class="dropdown-item" v-for="team in limitedTeams" :key="team.id" @click="itemClicked(team.id)">
        {{ team.name }}
      </div>
      <div v-if="remainingTeams" class="dropdown-item dropdown-more" @click="gotoTeams()">
        + {{ remainingTeams }} more...
      </div>
      <div v-if="remainingTeams == 0" class="dropdown-item dropdown-more" @click="gotoTeams()">
        <IconPlus />Add teams
      </div>
    </div>
    <div class="overlay" @click="toggleMenu"></div>
  </div>
</template>

<script>
import IconPlus from '@/components/icons/Plus';

const teamsLimit = 4;

export default {
  name: 'TeamMenuDropdown',
  props: ['isOpen'],
  components: {
    IconPlus,
  },
  data: () => ({
    organisation: null,
  }),
  created() {
    this.getMyTeams(this.currentOrgId);
  },
  computed: {
    currentOrgId() {
      return this.$store.getters['organisations/currentId'];
    },
    limitedTeams() {
      return [...this.teams].splice(0, teamsLimit);
    },
    remainingTeams() {
      const len = this.teams.length;
      return len > teamsLimit ? len - teamsLimit : 0;
    },
    teams() {
      return this.$store.getters['teams/teams'];
    },
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
    },
    getMyTeams(organisation) {
      if (organisation) {
        this.$store.dispatch('teams/fetchTeams', organisation);
      }
    },
    itemClicked(teamId) {
      this.$store.dispatch('onTeamChange');
      this.toggleMenu();
      this.$nextTick(() => {
        this.$router.push({ name: 'Boards', params: { teamId } });
      });
    },
    gotoTeams() {
      this.toggleMenu();
      this.$nextTick(() => {
        this.$router.push({ name: 'Retros' });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-container {
  position: relative;
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 0 0 4px 4px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 20;
  min-width: 100%;
}
.dropdown-item {
  padding: 0 $space_sm;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $color_text;
  text-decoration: none;

  &:hover {
    background: #f6f6f6;
  }
}
.dropdown-new {
  border-top: solid 1px #eee;
}
.dropdown-more {
  border-top: solid 1px #eee;
  background: #f6f6f6;
  border-radius: 0 0 4px 4px;
}
.overlay {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: $size_header;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19;
}
</style>

<style lang="scss">
.dropdown-item {
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-left: -4px;
  }
  svg {
    fill: $color_text;
  }
}
</style>
