import { db } from './firebase-config';

const boards = {
  fetchBoardsByTeamId(teamId, resolve) {
    // Fetch store and set up watcher
    const query = db.collection('boards').where('teamId', '==', teamId);
    query.onSnapshot(
      snap => {
        const boards = [];
        snap.forEach(doc => {
          boards.push(doc.data());
        });
        // resolve promise
        resolve(boards);
      },
      err => {
        /* eslint-disable */
        console.log(`Encountered fetch error: ${err}`);
        /* eslint-enable */
      }
    );
  },
  addBoard(board) {
    if (board) {
      return db
        .collection('boards')
        .doc(board.id)
        .set(board)
        .then(() => {
          return board.id;
        });
    }
    return false;
  },
  updateBoard(id, data) {
    if (data) {
      const query = db.collection('boards').doc(id);
      query.update(data);
    }
  },
};

export default boards;
