<template>
  <div class="board-dots">
    <div class="dots">
      <div
        class="create-new"
        v-if="canEditBoard"
        @click="showModal"
        content="Create new Retro"
        v-tippy="{ placement: 'right', arrow: true, size: 'small' }"
      >
        <icon-plus />
      </div>
      <router-link
        class="dot"
        v-for="board in boards"
        :key="board.id"
        :to="{
          name: 'Board',
          params: { teamId: teamId, boardId: board.id },
        }"
        :content="board.name || tooltipDate(board.createdDate)"
        v-tippy="{ placement: 'right', arrow: true, size: 'small' }"
      >
        <span></span>
      </router-link>
    </div>
    <slot v-if="canEditBoard"></slot>
    <modal v-if="isModalVisible" @close="closeModal">
      <board-creator :teamId="teamId" @close="closeModal" />
    </modal>
  </div>
</template>

<script>
import { format } from 'date-fns';
import BoardCreator from '@/components/BoardCreator';
import Modal from '@/components/Modal';
import IconPlus from '@/components/icons/Plus';
import { isMemberOfTeam } from '@/utils/permissions';

export default {
  name: 'BoardBar',
  components: {
    BoardCreator,
    Modal,
    IconPlus,
  },
  data: () => ({
    isModalVisible: false,
  }),
  created() {
    this.teamId = this.$route.params.teamId;
    this.getBoards(this.teamId);
  },
  watch: {
    '$route.params.teamId'(teamId) {
      this.teamId = teamId;
      this.getBoards(this.teamId);
    },
  },
  computed: {
    boardId() {
      return this.$route.params.boardId;
    },
    boards() {
      return this.$store.getters['boards/boards'];
    },
    canEditBoard() {
      const teamId = this.teamId;
      return isMemberOfTeam(teamId);
    },
  },
  methods: {
    getBoards(teamId) {
      if (teamId && (!this.boards || !this.boards.length)) {
        this.$store.dispatch('boards/fetchBoardsForTeam', teamId);
      }
    },
    tooltipDate(date) {
      return format(new Date(date), 'dd MMM yyyy');
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.board-dots {
  width: $size_sidebar;
  height: calc(100vh - #{$size_header});
  background: $color_sidebar;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(0deg, rgba(244, 246, 248, 1) 0%, rgba(244, 246, 248, 0) 100%);
  }
}
.dots {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 6px;

  > * {
    width: $size_sidebar;
    height: 42px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.dot {
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin: -4px 0 0 -4px;
    background: #ddd;
  }

  &.router-link-active span,
  &:hover span {
    background: $color_primary;
  }
}
</style>

<style lang="scss">
.create-new {
  margin-top: 2px;

  svg {
    fill: #cacaca;
  }

  &:hover svg {
    fill: $color_primary;
  }
}
</style>
